import * as Yup from "yup";
import ImportConfig from "../config/ImportConfig";
import BNCCModel from "../config/BNCCModel";
import { isArrayWithLength } from "../helpers/commons";

Yup.addMethod(Yup.array, "hasOneCorrectAnswer", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasOneCorrectAnswer", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		let correctAnswers = value.filter(item => JSON.parse(item.isCorrectAnswer));
		
		//console.log(value, correctAnswers);
		
    	return correctAnswers.length === 1
    			|| createError({ path, message: message }); 
    
    });
});

Yup.addMethod(Yup.array, "hasMultipleCorrectAnswers", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasMultipleCorrectAnswers", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		let correctAnswers = value.filter(item => JSON.parse(item.isCorrectAnswer));
		
    	return correctAnswers.length >= 2
    			|| createError({ path, message: message }); 
    
    });
});

Yup.addMethod(Yup.array, "hasCorrectOrder", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasCorrectOrder", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		const uniques = new Set(value.map(item => parseInt(item["stepOrder"])));
		let ordering = [...uniques].sort((a,b) => a-b);
		
    	return (ordering.length === value.length && ordering.at(0) === 1 && ordering.at(-1) === ordering.length)
    			|| createError({ path, message: message });
    
    });
});

const Levels = [
	"PRACTICE", 
	"PROFICIENCY", 
	"CHALLENGE"
]

const Types = [
	"MULTIPLE_CHOICE",
	"SHORT_ANSWER",
	"TRUE_OR_FALSE",
	"MULTIPLE_RESPONSE",
	//"FILL_IN_THE_BLANK"
]

const GeneralFormValidationRules = {
   	field: Yup.string()
		.oneOf([""].concat(BNCCModel.KnowledgeFields), 'form.validation.string.enum')
		.required()
   		.default(""),
   	level: Yup.string()
		.oneOf([""].concat(Levels), 'form.validation.string.enum')
		.required()
   		.default(""),
	sourceId: Yup.string()
		.required()
   		.default(() => ""),
   	year: Yup.string()
		.max(4, 'questions.save.form.year.validation.maximumlength')
		.required()
   		.default(() => ""),
   
}

const QuestionStemFormValidationRules = {
	questionStem: Yup.string()
		.max(50000, 'questions.save.form.questionStem.maximumlength')
		.required()
		.default(() => ""),
	estimatedTime: Yup.string()
		.required()
   		.default(() => ""),
	/*skills: Yup.array()
		.min(1, 'questions.save.form.skills.validation.required')
		.default(() => []),*/
}


const QuestionAnswerOptionFormValidationRules = {
   	id: Yup.string()
		.default(() => ""),
   	answer: Yup.string()
   		.required()
   		.max(10000, 'questions.save-answer-option-modal.form.answer.validation.maximumlength')
   		.default(""),		
   	isCorrectAnswer: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => false),
}

const AnswerFormValidationRules = {
	type: Yup.string()
		.oneOf([""].concat(Types), 'form.validation.string.enum')
		.required()
   		.default(""),
	shortAnswer: Yup.string()
		.when('type', ([type], schema) => {
			if(type === "SHORT_ANSWER") {
				return Yup.string()
					.required()
   					.max(30, 'questions.save.form.shortAnswer.maximumlength');		
	        }
			return schema;
        })
   		.default(""),	
	answerOptions: Yup.array()
		.when('type', ([type], schema) => {
			if (type === "MULTIPLE_CHOICE" || type === "TRUE_OR_FALSE") {
				return Yup.array()
					.min(1, 'questions.save.form.answerOptions.validation.required')
					.hasOneCorrectAnswer({ message: 'questions.save.form.answerOptions.validation.one-correct-answer'})	
	        } else if (type === "MULTIPLE_RESPONSE") {
				return Yup.array()
					.min(1, 'questions.save.form.answerOptions.validation.required')
					.hasMultipleCorrectAnswers({ message: 'questions.save.form.answerOptions.validation.multiple-correct-answers'})	
			}
			return schema;
        })
		.default(() => []),
}

const QuestionResolutionStepFormValidationRules = {
   	id: Yup.string()
		.default(() => ""),
   	stepOrder: Yup.number()
		.required()
		.default(() => 0),
   	resolution: Yup.string()
   		.required()
   		.max(10000, 'questions.save-answer-option-modal.form.answer.validation.maximumlength')
   		.default(""),
   	estimatedTime: Yup.string()
		.required()
   		.default(() => ""),	
   	skill: Yup.object()
   		.nullable()
		.required()
   		.default(() => null),
   	
}

const ResolutionFormValidationRules = {
	resolutionSteps: Yup.array()
		.min(1, 'questions.save.form.resolutionSteps.validation.required')
		.hasCorrectOrder({ message: 'questions.save.form.resolutionSteps.validation.correct-order'})
		.default(() => []),
}

const ImagesFormValidationRules = {
	images: Yup.array().default(() => [])
}

const QuestionPreviewFormValidationRules = {
	source: Yup.string()
   		.default(() => ""),
   	year: Yup.string()
   		.default(() => ""),
	questionStem: Yup.string()
		.default(() => ""),
	type: Yup.string()
   		.default(""),
	shortAnswer: Yup.string()
		.when('type', ([type], schema) => {
			if(type === "SHORT_ANSWER") {
				return Yup.string()
					.required('questions.question-preview-modal.form.shortAnswer.validation.required')
   					.max(30, 'questions.question-preview-modal.form.shortAnswer.validation.maximumlength');		
	        }
			return schema;
        })
   		.default(""),	
	answerOptions: Yup.array()
		.when('type', ([type], schema) => {
			if (type === "MULTIPLE_CHOICE" || type === "TRUE_OR_FALSE") {
				return Yup.array()
					.hasOneCorrectAnswer({ message: 'questions.question-preview-modal.form.answerOptions.validation.one-correct-answer'})	
	        } else if (type === "MULTIPLE_RESPONSE") {
				return Yup.array()
					.hasMultipleCorrectAnswers({ message: 'questions.question-preview-modal.form.answerOptions.validation.multiple-correct-answers'})	
			}
			return schema;
        })
		.default(() => []),
}

const ExportHeaders =[
	"id", "field", "level", "type", "source", "year", "questionStem", "estimatedTime", "active", "shortAnswer", "answerOptions", "resolutionSteps", "images", "skills", "whenModified", "whenCreated"
]

const ImportHeaders =[
	"id", "field", "level", "type", "source", "year", "questionStem", "estimatedTime", "active", "shortAnswer", "answerOptions", "resolutionSteps", "images", "skills"
]

const ImportActions = ImportConfig.Actions;
const ImportDelimiterOptions = ImportConfig.DelimiterOptions;
const ImportFirstRowHeaderOptions = ImportConfig.FirstRowHeaderOptions;
const ImportColumnMappingStrategyOptions = ImportConfig.ColumnMappingStrategyOptions;

const ImportFormValidationRules = {
	   action: Yup.string()
		.oneOf([""].concat(ImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(Object.assign({}, GeneralFormValidationRules, QuestionStemFormValidationRules, ResolutionFormValidationRules )))
		.max(255, 'questions.import.form.data.validation.maximum-size' )
		.default(() => []),
}

const FilterFormValidationRules = {
	filter: Yup.string()
		.test(
        	'empty-or-3-characters-check',
        	'questions.list.form.filter.validation.minimumlength',
        	filter => !filter || filter.length >= 3,
    	)
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "questionStem"),
	fields: Yup.array().of(Yup.string().oneOf([""].concat(BNCCModel.KnowledgeFields), 'form.validation.string.enumlist'))
		.default(() => []),
	
}

const AdvancedFilterFormFields = {
	levels: Yup.array().of(Yup.string().oneOf([""].concat(Levels), 'form.validation.string.enumlist'))
   		.default(() => []),
   	types: Yup.array().of(Yup.string().oneOf([""].concat(Types), 'form.validation.string.enumlist'))
   		.default(() => []),
   	sources: Yup.array()
		.default([]),
   	year: Yup.string()
		.default(() => ""),
	skills: Yup.array()
		.default([]),
}

const QuestionConfig = { Levels, Types, GeneralFormValidationRules, QuestionStemFormValidationRules, 
		AnswerFormValidationRules, ResolutionFormValidationRules, ImagesFormValidationRules, QuestionAnswerOptionFormValidationRules,
		QuestionResolutionStepFormValidationRules, QuestionPreviewFormValidationRules,
		ImportHeaders, ExportHeaders, ImportFormValidationRules,
		ImportActions, ImportDelimiterOptions, ImportFirstRowHeaderOptions, ImportColumnMappingStrategyOptions,
		FilterFormValidationRules, AdvancedFilterFormFields
		
};

export default QuestionConfig;