import * as Yup from "yup";

const Subjects = [
	"USER"
]

const SubjectActions = {
	"USER":	[
		"LOGIN",
		"LOGOUT",
	 	"CHANGE_PASSWORD",
		"FORGOT_PASSWORD",
	 	"RESET_PASSWORD",
		"PASSWORD_UPDATE",
	 	"SELF_PASSWORD_UPDATE",
		"CREATE",
		"UPDATE",
	],
}

const FilterFormValidationRules = {
	   subject: Yup.string()
			.oneOf([""].concat(Subjects), 'form.validation.string.enum')
			.default(() => ""),
	   action: Yup.string()
			.when('subject', (subject, schema) => {
			if (Object.keys(SubjectActions).includes(subject)) {
				return Yup.string()
					.oneOf(SubjectActions[subject], 'form.validation.string.enum');		
	        }
			return schema;
        })
			.default(() => ""),
	   filter: Yup.string()
			.default(() => ""),
	   filteredColumn: Yup.string()
			.default(() => ""),
	   start: Yup.string()
			.default(() => ""),
	   end: Yup.string()
			.default(() => ""),
};

const ActivityConfig = { Subjects, SubjectActions, FilterFormValidationRules };

export default ActivityConfig;