import React, { useEffect, useState, useRef }  from 'react';
import {useWindowSize, useMouse, useMouseHovered} from 'react-use';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import parse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faListOl, faPlus } from '@fortawesome/free-solid-svg-icons'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import Loader from "../common/Loader";

import QuestionConfig from "../../config/QuestionConfig";

import logoHeader from '../../images/logo-horizontal-bg-dark.png';

import QuestionPreviewFormFields from './QuestionPreviewFormFields';

import Confetti from 'react-confetti';
import { isArrayWithLength } from '../../helpers/commons';

import API from "../../services/backend-api";

import useSound from 'use-sound';
import questionPassedAudio from '../../audios/question-passed.mp3';

import '../../styles/QuestionPreviewModal.css';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });
	 

	 
const close = {
	position: "absolute",
	right: 5,
	top: 0,
	zIndex: 999,
	fontSize: "2rem",
	fontWeight: "normal",
	color: "#fff",
	backgroundColor: "transparent",
	border: 0,
	opacity:1
}

const validationSchema = Yup.object().shape(QuestionConfig.QuestionPreviewFormValidationRules);


const ResolutionHintList = props => {
	
	const { t } = useTranslation();
	
	let resolutionHints = props.items
		.filter((item, index) => (index < props.hints))
		.map((item, index) => {
			return (
					<Row key={index} className="mb-3">
						<Col xs={"auto"} className="text-center font-monospace fw-bold text-primary">
							{item.stepOrder+"/"+props.items.length}
						</Col>
						<Col xs={"auto"}><div className="vr" style={{width: "5px", height: "100%"}}/></Col>
						<Col>
							<div>{parse(item.resolution)}</div>
						</Col>
					</Row>
				)
		});
		
    
	return(
		<>
			{resolutionHints}
			{(props.items.length > props.hints) ?
			  	<Row><Col>
			  		<Button variant="link" onClick={props.showMore}><FontAwesomeIcon icon={faPlus} /> <Trans i18nKey="questions.question-preview-modal.hints.show-more-button" values={{hint: props.hints, maxHints: props.items.length}}>Show Hint</Trans></Button>
			  	</Col></Row>
				:
				null
			}
		</>
	) 
	
}

const QuestionPreviewModal = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [source, setSource] = useState(null);
	const [runConfetti, setRunConfetti] = useState(false);
	const [questionPreviewResult, setQuestionPreviewResult] = useState(null);
	const [showTryAgainToast, setShowTryAgainToast] = useState(false);
	const [showPassedToast, setShowPassedToast] = useState(false);
	const [showQuestionHints, setShowQuestionHints] = useState(0);
	
	const { width, height } = useWindowSize()
	const refModal = useRef(null)
	const {docX, docY, posX, posY, elX, elY, elW, elH} = useMouseHovered(refModal, {bound: false, whenHovered: false});
	
	const [playQuestionPassedAudio] = useSound(questionPassedAudio);

	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.level === "CHALLENGE" && props.item.sourceId) {
			
			//Get Question Source 
			API.lookupQuestionSource(props.item.sourceId)
				.then(response => {	
					setSource(response);				
			})
			.catch(error => { 
				log.error("Error Loading Question Source: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
				if (isMounted) setIsLoading(false);
			});
		 							
		} else {
			if (isMounted) { 
				setIsLoading(false);
			}
		}
		 
		return () => { isMounted = false };
		  
	}, []);
	
	if (!props.item) {
		return null;
	} else {
		if (isLoading) {
			return <Loader />
		}
	}
	
	let initialValues = validationSchema.default();
	
	initialValues.type =  props.item.type;
	initialValues.questionStem =  props.item.questionStem;
	initialValues.answerOptions = props.item.answerOptions.map((item, index) => Object.assign({},item,{ index: index, isCorrectAnswer: false}));
	
	initialValues.source =  (source) ? source.name : "";
	initialValues.year =  props.item.year;
	
	//This method simulates a Backend API Request to verify Question Answer
	const verifyQuestionAnswer = (values) => {
		
		let response = {};
		
		if (values.type === "MULTIPLE_CHOICE" || values.type === "MULTIPLE_RESPONSE" || values.type === "TRUE_OR_FALSE") {
			
			let foundIncorrectAnswers = values.answerOptions.filter((item, index) =>  {
				return (JSON.parse(item.isCorrectAnswer) && !JSON.parse(props.item.answerOptions[index].isCorrectAnswer));
			});
			
			let foundCorrectAnswers = values.answerOptions.filter((item, index) =>  {
				return (JSON.parse(item.isCorrectAnswer) && JSON.parse(props.item.answerOptions[index].isCorrectAnswer));
			});
			
			response.result = !isArrayWithLength(foundIncorrectAnswers) ? "PASSED" : "FAILED";
			response.correctAnswers = foundCorrectAnswers;
			response.incorrectAnswers = foundIncorrectAnswers;
								
		} else if (values.type === "SHORT_ANSWER") {
			
			response.result = (props.item.shortAnswer.trim() === values.shortAnswer.trim()) ? "PASSED" : "FAILED";
	   
	   }
	   
	   return response;
	}
	
	const handleTryAgain = () => {
		 
		 setShowQuestionHints(showQuestionHints + 1);
		 setShowTryAgainToast(false);
		 setQuestionPreviewResult(null);
	}
	
	const handleShowMoreHint = () => {
		 setShowQuestionHints(showQuestionHints + 1);
		 setShowTryAgainToast(false);
		 setQuestionPreviewResult(null);
	}
	
	return (
		<Formik
			initialValues={initialValues}
		    validationSchema={validationSchema}
		    validateOnChange={false}
		    validateOnBlur={false}     
		    onSubmit={(values, actions) => {
		      	//Clear Error
		      	//_setError(null);
				
				let questionResult = verifyQuestionAnswer(values);
						
				if (questionResult.result === "PASSED") {
					playQuestionPassedAudio();
					setRunConfetti(true);
					setShowPassedToast(true);
				} else if (questionResult.result === "FAILED") {
							
					let maxQuestionHints = props.item.resolutionSteps.length;
							
					setShowTryAgainToast((maxQuestionHints > showQuestionHints));
				}
								
				setQuestionPreviewResult(questionResult);
				actions.setSubmitting(false);	

			}}
		 >
		 {({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		
			<Modal
		    	show={props.show}
		    	onHide={props.onHide}
		    	size={props.size}
		    	aria-labelledby="contained-modal-question-preview"
		    	centered
		    	keyboard={false}
				className="question-preview-modal"
				dialogClassName="question-preview-modal-dialog"
				scrollable 
			>
		    	<Modal.Header className="p-0">
  					<Container fluid className="p-0">
						<Card className="text-center border-0">
      						<Card.Header style={{backgroundColor: "#164E5C"}}>
								<Image src={logoHeader} height="45"/>
							</Card.Header>
  	        			</Card>
  	        			<button type="button" className="close" style={close} onClick={props.onHide} aria-label="Close">
  	        				<span aria-hidden="true">&times;</span>
  	        			</button>     
  	        		</Container>
  				</Modal.Header>
  				
  				<Modal.Body ref={refModal}>
        				
        				<Row className="mb-3 border-bottom">
        					<Col>
        						<Form id="form-question-preview-modal" className="form-question-preview-modal" noValidate>
		      						<QuestionPreviewFormFields 
										values={values} 
										errors={errors} 
										onChange={handleChange}
										dirty={dirty} 
										onError={props.onError}
										onSuccess={props.onSuccess}
										onWarning={props.onWarning}
										onInfo={props.onInfo}
										setFieldValue={setFieldValue}
										setFieldError={setFieldError}
										result={questionPreviewResult}
										i18nPrefix="questions.question-preview-modal."
									/>
								</Form>
							</Col>
						</Row>
						

						{(showQuestionHints > 0) ?
						<Row >
							<Col>
						 		<ResolutionHintList
							 		i18nPrefix={props.i18nPrefix}
							 		items={props.item.resolutionSteps}
							 		hints={showQuestionHints}
							 		showMore={handleShowMoreHint}
						 		/>
							</Col>
						</Row>
						: null
						}
						
						<div id="anchor" style={{overflowAnchor: "auto", height: "1px"}}></div>
				</Modal.Body>
  	        	
  	        	<Modal.Footer className="p-2" >		 
          			{runConfetti 
							? <Confetti 
								recycle={false} 
								//width={width}
      							//height={height}
								onConfettiComplete={() => {setRunConfetti(false);}}
								confettiSource={{y : elH, x: elW-20}}
				  		  	/>
						: null }
						
          			{ showTryAgainToast ? 
						<ToastContainer
          					className="p-3 w-75"
          					position={"bottom-end"}
          					style={{ zIndex: 1 }}
        				>
          					<Toast className="bg-white" show={showTryAgainToast} onClose={() => {setShowTryAgainToast(false);}}>
          				    	<Toast.Header closeButton={true} className="border-bottom-0 text-black">
          				    		<strong className="me-auto"><Trans i18nKey="questions.question-preview-modal.try-again-toast.header">Try Again</Trans></strong>
								</Toast.Header>
								<Toast.Body>
									<Row className="mb-3">
										<Col><Trans i18nKey="questions.question-preview-modal.try-again-toast.body">Try Again</Trans></Col>
									</Row>
									<Row>
										<Col>&nbsp;</Col>
										<Col xs={"auto"}>
											<Button variant="primary" onClick={handleTryAgain}>
												<FontAwesomeIcon icon={faListOl} /> <Trans i18nKey="questions.question-preview-modal.try-again-toast.show-hint-button">Show Hint</Trans>
											</Button>
										</Col>
									</Row>

								</Toast.Body>
          					</Toast>
        				</ToastContainer>
        				: null
        			}
        			
        			{ showPassedToast ? 
						<ToastContainer
          					className="p-3 w-75"
          					position={"bottom-end"}
          					style={{ zIndex: 1 }}
        				>
          					<Toast className="bg-white" show={showPassedToast} onClose={() => {setShowPassedToast(false);}}>
          				    	<Toast.Header closeButton={true} className="border-bottom-0 text-black">
          				    		<strong className="me-auto"><Trans i18nKey="questions.question-preview-modal.passed-toast.header">Passed</Trans></strong>
								</Toast.Header>
								<Toast.Body>
									<Row className="mb-3">
										<Col xs={"auto"}><FontAwesomeIcon size="2xl" className="text-success" icon={faCheckCircle} /></Col>
										<Col className="align-self-center"><Trans i18nKey="questions.question-preview-modal.passed-toast.body">Passed</Trans></Col>
									</Row>
								</Toast.Body>
          					</Toast>
        				</ToastContainer>
        				: null
        			}
          			
          			{ (!questionPreviewResult) ?
          				<Button variant="success" form='form-question-preview-modal' type="submit" disabled={isSubmitting} > 
          					{isSubmitting ? <Trans i18nKey="questions.question-preview-modal.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheck} /> <Trans i18nKey={"questions.question-preview-modal.submit"+((showQuestionHints > 0)?"-again":"")}>Submit</Trans></span>} 
          				</Button>
          				:
          				<Button variant="secondary" disabled={true}> 
          					{<Trans i18nKey="questions.question-preview-modal.answered">Answered</Trans>} 
          				</Button>
          			}
          		</Modal.Footer>
          	</Modal>
          )}
		</Formik>
	);
}

export default QuestionPreviewModal;
