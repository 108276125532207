import React,  { useState, useEffect, Fragment } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import Loader from "../common/Loader";
import PagedList from "../common/PagedList";
import QuestionFilterForm from "./QuestionFilterForm";
import CloneQuestionModal from "./CloneQuestionModal";

import QuestionConfig from "../../config/QuestionConfig";

import { jsonToCSV } from "react-papaparse";

import API from "../../services/backend-api";

const dataTableActionModals = [
      {
        key: 'action-1',
        component: CloneQuestionModal,
		size: 'lg',
		multiple : false
      }
    ];


const Questions = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedQuestions, setPagedQuestions] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	
	const { t } = useTranslation();
	
	let navigate = useNavigate();
	
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		//Initializing Action Modals Show Status
		resetModalsVisibility();
		
		pageQuestions({})
			.catch(error => { 
				log.error("Error Loading Initial Questions List: ", error.message);
				context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
	  setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }
   
   const handleSuccess = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }
   
  const updateQuestion = (question) => {
	  navigate("/questions/update", { state: { item: question} });
  }
  
  const addQuestion = () => {
	  navigate("/questions/create");
  }
    
  const updatePage = (values) => {
	  pageQuestions(values)
		.catch(error => { 
			log.error("Error Paginating Questions List: ", error.message);
			context.onError(error);
		})
		
  }
  
  const cloneQuestion = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageQuestions(values)
		.catch(error => { 
			log.error("Error Sorting Questions List: ", error.message);
			context.onError(error);
		})
		
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const refreshPage = () => {
		  
	  //List Questions with no new values
	  pageQuestions({})
		.catch(error => { 
	 		log.error("Error Refreshing Questions List: ", error.message);
	 		context.onError(error);
		})	
  }  
  
  const exportQuestions = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedQuestions, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findQuestions(exportFilters).then(response => {

			let columns = QuestionConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.active = t("questions.list.export.enums.active."+r.active);
				row.type = t("questions.list.export.enums.type."+r.type);
				row.field = t("questions.list.export.enums.field."+r.field);
				row.level = t("questions.list.export.enums.level."+r.level);
				
				row.answerOptions = JSON.stringify(r.answerOptions);
				row.resolutionSteps = JSON.stringify(r.resolutionSteps);
				row.images = JSON.stringify(r.images);
				row.skills = JSON.stringify(r.skills);
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("questions.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});

  
  const pageQuestions = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
		context.onError(null);
		
		API.pageQuestions(mergeQueryParams(pagedQuestions, values)).then(response => {
			setPagedQuestions(response);
			resolve(response);
		}).catch(error => { 	
			reject(error);
		});
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedQuestions) 
		return null;
		
	return (
		<Fragment>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="questions"
									items={selectedDataTableItems}
									onError={context.onError}
									onSuccess={handleSuccess}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="questions"
									item={selectedDataTableItem}
									onError={context.onError}
									onSuccess={handleSuccess}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			<PagedList
				i18nPrefix="questions.list."
				page={pagedQuestions}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={QuestionFilterForm}
				filterParameters={{ filter: pagedQuestions.filter, filteredColumn: pagedQuestions.filteredColumn }}
				onFilter={pageQuestions}
				onExport={exportQuestions}
				onError={context.onError}
				onSort={sortDataTable}
				onAdd={addQuestion}
				onPage={updatePage}
				dataTableColumns={["id", "field", "level", "type", "source", "whenModified"]} 
				dataTableSortableColumns={["whenModified"]}
				dataTableCustomDisplayColumns={[["whenModified", "ZonedDateTime"], ["field", "Enum"], ["level", "Enum"], ["type", "Enum"]]}
				dataTableActions={[updateQuestion, cloneQuestion]}
				dataTableDefaultAction={updateQuestion}
				dataTableCustomRowStyle={displayInactive}		
			/>
			</Fragment>
		
	);
}

export default Questions;
