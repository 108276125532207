import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import UserFormFields from "./UserFormFields";

import Prompt from "../common/Prompt";
import Loader from "../common/Loader";

import API from "../../services/backend-api";
import { useConfig } from "../../services/use-config";

import UserConfig from "../../config/UserConfig";
import UserPasswordConfig from "../../config/UserPasswordConfig";

import { ValidationError } from "../../helpers/custom-errors";

import { compareJSON } from "../../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   },
	   string: {
		     email: 'form.validation.string.email',
		   },
	 });


const updateUserValidationSchema = Yup.object().shape({
	...UserConfig.GeneralFormValidationRules,
	...UserConfig.AccessControlFormValidationRules
});

const createUserValidationSchema = Yup.object().shape({
	...UserConfig.GeneralFormValidationRules,
	...UserPasswordConfig.FormValidationRules,
	...UserConfig.AccessControlFormValidationRules
});


const saveUserTabs = [
	  {
        key: 'general',
        component: UserFormFields.GeneralSection,
		actions: ['create', 'update'],
      }, 
      {
        key: 'access-control',
        component: UserFormFields.AccessControlSection,
		actions: ['create', 'update']
      }
    ];

const SaveUser = props => {
	
	const [tabKey, setTabKey] = useState('general');
	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState(null);
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	let context = useOutletContext();
	
	const tabs = saveUserTabs;
	
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted && location.success)
			context.onSuccess(location.success)
		
		if (location.pathname === '/users/update' && location.state && location.state.item) {
			API.lookupUser(location.state.item.id)
			.then(response => {
					setUser(response);
			})
			.catch(error => { 
				log.error("Error Loading User: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		}
		 
		 return () => { isMounted = false };
		  
	}, [location.state]);
	
	
	if (location.pathname === '/users/update') {
		if (!location.state || !location.state.item) {
			return (<Navigate to="/users" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!user) {
			return null;
		}
	}
	
	let initialValues = {}, action, actionIcon, validationSchema, linearValidationRules;
	
	let defaultValues = createUserValidationSchema.default();
		
	if (location.pathname === '/users/update') {
		//let mergedRules = Object.assign(UserConfig.GeneralFormValidationRules, UserConfig.AccessControlFormValidationRules);
		Object.keys(Object.assign({}, UserConfig.GeneralFormValidationRules, UserConfig.AccessControlFormValidationRules))
			.forEach((key) => {
				initialValues[key] = (user[key]) ? user[key] : defaultValues[key];
		});
				
		action = "update";
		actionIcon = faCheckCircle;
		validationSchema = updateUserValidationSchema;
		linearValidationRules = [
			UserConfig.GeneralFormValidationRules,
			UserConfig.AccessControlFormValidationRules
		]
		
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		validationSchema = createUserValidationSchema;
		linearValidationRules = [
			Object.assign({}, UserConfig.GeneralFormValidationRules, UserPasswordConfig.FormValidationRules),
			UserConfig.AccessControlFormValidationRules
		];
		
	}
	
	const saveUser = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/users/update') {
				API.updateUser(location.state.item.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createUser(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		

	const hasTabErrors = (indexTab, errors) => {	
		
		return (Object.keys(linearValidationRules[indexTab])
			.find((key) => !(errors[key] == null)))
    }
    
  
	return (
		<Container >
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"users.save.header-"+action}>Save User</Trans> 
        			</h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col>
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						saveUser(values)
    							.then((response) => {
    								//actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/users", { state: { success: t("users.save.success")} });
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save User Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving User: ", error.message);
    								}
    								
    								context.onError(error);
    								
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		      	
		      	<Form className="form-user" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={
		      		      JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		        			   "when": compareJSON(initialValues, values)
		      		          })
		      		   }
		      		/>

					<Tab.Container id="userFormTab" activeKey={tabKey} onSelect={(k) => setTabKey(k)} >
					
					<Nav variant="tabs" className="mb-3">
					
						{ tabs.map((tab, index) => {
							
							if (!tab.actions.includes(action)) return null;
							
							return (
								<Nav.Item key={index}>
    								<Nav.Link eventKey={tab.key} className={ hasTabErrors(index, errors)  ? "text-danger" : ""}><Trans i18nKey={"users.save.tabs."+tab.key}>{tab.key}</Trans>{ hasTabErrors(index, errors) ? "*" : ""}</Nav.Link>
  								</Nav.Item>
	      					)
	      				})}
						
					</Nav>
					
					
					<Tab.Content>
						{ tabs.map((tab, index) => {
								
							const { 
		    					component: Component, 
		    						...rest 
		    				} = tab;
								
							
							if (!tab.actions.includes(action)) return null;
							
							return (
								
								<Tab.Pane key={index} eventKey={tab.key}>
									<Component 
										values={values} 
										errors={errors} 
										onChange={handleChange} 
										onError={context.onError}
										setFieldValue={setFieldValue}
										action={action}
									/>
								</Tab.Pane>

	      						)
    						})}
						
					</Tab.Content>
					
					</Tab.Container>
					
	    			    <Row className="mt-4">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="users.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"users.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
    						    
    							<Button as={Link} variant="secondary" className="float-end" to={"/users"}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="users.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			       </Row>     
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
	     </Container>
	);
}

export default SaveUser;
