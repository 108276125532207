import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faTimesCircle, faSearch} from '@fortawesome/free-solid-svg-icons'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
//import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl';
//import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../common/FormControlErrors";

import CustomLexicalEditor from '../lexical/CustomLexicalEditor';
import SearchSubjectTopicSkillModal from '../subject/SearchSubjectTopicSkillModal';


const skillToken = {
	//backgroundColor: "#e7f4ff",
	color: "#007bff",
	cursor: "pointer"
};

const QuestionResolutionStepFormFields = props => {
	
	const [searchSubjectTopicSkillModalShow, setSearchSubjectTopicSkillModalShow] = useState(false);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
			
		 return () => { isMounted = false };
		  
	}, []);
	

	const handleResolutionChange = (content) => {
        
        props.setFieldValue("resolution", content);  
    }
    
    const handleSubjectTopicSkillSelected = (values) => {
	  
	  setSearchSubjectTopicSkillModalShow(false);
	  	  
	  props.setFieldValue("skill", Object.assign({subject: values.subject.name, topic: values.topic.name}, values.skill));
	  props.setFieldError("skill", null);
	  
 	}
 	
 	const clearSubjectTopicSkill = () => {
			
		props.setFieldValue("skill", null);
		props.setFieldError("skill", null);
  }

   
	return(
		<Fragment>				
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridResolution">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.resolution.label"}>Resolution</Trans></FormLabel>
				    <CustomLexicalEditor
  						onChange={handleResolutionChange}
  						content={props.values.resolution}
  						isInvalid={!(props.errors.resolution == null)}
  						context={props.context}
					/>
				    <FormControlErrors block errors={props.errors.resolution} />
				</FormGroup>
			</Row>
			
			{(searchSubjectTopicSkillModalShow) && <SearchSubjectTopicSkillModal
					show={searchSubjectTopicSkillModalShow}
	        		onHide={() => setSearchSubjectTopicSkillModalShow(false)}
					size="lg"
					onSelected={handleSubjectTopicSkillSelected}
		      	/>
				}
				
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridSkill" >
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.skill.label"}>Skill</Trans> *</FormLabel>
					{(props.values.skill) ?
						<Row className="pt-1 pb-1">
							<Col md={"auto"}>
							<span className="ps-3 pe-3 pt-2 pb-2 rounded bg-light"style={skillToken} 
								onClick={(e) => { e.stopPropagation();}}>
				      				{props.values.skill.subject} &gt; {props.values.skill.topic} &gt; {props.values.skill.name}
				      				<FontAwesomeIcon className="text-danger ms-2" icon={faTimesCircle} 
				      						onClick={() => { clearSubjectTopicSkill();} }/>
				      		</span>
				      		</Col>
						</Row>
						:
						<InputGroup onClick={() => {setSearchSubjectTopicSkillModalShow(true);}} >
							<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
							<FormControl type={'text'} name="skill" className="text-uppercase" style={{backgroundColor: "#ffffff"}} readOnly placeholder={t(props.i18nPrefix+"form.skill.placeholder")} />
						</InputGroup>
					}
					<FormControlErrors block errors={props.errors.skill} />
				</FormGroup>
			</Row>
			
			<Row className="mb-3">
				<FormGroup as={Col} md={4} controlId="formGridEstimatedTime">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.estimatedTime.label"}>Estimated Time</Trans> *</FormLabel>
					<InputGroup>
						<FormControl type={'text'} name="estimatedTime" isInvalid={!(props.errors.estimatedTime == null)} value={props.values.estimatedTime} onChange={props.onChange} />
						<InputGroup.Text><Trans i18nKey={props.i18nPrefix+"form.estimatedTime.append-text"} /></InputGroup.Text>
						<FormControlErrors errors={props.errors.estimatedTime} />
  					</InputGroup>
				</FormGroup>
				
			</Row>
		</Fragment>
		
	) 
}


export default QuestionResolutionStepFormFields;
