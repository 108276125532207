import React, { useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter} from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';

import FormControlErrors from "../common/FormControlErrors";

import * as Yup from "yup";

import SubjectConfig from "../../config/SubjectConfig";

//import API from "../../services/backend-api";
//import { isArrayWithLength } from '../../helpers/commons';


const displayFiltersButton = {
    position: "absolute", 
	right: "0.75rem"
}

const validationSchema = Yup.object().shape(SubjectConfig.FilterFormValidationRules);

const SubjectFilterFormFields = props => {
	
	const [displayMoreFilters, setDisplayMoreFilters] = useState(false);
		
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 		
		
		return () => { isMounted = false };
		  
	}, []);
	

	const toogleDisplayFilters = () => {
		
		let currentDisplayMode = displayMoreFilters;
		
		if (displayMoreFilters) {
			
			let defaultValues = validationSchema.default();
			
			Object.keys(SubjectConfig.AdvancedFilterFormFields)
			.forEach((key) => {
				props.setFieldValue(key, defaultValues[key]);
			});
			
			props.submitForm();
		}
		
		setDisplayMoreFilters(!currentDisplayMode);
		
    } 

	
	return (
		<fieldset className="position-relative">
			<div className={"border rounded p-4"}>
			
				<FormGroup as={Row} controlId="formGridKnowledgeField" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"field.label"}>Knowledge Field</Trans>
    				</FormLabel>
					<Col sm="7">
						<FormSelect name="field" isInvalid={!(props.errors.field == null)} value={props.values.field} onChange={props.onChange} >
		    			    <option  value="">{t(props.i18nPrefix+"field.options.blank")}</option>
		    				{ SubjectConfig.KnowledgeFields.map(item =>
		    					<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"field.options."+item)}</option>
		    				)}
		    			</FormSelect>
						<FormControlErrors errors={props.errors.subjectId} />
					</Col>
				</FormGroup>		
				
				<FormGroup as={Row} controlId="formGridName" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"filter.label"}>Name</Trans>
    				</FormLabel>
					<Col sm="7">
						<FormControl type={'search'} name="filter" isInvalid={!(props.errors.filter == null)} value={props.values.filter} onChange={props.onChange} placeholder={t(props.i18nPrefix+"filter.placeholder")} />
						<FormControlErrors errors={props.errors.filter} />
					</Col>
					<Col sm="2">
						<Button variant="success" type="submit" disabled={props.isSubmitting} onClick={() => {}}> 
							{props.isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</Col>
				</FormGroup>
				
			</div>
		</fieldset>
	)

}

export default SubjectFilterFormFields;
