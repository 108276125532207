import React, {Fragment} from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';
import { isArrayWithLength} from "../../helpers/commons";

const FormControlErrors = props => {

	const { t } = useTranslation();
	
	if (!props.errors) {
		return null
	}
	
	let errorMessage = !isArrayWithLength(props.errors) ? t(props.errors) : t("form.validation.array.general");
	
	return (
		<Fragment>
			{ (!props.block) 
				? <FormControl.Feedback className="text-left" type="invalid">{errorMessage}</FormControl.Feedback>
				: <div className="text-left invalid-feedback" style={{display: "block"}}>{errorMessage}</div>
			}
		</Fragment>
	)
};

export default FormControlErrors;