import React, { useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import SearchSubjectTopicSkillFormFields from "./SearchSubjectTopicSkillFormFields";

import Prompt from "../common/Prompt";
import DismissableFeedback from "../common/DismissableFeedback";

import { useConfig } from "../../services/use-config";
//import API from "../../services/backend-api";
//import { AuthError, ValidationError } from "../../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   subject: Yup.object()
	   		.nullable()
	   		.required()
	   		.default(() => null),
	   topic: Yup.object()
	   		.nullable()
	   		.required()
	   		.default(() => null),
	   skill: Yup.object()
	   		.nullable()
	   		.required()
	   		.default(() => null),
});

const SearchSubjectTopicSkillModal = props => {

	const [_error, _setError] = useState(null);
	//const [isLoading, setIsLoading] = useState(true);
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		 
		return () => { isMounted = false };
		  
	}, []);

			
	let initialValues = validationSchema.default()
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-search-subject-topic-skill"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-search-subject-topic-skill">
  					<Trans i18nKey={"questions.search-topic-skill-modal.title"}>Search Subject Topic Skill</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Modal.Body className="mb-3">
				<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						actions.setSubmitting(false);
						
						props.onSelected(values);
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-search-subject-topic-skill-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

								<SearchSubjectTopicSkillFormFields
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={(err) => {_setError(err.message)}}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									i18nPrefix="questions.search-topic-skill-modal.form."
								/>
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="questions.search-topic-skill-modal.form.submitting">Please wait...</Trans> : <span><Trans i18nKey={"questions.search-topic-skill-modal.form.submit"}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="questions.search-topic-skill-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			</Modal.Body>
		</Modal>
	);
}

export default SearchSubjectTopicSkillModal;
