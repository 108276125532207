import React, { useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import SubjectTopicFormFields from "./SubjectTopicFormFields";

import Prompt from "../common/Prompt";
import DismissableFeedback from "../common/DismissableFeedback";

import { useConfig } from "../../services/use-config";
//import API from "../../services/backend-api";
import SubjectConfig from "../../config/SubjectConfig";
import { compareJSON } from "../../helpers/commons";
import ConfirmationDialogModal from '../common/ConfirmationDialogModal';
//import { AuthError, ValidationError } from "../../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveSubjectTopicValidationSchema = Yup.object().shape({
	...SubjectConfig.TopicFormValidationRules
});

const SaveSubjectTopicModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [showIgnoreUnsavedChangesModal, setShowIgnoreUnsavedChangesModal] = useState(false);
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		 
		return () => { isMounted = false };
		  
	}, []);

			
	let initialValues = {}, action, actionIcon, validationSchema;
	
	let defaultValues = saveSubjectTopicValidationSchema.default();
	
	validationSchema = saveSubjectTopicValidationSchema;
	
	//if props.item != null, then we have an update
	if (props.item) {		
		Object.keys(SubjectConfig.TopicFormValidationRules)
			.forEach((key) => {
				initialValues[key] = (props.item[key]) ? props.item[key] : defaultValues[key];
		});
		
		action = "update";
		actionIcon = faCheckCircle
		
	} else {	
		initialValues = defaultValues;
		action = "create";
		actionIcon = faCheckCircle;
	}	
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-add-subject-topic"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-subject-topic">
  					<Trans i18nKey={"subjects.save-topic-modal.title-"+action}>Save Subject Topic</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Modal.Body className="mb-3">
				<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						actions.setSubmitting(false);
						props.onItemSaved(action, values, (props.item) ? props.item.index : -1);     			
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-subject-topic-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>
		      				
		      				{(showIgnoreUnsavedChangesModal) && <ConfirmationDialogModal
								show={showIgnoreUnsavedChangesModal}
        						onHide={() => setShowIgnoreUnsavedChangesModal(false)}
								size="lg"
								title={t("form.unsaved-changes-modal.title")}
								bodyText={t("form.unsaved-changes-modal.body")}
								confirmText={t("form.unsaved-changes-modal.confirm")}
								cancelText={t("form.unsaved-changes-modal.cancel")}
								variant="danger"
								onConfirmation={props.onHide}
							/>}

								<SubjectTopicFormFields 
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={(err) => {_setError(err.message)}}
									//onSuccess={props.onSuccess}
									//onWarning={props.onWarning}
									//onInfo={props.onInfo}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									action={action}
									i18nPrefix="subjects.save-topic-modal."
								/>
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting || props.readOnly}> 
          				      		{isSubmitting ? <Trans i18nKey="subjects.save-topic-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"subjects.save-topic-modal.form.submit-"+action}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" 
          				      	onClick={() => { 
										if (!!dirty) setShowIgnoreUnsavedChangesModal(true)
										else props.onHide()
									}}
									>
									<FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="subjects.save-topic-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			</Modal.Body>
		</Modal>
	);
}

export default SaveSubjectTopicModal;
