import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import * as Yup from "yup";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import NumericInput from 'react-numeric-input';

import FormControlErrors from "../common/FormControlErrors";
import FormControlHelper from "../common/FormControlHelper";
import ItemizableTextArea from '../common/ItemizableTextArea';

import SubjectConfig from "../../config/SubjectConfig";
import Switch from '../common/Switch';

const validationSchema = Yup.object().shape(SubjectConfig.TopicSkillFormValidationRules);

const SubjectTopicSkillFormFields = props => {
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
			
		return () => { isMounted = false };
		  
	}, []);
	
	
	const handleNewTopicSkillsChange = (text, lineItems) => {
        props.setFieldValue("skills", lineItems.map(item => 
        	Object.assign({}, validationSchema.default(), {"name": item }))
        );
    }
	
	return(
		<Fragment>	
			{(props.action === "update") ?
				<>
				    <Row className="mb-3">
				    	<FormGroup as={Col} md={2} controlId="formGridSkillOrder">
				    		<FormLabel><Trans i18nKey={props.i18nPrefix+"form.skillOrder.label"}>Skill Order</Trans> *</FormLabel>
				    		<NumericInput className={!(props.errors.skillOrder == null) ? "form-control is-invalid" : "form-control" } strict={true} min={1} name="skillOrder" value={props.values.skillOrder} onChange={(valueAsNumber) => props.setFieldValue("skillOrder",valueAsNumber)} />
				    		<FormControlErrors errors={props.errors.skillOrder} />
						</FormGroup>
				    	<FormGroup as={Col} controlId="formGridName">
				    		<FormLabel><Trans i18nKey={props.i18nPrefix+"form.name.label"}>Name</Trans> *</FormLabel>
				    		<FormControl type={'text'} name="name" isInvalid={!(props.errors.name == null)} value={props.values.name} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.name.placeholder")} />
				    		<FormControlErrors errors={props.errors.name} />
						</FormGroup>
					</Row>
					<Row className="mb-3">    					   
	    				<FormGroup as={Col} controlId="formGridActive">
	    					<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.active.label"}>Active</Trans></FormLabel>
	    					<Switch 
	    						name="active"
	    						value={props.values.active}
	    						checked={JSON.parse(props.values.active)}
	    						onChange={props.onChange}
	    					/>
	    					<FormControlErrors block errors={props.errors.active} />
	    				</FormGroup>
	    			</Row>
				</>
				:
				<Row className="mb-3">	
					<FormGroup as={Col} controlId="formGridName">
						<FormLabel><Trans i18nKey={props.i18nPrefix+"form.skills.label"}>Name</Trans> <FormControlHelper text={props.i18nPrefix+"form.skills.helper"}/></FormLabel>
				    	<ItemizableTextArea
				    		isInvalid={!(props.errors.skills == null)}
							onChange={handleNewTopicSkillsChange}
							placeholder={props.i18nPrefix+"form.skills.placeholder"}
							counter={props.i18nPrefix+"form.skills.counter"}
						/>
						<FormControlErrors block errors={props.errors.skills} />
					</FormGroup>
				</Row>
			}
			
		</Fragment>
		
	) 
}


export default SubjectTopicSkillFormFields;
