import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinus} from '@fortawesome/free-solid-svg-icons'

import parse from 'html-react-parser';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import FormControlErrors from "../common/FormControlErrors";

const QuestionAnswerOptionList = props => {
	
	const { t } = useTranslation();
	
    const allowMultipleResponse= (props.questionType === "MULTIPLE_RESPONSE");
    
	return(
		<>
			{
			props.items.map((item, index) => {
				
				let buttonVariant, buttonClassName, buttonContent, answerContent, answerResult;
				
				if (props.result) {
					
					let foundCorrectAnswer = props.result.correctAnswers.find((item) => (item.index === index));
					let foundIncorrectAnswer = props.result.incorrectAnswers.find((item) => (item.index === index));
					
					buttonVariant = (foundCorrectAnswer) ? "success" : (foundIncorrectAnswer) ? "danger" : "secondary-outline border";
					buttonClassName =  (allowMultipleResponse ? "rounded": "rounded-circle");
					buttonContent = (foundCorrectAnswer) ? <FontAwesomeIcon size="sm" icon={faCheck} /> : (foundIncorrectAnswer) ? <FontAwesomeIcon size="sm" icon={faMinus} /> : parse("&#" +(65+index));
					answerResult = (foundCorrectAnswer) ? 
						<div className="text-success text-uppercase small">{t(props.i18nPrefix+"form.answerOptions.result.correct")}</div> 
						: (foundIncorrectAnswer) ? 
							<div className="text-danger text-uppercase small">{t(props.i18nPrefix+"form.answerOptions.result.incorrect")}</div> 
							: null;
						
				} else {
					
					buttonVariant = JSON.parse(item.isCorrectAnswer) ? "primary": "secondary-outline border";
					buttonClassName =  (allowMultipleResponse ? "rounded": "rounded-circle") + " question-answer-option-selector-button";
					buttonContent = parse("&#" +(65+index));
					answerResult = null;	
				}
				
				answerContent = (props.questionType === "TRUE_OR_FALSE") ?
						t(props.i18nPrefix+"form.answerOptions.options."+item.answer)
						: parse(item.answer);
						
				return (
					<Row key={index} className="border-top p-1 mb-1">
						<Col xs={"auto"}>
							<Button 
								size="sm"
								variant={buttonVariant} 
								className={buttonClassName}
								disabled={(props.result)}
								onClick={() => { props.updateCorrectAnswer(item, index, allowMultipleResponse);}}
							>
									{buttonContent}
							</Button>
						</Col>
						<Col className="align-self-center question-answer-option-text">
							{answerResult}
							{answerContent}
						</Col>
					</Row>
			)	
		  })}
		</>
	) 
	
}


const QuestionPreviewFormFields = props => {
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
			
		 return () => { isMounted = false };
		  
	}, []);
	
	const updateCorrectAnswer = (item, index, allowMultipleResponse) => {
	  
	 	let answerOptions = props.values.answerOptions
	  		.map((answer, i) => {
				if (index === i) {
					return Object.assign(answer, {"isCorrectAnswer" : !JSON.parse(answer.isCorrectAnswer)}) 
				} else {
					return (!allowMultipleResponse) ? Object.assign(answer,{"isCorrectAnswer" : false}) : answer;
				}
			});
	  
	   props.setFieldValue("answerOptions", answerOptions);
	   props.setFieldError("answerOptions", null);
  }
  
	return(
		<Fragment>				
			<Row className="mb-1">
				<Col className="question-preview question-stem">
					<strong>{props.values.source ? "["+props.values.source + "/" + props.values.year +"] ": null}</strong>
					{parse(props.values.questionStem)}
				</Col>
				</Row> 
				{(props.values.type === "MULTIPLE_CHOICE" || props.values.type === "MULTIPLE_RESPONSE" || props.values.type === "TRUE_OR_FALSE") ?
				<>
					<Row className="mb-0">
						<FormGroup as={Col} controlId="formGridAnswerOptions">
							<FormLabel className={(props.errors.answerOptions != null) ? "text-danger" : ""}>
							<strong><small>
								<Trans i18nKey={"questions.question-preview-modal.form.answerOptions.label."+props.values.type}>Answer Options</Trans>:
							</small></strong>
							</FormLabel>
						</FormGroup>
					</Row> 
					<Row className="align-items-center">
						<Col className="question-preview question-answer-option">
        					<QuestionAnswerOptionList 
        						i18nPrefix={props.i18nPrefix}
        						questionType={props.values.type}
        						items={props.values.answerOptions} 
        						updateCorrectAnswer={updateCorrectAnswer}
        						result={props.result}
        					/>
        					<FormControlErrors block={true} errors={props.errors.answerOptions} />
        				</Col>
					</Row>
		   </>
		   :
		   (props.values.type === "SHORT_ANSWER") ?
		    <Row className="mb-3">
		    	<FormGroup as={Col} controlId="formGridShortAnswer">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.shortAnswer.label"}>Short Answer</Trans></FormLabel>
					<FormControl type={'text'} name="shortAnswer" className="w-50" isInvalid={!(props.errors.shortAnswer == null)} value={props.values.shortAnswer} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.shortAnswer.placeholder")}/>
					<FormControlErrors block={true} errors={props.errors.shortAnswer} />
				</FormGroup>
		   </Row>
		    
		    : null
		   }
		</Fragment>
		
	) 
}


export default QuestionPreviewFormFields;
