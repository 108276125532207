import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'

import '../../styles/ZoomImageModal.css';

const body = {
	position: "relative",
	padding: 0,
	background: "#ccc",
}

const image = {
//	minHeight: 200,
}

const close = {
	position: "absolute",
	right: -30,
	top: 0,
	zIndex: 999,
	fontSize: "2rem",
	fontWeight: "normal",
	color: "#fff",
	backgroundColor: "transparent",
	border: 0,
	opacity:1
}


const ZoomImageModal = props => {
	
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-zoom-image"
		    centered
		    keyboard={false}
			className="zoom-image-modal"
		    >
  			<Modal.Body className="p-0" style={body}> 
  	          
  	        	<button type="button" className="close" style={close} onClick={props.onHide} aria-label="Close">
  	        		<span aria-hidden="true">&times;</span>
  	        	</button>     

  	        	<Image src={props.item.preview} className="img-fluid" style={image} />
		  	
  	        </Modal.Body>
		</Modal>
	);
}

export default ZoomImageModal;
