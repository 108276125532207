import React,{ useState, useEffect, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import FormControl from 'react-bootstrap/FormControl';
import Badge from 'react-bootstrap/Badge';

import { isArrayWithLength} from "../../helpers/commons";


const ItemizableTextArea = props => {
	
	const [value, setValue] = useState("");
	const [lineItems, setLineItems] = useState([]);
	
	const { t } = useTranslation();
	    
    const handleChange = (e) => {
        
        //Break Lines
        let items = e.target.value.split("\n").filter((item) => item.trim() !== "");
        
        log.debug(items);
         
        setLineItems(items);
        setValue(e.target.value);
        
        if (props.onChange)  {
			props.onChange(e.target.value, items);
		}
    }
    
	return(
		<Fragment>
			<FormControl 
				as="textarea"
				isInvalid={props.isInvalid}
				className="mb-1"
				rows={[...value].reduce((a, c) => a + (c === '\n' ? 1 : 0), 0) + 1} 
				value={value} 
				onChange={handleChange} 
				placeholder={t(props.placeholder)} 
			 />
			 { (isArrayWithLength(lineItems) && lineItems.length > 1 )?
				   <h5><Badge className="float-end" variant="info"><Trans i18nKey={props.counter} count={lineItems.length} /></Badge></h5>
				    : null
		 	 }
		</Fragment>
	) 
	
}

export default ItemizableTextArea;
