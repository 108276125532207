import React, { useState, useEffect } from 'react';

//import FormControl from 'react-bootstrap/FormControl';

const EditableText = props => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
		let isMounted = true; 
		
		setText(props.value);
		
		return () => { isMounted = false };
		  
	}, [props.value]);
	
  const handleDoubleClick = (e) => {
		setIsEditing(true);
  };
  
  const handleBlur = (e) => {
		setIsEditing(false);
		if (props.onChange)
			props.onChange(e.target.value);
  };
  
  const handleChange = (e) => {
		setText(e.target.value);
  };
  
  return (
    <div >
      {isEditing ? (
        <input
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          size={(props.size) ? props.size : null}
          className={(props.inputClassName) ? props.inputClassName : ""}
          autoFocus
        />
      ) : (
        <span onDoubleClick={handleDoubleClick}>{props.displayText}</span>
      )}
    </div>
  );
};

export default EditableText;

