import React, { useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
//import FormSelect from 'react-bootstrap/FormSelect';

import MultipleImageList from '../common/MultipleImageList';
import DismissableFeedback from "../common/DismissableFeedback";
import FormControlErrors from "../common/FormControlErrors";
import FormControlHelper from '../common/FormControlHelper';

import { useConfig } from "../../services/use-config";
import API from "../../services/backend-api";
//import { AuthError, ValidationError } from "../../helpers/custom-errors";


import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';
import { isArrayWithLength } from '../../helpers/commons';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   image: Yup.object()
	   		.nullable()
	   		.required("questions.select-image-modal.form.image.validation.required")
	   		.default(() => null),
});

const SelectQuestionImageModal = props => {

	const [_error, _setError] = useState(null);
	//const [isLoading, setIsLoading] = useState(true);
	const [images, setImages] = useState([])
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		
		let currentImages = props.items.map(item => {
			
			let image = {};
				
			if (item.systemFileName) {
				image.preview = API.getQuestionPublicImageUrl(item.systemFileName);
				image.disabled = false;
			} else if (item.uploadedImage) {
		    	image.preview = URL.createObjectURL(item.uploadedImage);
		    	image.disabled = true;
			}
				
			return image;
			
		});
			
		setImages(currentImages);

		 
		return () => { isMounted = false };
		  
	}, []);

			
	let initialValues = validationSchema.default()
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-select-image"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-select-image">
  					<Trans i18nKey={"questions.select-image-modal.title"}>Select Image</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Modal.Body className="mb-3">
				<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						actions.setSubmitting(false);
						
						props.onSelected(values.image);
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-select-image-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

							<Row className="mb-1">
								<FormGroup as={Col} controlId="formGridImage">
									<FormLabel><Trans i18nKey="questions.select-image-modal.form.image.label">Select Image</Trans> * <FormControlHelper text={"questions.select-image-modal.form.image.helper"}/></FormLabel>
										<fieldset className={!(errors.image == null) ? "is-invalid" : ""}>
											<div className={!(errors.image == null) ? "border border-danger rounded p-3" : "border rounded p-3"}>
												<MultipleImageList
													i18nPrefix={"questions.select-image-modal.form.image."}
													items={images}
													noItemsDisclaimer={true}
													onChange={(image) => { setFieldValue("image", image)}}
												/>
							    			</div>
										</fieldset>
								<FormControlErrors errors={errors.image} />
							</FormGroup>
						</Row>
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      {isArrayWithLength(images) ?
          				      	<Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="questions.select-image-modal.submitting">Please wait...</Trans> : <span><Trans i18nKey={"questions.select-image-modal.submit"}>Submit</Trans></span>} 
          				      	</Button>
          				      :
          				      	null
          				      }
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="questions.select-image-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			</Modal.Body>
		</Modal>
	);
}

export default SelectQuestionImageModal;
