import { Roles } from '../config/Roles'
import { Permissions } from '../config/Permissions'

import Users from "../components/user/Users";
import SaveUser from "../components/user/SaveUser";
import UpdateUserPassword from "../components/user/UpdateUserPassword";
import UpdateSelfPassword from "../components/user/UpdateSelfPassword";
import UsersImport from '../components/user/UsersImport';
import Home from "../components/Home";
import Activities from "../components/activity/Activities";
import About from '../components/About';
import Questions from '../components/question/Questions';
import SaveQuestion from '../components/question/SaveQuestion';
import Subjects from '../components/subject/Subjects';
import SaveSubject from '../components/subject/SaveSubject';



export const PrivateRoutesConfig = [
	{
		component: Users,
		path: '/users',
		title: 'header.navigation-titles.users',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		],
		home: [
			Roles.ROOT
		],
	},
	{
		component: SaveUser,
		path: '/users/create',
		title: 'header.navigation-titles.users-create',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			},
			{
				title: "header.navigation-titles.users",
				path : "/users"
			},
		]
	},
	{
		component: SaveUser,
		path: '/users/update',
		title: 'header.navigation-titles.users-update',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			},
			{
				title: "header.navigation-titles.users",
				path : "/users"
			},
		]
	},
	{
		component: UpdateUserPassword,
		path: '/users/update-password',
		title: 'header.navigation-titles.users-password-update',
		exact: true,
		roles: [
			Roles.ROOT
		]
	},
	{
		component: UpdateSelfPassword,
		path: '/profile/change-password',
		title: 'header.navigation-titles.profile-change-password',
		exact: true,
		hierarchy: [
			{
				title: "header.navigation-titles.profile"
			}
		]
	},
	{
		component: UsersImport,
		path: '/users/import',
		title:  'header.navigation-titles.users-import',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: Home,
		path: '/home',
		title: 'header.navigation-titles.home',
		exact: true
	},
	{
		component: Activities,
		path: '/activities',
		title:  'header.navigation-titles.activities',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: About,
		path: '/about',
		title:  'header.navigation-titles.about',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: Questions,
		path: '/questions',
		title: 'header.navigation-titles.questions',
		exact: true,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: SaveQuestion,
		path: '/questions/create',
		title: 'header.navigation-titles.questions-create',
		exact: true,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.questions",
				path : "/questions"
			},
		]
	},
	{
		component: SaveQuestion,
		path: '/questions/update',
		title: 'header.navigation-titles.questions-update',
		exact: true,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.questions",
				path : "/questions"
			},
		]
	},
	{
		component: SaveQuestion,
		path: '/questions/clone',
		title: 'header.navigation-titles.questions-clone',
		exact: true,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.questions",
				path : "/questions"
			},
		]
	},
	{
		component: Subjects,
		path: '/subjects',
		title: 'header.navigation-titles.subjects',
		exact: true,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		],
		home: [
			Roles.ADMIN
		],
	},
	{
		component: SaveSubject,
		path: '/subjects/create',
		title: 'header.navigation-titles.subjects-create',
		exact: true,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.subjects",
				path : "/subjects"
			},
		]
	},
	{
		component: SaveSubject,
		path: '/subjects/update',
		title: 'header.navigation-titles.subjects-update',
		exact: true,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.subjects",
				path : "/subjects"
			},
		]
	},
]