import React, {useState} from 'react';
import { Trans } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';

import KatexRenderer from "./KatexRenderer"

import Switch from "../../common/Switch";
import DismissableFeedback from "../../common/DismissableFeedback";
import FormControlErrors from "../../common/FormControlErrors";

import { ErrorBoundary } from "react-error-boundary"

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const validationSchema = Yup.object().shape({
	equation: Yup.string()
		.required()
   		.default(""),
   	inline: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => true),	
});

const InsertEquationModal = props => {
	const [_error, _setError] = useState(null);
  	
  	let initialValues = validationSchema.default()
  
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-insert-equation-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    	<Modal.Header closeButton>
  					<Modal.Title id="contained-modal-insert-equation-modal">
  						{(props.title)
  							? props.title
  							: <Trans i18nKey="components.insert-equation-modal.title">Insert Equation Title</Trans>
  						}
  					</Modal.Title>
  				</Modal.Header>
  				
  				<Formik
					initialValues={initialValues}
		    		validationSchema={validationSchema}
		    		validateOnChange={false}
		    		validateOnBlur={false}
		    		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						actions.setSubmitting(false);
						
						props.onConfirm(values.equation, values.inline)
					}}
		    	>
		   		{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		   
		   		<Form className="form-insert-equation-modal" noValidate>	
  					<Modal.Body className="pt-0 pb-0">
  						<Container className="p-2">
  					
  						<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />
  						
  						<Row className="mb-3">
							<FormGroup as={Col} controlId="formGridEquation">
								<FormLabel><Trans i18nKey={"components.insert-equation-modal.equation.label"}>Equation</Trans> *</FormLabel>
								<FormControl as="textarea" name="equation" value={values.equation} isInvalid={!(errors.equation == null)}  onChange={handleChange} />
								<FormControlErrors errors={errors.equation} />
							</FormGroup>
							<FormGroup as={Col} md={"auto"} controlId="formGridInline">
								<FormLabel><Trans i18nKey={"components.insert-equation-modal.inline.label"}>Inline</Trans></FormLabel>
								<Switch 
	    							name="inline"
	    							value={values.inline}
	    							checked={values.inline}
	    							onChange={handleChange}
	    						/>
							</FormGroup>
						</Row>
						<Row className="mb-1">
							<FormGroup as={Col} controlId="formGridPreviewLabel">
								<FormLabel><Trans i18nKey={"components.insert-equation-modal.preview.label"}>Preview</Trans></FormLabel>
							</FormGroup>
						</Row>
						<Row className="mb-3">
							<FormGroup as={Col} controlId="formGridPreviewRenderer">
								<ErrorBoundary onError={e => props.editor._onError(e)} fallback={null}>
          							<KatexRenderer
           								equation={values.equation}
            							inline={false}
            							onDoubleClick={() => null}
          							/>
        						</ErrorBoundary>
							</FormGroup>
						</Row>
					</Container>
		  		</Modal.Body>
          		<Modal.Footer>			      		
          			<Button variant="success" type="submit"> 
          				{(props.confirmText)
  							? props.confirmText
  							: <Trans i18nKey="components.insert-equation-modal.confirm">OK</Trans>
  						}
          			</Button>
          			<Button variant="secondary" onClick={props.onHide}>
          				{(props.cancelText)
  							? props.cancelText
  							: <Trans i18nKey="components.insert-equation-modal.cancel">Cancel</Trans>
  						}
					</Button>	
          		</Modal.Footer>
			</Form>	
		)}
		</Formik>
	</Modal>
	);
}

export default InsertEquationModal;
