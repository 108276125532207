import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $getNodeByKey
} from "lexical";
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $wrapNodes,
  $isAtNodeEnd
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { createPortal } from "react-dom";
import {
  $createHeadingNode,
  $isHeadingNode
} from "@lexical/rich-text";

import { Trans, useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button' 
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Dropdown from 'react-bootstrap/Dropdown';
//import DropdownButton from 'react-bootstrap/DropdownButton';
import FormSelect from 'react-bootstrap/FormSelect';
import InputGroup from 'react-bootstrap/InputGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faRedo, faBold, faItalic, faUnderline, faStrikethrough, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faSubscript, faSuperscript, faPlus, faPlusMinus, faImages, faImagePortrait, faImage, faPaintbrush, faFill } from '@fortawesome/free-solid-svg-icons'

import InsertEquationModal from "../ui/InsertEquationModal";
import InsertImageModal from "../ui/InsertImageModal";
//import DropdownColorPicker from "../ui/DropdownColorPicker"

//import EquationsPlugin from '../lexical/EquationsPlugin';
//import {InsertEquationDialog} from '../lexical/EquationsPlugin';

import {
  INSERT_EQUATION_COMMAND,
} from './EquationsPlugin';

import {
  INSERT_IMAGE_COMMAND,
} from './ImagesPlugin';

import {
  INSERT_INLINE_IMAGE_COMMAND,
} from './InlineImagePlugin';

import ColorPicker from "../ui/ColorPicker";



const LowPriority = 1;

const supportedBlockTypes = new Set([
  "paragraph",
  "h1",
  "h2",
  "ul",
  "ol"
]);

const toolbarButtonsVariant = "secondary-outline";

function Divider() {
  return <div className="vr" />;
}

function Select({ onChange, className, options, value }) {
  return (
    <select className={className} onChange={onChange} value={value}>
      <option hidden={true} value="" />
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

const BlockOptionsDropdownList = ({
  editor,
  blockType
}) => {

  const { t } = useTranslation();
  
  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };


	const handleBlockTypeChange = (e) => {
		
		switch (e.target.value) {
			case "paragraph":
				formatParagraph();
				break;
			case "h1":
				formatLargeHeading();
				break;
			case "h2":
				formatSmallHeading();
				break;
			case "ul":
				formatBulletList();
				break;
			case "ol":
				formatNumberedList();
				break;
			default:
        }
	}
	
	return (
     <FormSelect name="blockType" value={blockType} onChange={handleBlockTypeChange} >
		 { Array.from(supportedBlockTypes).map(item =>
		    <option key={item} value={item}>{t("components.lexical-editor.toolbar.commands.formatting."+item)}</option>
		 )}
	 </FormSelect>
  );
}


export default function ToolbarPlugin(props) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [showInsertEquationModal, setShowInsertEquationModal] = useState(false);
  const [showInsertImageModal, setShowInsertImageModal] = useState(false);
  const [isRTL, setIsRTL] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [fontColor, setFontColor] = useState("#000")
  const [bgColor, setBgColor] = useState("#fff")
  
  const { t } = useTranslation();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Handle buttons
      setFontColor(
        $getSelectionStyleValueForProperty(selection, "color", "#000")
      )
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          "background-color",
          "#fff"
        )
      )
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setIsRTL($isParentElementRTL(selection));

    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);
  
 const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      editor.update(
        () => {
          const selection = $getSelection()
          if (selection !== null) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? { tag: "historic" } : {}
      )
    },
    [editor]
  )
  
  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack)
    },
    [applyStyleText]
  )

  const onBgColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ "background-color": value }, skipHistoryStack)
    },
    [applyStyleText]
  )
  
 
  return (
    <ButtonToolbar className="lexical-toolbar border rounded mb-2" ref={toolbarRef}>
      <ButtonGroup className="me-2">
      	<Button
        	disabled={!canUndo}
       		onClick={() => {
          	editor.dispatchCommand(UNDO_COMMAND);
        	}}
        	className="toolbar-item"
        	aria-label={t("components.lexical-editor.toolbar.commands.undo")}	
      	>
        	<FontAwesomeIcon icon={faUndo} />
      	</Button>
      	<Button
        	disabled={!canRedo}
        	onClick={() => {
          		editor.dispatchCommand(REDO_COMMAND);
        	}}
        	className="toolbar-item"
        	aria-label={t("components.lexical-editor.toolbar.commands.redo")}
      	>
        <FontAwesomeIcon icon={faRedo} />
      </Button>
      </ButtonGroup>
      <Divider />
      
      <InputGroup className="ms-2 me-2">
      	<BlockOptionsDropdownList
      		editor={editor}
            blockType={blockType}
      	/>
      </InputGroup>
      <Divider />
      
      <ButtonGroup className="ms-2 me-2" >
         <Button
            onClick={() => {
              	editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
            }}
            className={"toolbar-item" + (isBold ? " active" : "")}
            aria-label={t("components.lexical-editor.toolbar.commands.bold")}
            variant={toolbarButtonsVariant}
          >
            <FontAwesomeIcon icon={faBold} />
          </Button>
          <Button
          	onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
            	}}
            	className={"toolbar-item" + (isItalic ? " active" : "")}
            	aria-label={t("components.lexical-editor.toolbar.commands.italics")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faItalic} />
          	</Button>
          	<Button
            	onClick={() => {
              	editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
            	}}
            	className={"toolbar-item" + (isUnderline ? " active" : "")}
            	aria-label={t("components.lexical-editor.toolbar.commands.underline")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faUnderline} />
          	</Button>
          	<Button
            	onClick={() => {
              		editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
           	 	}}
           	 	className={"toolbar-item" + (isStrikethrough ? " active" : "")}
            	aria-label={t("components.lexical-editor.toolbar.commands.strikethrough")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faStrikethrough} />
          	</Button>
          </ButtonGroup>
          <Divider />
          <ButtonGroup className="ms-2 me-2" >
          	<Button
            	onClick={() => {
              		editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
            	}}
            	className="toolbar-item"
            	aria-label={t("components.lexical-editor.toolbar.commands.align-left")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faAlignLeft} />
          	</Button>
          	<Button
            	onClick={() => {
              	editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
            	}}
            	className="toolbar-item"
				aria-label={t("components.lexical-editor.toolbar.commands.align-center")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faAlignCenter} />
          	</Button>
          	<Button
            	onClick={() => {
              		editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
            	}}
            	className="toolbar-item"
            	aria-label={t("components.lexical-editor.toolbar.commands.align-right")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faAlignRight} />
          	</Button>
          	<Button
            	onClick={() => {
              		editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
            	}}
            	className="toolbar-item"
            	aria-label={t("components.lexical-editor.toolbar.commands.align-justify")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faAlignJustify} />
          	</Button>{" "}
         </ButtonGroup>
         
         {/*<Divider />
         
         <ButtonGroup className="ms-2 me-2" >
          	<Button
            	onClick={() => {
              		editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
            	}}
            	className={"toolbar-item" + (isSuperscript ? " active" : "")}
				aria-label={t("components.lexical-editor.toolbar.commands.superscript")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faSuperscript} />
          	</Button>
          	<Button
            	onClick={() => {
              		editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
            	}}
            	className={"toolbar-item" + (isSubscript ? " active" : "")}
            	aria-label={t("components.lexical-editor.toolbar.commands.subscript")}
            	variant={toolbarButtonsVariant}
          	  >
            	<FontAwesomeIcon icon={faSubscript} />
          	</Button>
         </ButtonGroup>*/}
         
         <Divider />
         
         {/*<DropdownColorPicker
            buttonClassName="toolbar-item color-picker"
            buttonAriaLabel="Formatting text color"
            buttonIconClassName="icon font-color"
            color={fontColor}
            onChange={onFontColorSelect}
            title="text color"
          />*/}
          
          <Dropdown className="ms-2" id="fontColorDropdown">
      		<Dropdown.Toggle variant={toolbarButtonsVariant} className={"toolbar-item"}>
        		<FontAwesomeIcon icon={faPaintbrush} />
      		</Dropdown.Toggle>
      		<Dropdown.Menu>
        		<ColorPicker color={fontColor} onChange={onFontColorSelect}/>
      		</Dropdown.Menu>
    	  </Dropdown>
    	  
    	  <Dropdown className="me-2" id="bgColorDropdown">
      		<Dropdown.Toggle variant={toolbarButtonsVariant} className={"toolbar-item"}>
        		<FontAwesomeIcon icon={faFill} />
      		</Dropdown.Toggle>
      		<Dropdown.Menu>
        		<ColorPicker color={bgColor} onChange={onBgColorSelect}/>
      		</Dropdown.Menu>
    	  </Dropdown>
          
          {/*<DropdownButton
         	as={ButtonGroup}
         	title={"Math"}
         	id="equationsDropdown"
         	className="ms-2 me-2"
      	  >
           <Dropdown.Item ><ColorPicker /></Dropdown.Item>
        </DropdownButton>*/}
          
          <Divider />
          
          {showInsertEquationModal && (
			<InsertEquationModal
				show={showInsertEquationModal} 
				onHide={() => {setShowInsertEquationModal(false);}}
				activeEditor={editor}
				onConfirm={(equation, inline) => {
      				//console.log(equation, inline);
      				editor.dispatchCommand(INSERT_EQUATION_COMMAND, { equation, inline });
      				setShowInsertEquationModal(false);
				}}
			/>
		  )}
          
          <ButtonGroup className="ms-2 me-2" >
          	<Button
            	onClick={() => {setShowInsertEquationModal(true);}}
            	className={"toolbar-item"}
				aria-label={t("components.lexical-editor.toolbar.commands.equations")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon className="me-1" icon={faPlusMinus} /> <Trans i18nKey={"components.lexical-editor.toolbar.commands.equations"}>Equations</Trans> 
          	</Button>
         </ButtonGroup>
         
          <Divider />
          
          {showInsertImageModal && (
			<InsertImageModal
				size="lg"
				show={showInsertImageModal} 
				onHide={() => {setShowInsertImageModal(false);}}
				activeEditor={editor}
				onConfirm={(payload, inline) => {
      				editor.dispatchCommand((inline) ? INSERT_INLINE_IMAGE_COMMAND : INSERT_IMAGE_COMMAND, payload);
      				setShowInsertImageModal(false);
				}}
				images={props.context.images}
			/>
		  )}
		  
          <ButtonGroup className="ms-2 me-2" >
          	<Button
            	onClick={() => {setShowInsertImageModal(true);}}
            	className={"toolbar-item"}
				aria-label={t("components.lexical-editor.toolbar.commands.image")}
            	variant={toolbarButtonsVariant}
          	>
            	<FontAwesomeIcon icon={faImage} />
          	</Button>
         </ButtonGroup>
          {/*<DropdownButton
         	as={ButtonGroup}
         	title={"Math"}
         	id="equationsDropdown"
         	className="ms-2 me-2"
      	  >
           <Dropdown.Item >Equations</Dropdown.Item>
        </DropdownButton>*/}
       
       
         
         
    </ButtonToolbar>
  );
}
