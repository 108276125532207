import React,{ useState, useEffect, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
//import 'moment/locale/en';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import FormCheck from 'react-bootstrap/FormCheck';

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { useConfig } from "../../services/use-config";

import { isArrayWithLength, formatCurrency } from "../../helpers/commons";

import '../../styles/DataTable.css';


const DataTableHeader = props => {
	const [selectedAll, setSelectedAll] = useState(false);
	
	var row = [];
	var columns = [];
	
	useEffect(() => {
		let isMounted = true; 
				
		if (isMounted) {
			setSelectedAll(false);
		}
		
		return () => { isMounted = false };
		  
	}, [props.items]);
	
	
	const handleMultipleSelectionChange = (e) => {
		setSelectedAll(e.target.checked);
		props.onMultipleSelectChange(e.target.checked);
    }
	
	if (isArrayWithLength(props.columns)) {
		if (isArrayWithLength(props.items)) {
			
			columns = props.columns.filter(column => {
				if (props.items[0].hasOwnProperty(column)) {
					return true;
				} else {
					log.warn("DataTableHeader: Column [", column,"] not found => Removed")
					return false;
				}
			})
			
		} else {
			columns = props.columns;
		}
	} else if (isArrayWithLength(props.items)) {
		for (var key in props.items[0]) {
			columns.push(key);
		}
	}

	row = columns.map((column, index) => {
		
		if ((!isArrayWithLength(props.items)) || (!isArrayWithLength(props.sortableColumns)) || (isArrayWithLength(props.sortableColumns) && props.sortableColumns.indexOf(column) === -1)) {
			return (<th scope="col" key={index}>{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns."+column} /> : column } </th>)
		} else {
			
			let className = "header";
			let sortBy = column;
			let order = props.order;

			if(props.sortBy === column) {
				 if(props.order === "asc") {
				      order = "desc";
				      className += " headerSortDown";
				 } else {
				      order = "asc";
				      className += " headerSortUp";
				 }
			} else {
				 order = "asc";
			}

			return (<th scope="col" className={className} key={index}  onClick={() => {props.sortDataTable({sortBy: sortBy, order: order });}}>
					{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns."+column} /> : column}
				</th>)
		}
		
	})
		
	if (isArrayWithLength(props.actions) && row.length !== 0) {
		row.push(<th key={"actions"}>
			{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns.actions"} /> : "actions"}
		</th>)
	}
	
	if (isArrayWithLength(props.multipleSelectActions) && row.length !== 0) {
		
		var actionItems = [];
		
		var selectedItems = props.items.filter((item, index) => props.selectedItems[index]);
		
		actionItems = props.multipleSelectActions.map((action, index) => 
			<Dropdown.Item key={index} onClick={() => {action(selectedItems, index);}}><Trans i18nKey={props.i18nPrefix+"multiple-action-"+index} /></Dropdown.Item>
		)
		
		row.unshift(<th key={"multipleSelectActions"} className="align-top" >
			<FormCheck type="checkbox">
        		<FormCheck.Input type="checkbox" checked={selectedAll} onChange={handleMultipleSelectionChange} />
				{ (isArrayWithLength(selectedItems)) ?
					<Dropdown drop="left">
						<Dropdown.Toggle as={Button} className="p-0" bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
							<FontAwesomeIcon icon={faEllipsisV} />
						</Dropdown.Toggle>
		       			<Dropdown.Menu>
		       				{actionItems}
		       			</Dropdown.Menu>
		    		</Dropdown> : null
				}
			</FormCheck>
		</th>)
	}
	
	return row;
}

const DataTableRowItem = props => {

	const { t } = useTranslation();
	
	const renderTooltip = (text) => (
  		<Tooltip>
    		{t(text)}
  		</Tooltip>
	);
	
	let displayValue = Array.isArray(props.item) 
			? props.item.toString() 
			: (typeof props.item == "boolean") ? JSON.stringify(props.item)
			: props.item;
			
	/*let displayValue;
	if (typeof props.item === 'object') { 
		if (Array.isArray(props.item)) displayValue = props.item.toString();
	} else {
		displayValue = props.item;
	}*/
	
	/*let displayValue = (typeof props.item === 'object') 
			? props.item.toString() 
			: props.item;*/
			
	/*let displayValue = (typeof props.item === 'object') 
			? Array.isArray(props.item) ? props.item.toString() : JSON.stringify(props.item) 
			: props.item;*/

	if (props.customDisplay) {
		
		//First check if a custom function
		if (props.customDisplay instanceof Function) {
			displayValue = props.customDisplay(props.item);
		} else {
			
			switch (props.customDisplay) {
			 	case 'ZonedDateTime':
				  	displayValue = (props.item) ? <Moment local format="L LT">{props.item}</Moment> : "-";
			  	  	break;
			 	case 'LocalDate':
				  	displayValue = (props.item) ? <Moment local format="L">{props.item}</Moment> : "-";
				   	break;
			 	case 'Enum':
				  	displayValue = isArrayWithLength(props.item)
						? props.item.map(i => t(props.i18nPrefix+"enums."+props.column+"."+i)).join(', ')
						: (props.item) ? <Trans i18nKey={props.i18nPrefix+"enums."+props.column+"."+props.item}/> : "-";
				   break;
				case 'Boolean':
				  	displayValue = (typeof props.item == "boolean") ? <Trans i18nKey={props.i18nPrefix+"enums."+props.column+"."+props.item}/> : "-";
				   	break;
				case 'Currency':
					displayValue = (props.item == null) ? "-" : formatCurrency(props.item, props.locale, props.numberFormat.currency);
				   	break;
				 case 'Json':
					displayValue = (props.item == null) ? "-" : JSON.stringify(props.item);
				   	break;
				 case 'Count':
					displayValue = !isArrayWithLength(props.item) ? "0" : props.item.length;
				   	break;
				 default:
				  	break;
				}
			}
		}
		
		if (props.tooltipData) {
			return (
				<td style={props.tooltipTargetStyles} >
					<OverlayTrigger overlay={renderTooltip(props.tooltipData)}>
						<span title={props.tooltipData}>{(displayValue) ? displayValue : "-"}</span>
					</OverlayTrigger>
				</td>
			);
		} else if (props.handleTextOverflow) {
				return (<td className="table-row-item-overflow">{displayValue}</td>);
		} else {
			return (<td>{displayValue}</td>);
		}
	
};

const DataTableTotalRow = props => {
	var row = [];
	var columns = [];
	
	if (isArrayWithLength(props.columns)) {
		if (isArrayWithLength(props.items)) {
			
			columns = props.columns.filter(column => {
				if (props.items[0].hasOwnProperty(column)) {
					return true;
				} else {
					log.warn("DataTableTotalRow: Column [", column,"] not found => Removed")
					return false;
				}
			})
			
		} else {
			columns = props.columns;
		}
	} else if (isArrayWithLength(props.items)) {
		for (var key in props.items[0]) {
			columns.push(key);
		}
	}
	
	let customDisplayColumnsMap = (isArrayWithLength(props.customDisplayColumns)) ? new Map(props.customDisplayColumns) : null;
	
	row = columns.map((column, index) => {
		
		if ((!isArrayWithLength(props.items) || props.showTotalRowColumns.indexOf(column) === -1)) {
			return (<td key={index} ></td>)
		} else {
			let total = 0;
			props.items.forEach(i => { total += i[column];})
			
			let displayTotal = total;
			
			let customDisplay = (customDisplayColumnsMap && customDisplayColumnsMap.get(column)) ? customDisplayColumnsMap.get(column) : null;
			if (customDisplay && !(customDisplay instanceof Function)) {
				switch (customDisplay) {
					case 'Currency':
						displayTotal = formatCurrency(total, props.locale, props.numberFormat.currency);
				    	break;
			   		default:
				  		break;
			 	}
			}
			
			return (<td key={index}>{displayTotal}</td>)
		}
		
	})
	
	//Add complementary Columns
	if (isArrayWithLength(row)) {
		
		//Add MultipleSelectActions Check Column
		if (isArrayWithLength(props.multipleSelectActions)) {
			row.unshift(<td key={"multipleSelectActions"}></td>)
		}
	
		//Add Actions DropDown Column
		if (isArrayWithLength(props.actions)) {
				row.push(<td key={"actions"}></td>)
		}
	}
	
	return <tr className="font-weight-bold text-info">{row}</tr>;
}

const DataTableRow = props => {
	var row = [];
	var columns = [];
	
	if (isArrayWithLength(props.columns)) {
		columns = props.columns.filter(column => {
			if (props.item.hasOwnProperty(column)) {
				return true;
			}
			
			return false;
		})
				
	} else {
		for (var key in props.item) {
			columns.push(key);
		}
	}
	
	let customDisplayColumnsMap = (isArrayWithLength(props.customDisplayColumns)) ? new Map(props.customDisplayColumns) : null;
		
	row = columns.map((column, index) => {
		
		return(<DataTableRowItem
	           		key={index}
					item={props.item[column]}
					tooltipData={( props.tooltipData && props.tooltipData[column]) ? props.tooltipData[column] : null}
					tooltipTargetStyles={props.tooltipTargetStyles}
	           		column={column} 
	           		customDisplay={(customDisplayColumnsMap && customDisplayColumnsMap.get(column)) ? customDisplayColumnsMap.get(column) : null}
	           		i18nPrefix={props.i18nPrefix}
					locale={props.locale}
					numberFormat={props.numberFormat} 
					handleTextOverflow={props.handleTextOverflow}
	    />);
			
	});
	
	if (isArrayWithLength(props.actions) && row.length !== 0) {
		
		var actionItems = [];
				
		actionItems = props.actions.map((action, actionIndex) => 
			<Dropdown.Item key={actionIndex} onClick={() => {action(props.item, actionIndex, props.rowIndex);}}><Trans i18nKey={props.i18nPrefix+"action-"+actionIndex} values={{item: props.item}}/></Dropdown.Item>
		)
		
		row.push(<td key={"actions"} >
			<Dropdown drop="left">
				<Dropdown.Toggle as={Button} bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
					<FontAwesomeIcon icon={faEllipsisV} />
				</Dropdown.Toggle>
		       <Dropdown.Menu>
		       		{actionItems}
		       </Dropdown.Menu>
		    </Dropdown>
		</td>
		)
	}
	
	if (isArrayWithLength(props.multipleSelectActions) && row.length !== 0) {
		row.unshift(<td key={"multipleSelectActions"}>
			<FormCheck checked={(!props.selected) ? false : props.selected} onChange={(e) => {props.onSelect(props.index, e.target.checked);}} />
		</td>)
	}
	
	return row;
}



const DataTable = props => {

	const [selectedItems, setSelectedItems] = useState([]);
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
				
		if (isArrayWithLength(props.items) && isMounted) {
			setSelectedItems(Array.from({length: props.items.length}, (v, i) => false));
		}
		
		return () => { isMounted = false };
		  
	}, [props.items]);
	

	if (!isArrayWithLength(props.items)) {
		if (props.noItemsDisclaimer)
			return <Trans i18nKey={props.i18nPrefix+"no-items-disclaimer"} />
		else
			return null
	}
	
	const handleRowDoubleClick = (event, item, rowIndex) => {
		if (props.defaultAction && (event.detail === 2)) { 
		
			let foundIndex = isArrayWithLength(props.actions) ? props.actions.map( a => a.toString()).indexOf(props.defaultAction.toString()) : -1;
		
			props.defaultAction(item, foundIndex, rowIndex) 
		}
    };
	
	const handleMultipleSelectionChange = (checked) => {	
		setSelectedItems(Array.from({length: props.items.length}, (v, i) => checked));
    }

	const handleSelection = (index, checked) => {
		
		//alert(index + " " + checked)
		
		let s = selectedItems.map((item, i) => {
				return (index !== i) ? item : checked;	
		});
		
		setSelectedItems(s);
        
    }
	
	const items = props.items.map((item, index) => {
	      return (
	        <tr key={index} onClick={(e) => {handleRowDoubleClick(e, item, index);}} className={(props.customRowStyle) ? props.customRowStyle(item) : ""}>
	           <DataTableRow 
	           		item={item}
					tooltipData={(isArrayWithLength(props.tooltipData) && props.tooltipData[index]) ? props.tooltipData[index] : null }
					tooltipTargetStyles={props.tooltipTargetStyles}
	           		columns={props.columns} 
	           		customDisplayColumns={props.customDisplayColumns}
	           		i18nPrefix={props.i18nPrefix} 
					handleTextOverflow={props.handleTextOverflow}
	           		actions={props.actions}
					defaultAction={props.defaultAction}
					multipleSelectActions={props.multipleSelectActions}
					onSelect={handleSelection}
					selected={selectedItems[index]}
					locale={config.preferredLanguage}
					numberFormat={config.numberFormat}
					rowIndex={index}
	           	/>
	        </tr>
	        )
	      })
	
	return (
		<Table size="sm" striped responsive hover className={isArrayWithLength(props.sortableColumns) ? "table-sortable" : ""}>
			<thead>
				<tr>
				 <DataTableHeader 
				 	items={props.items} 
				 	columns={props.columns} 
				 	sortableColumns={props.sortableColumns} 
				 	sortBy={props.sortBy} 
				 	order={props.order} 
				 	i18nPrefix={props.i18nPrefix} 
				 	actions={props.actions}
					multipleSelectActions={props.multipleSelectActions}
					onMultipleSelectChange={handleMultipleSelectionChange}
					selectedItems={selectedItems}
					locale={config.preferredLanguage}
					numberFormat={config.numberFormat}
				 	sortDataTable={props.sortDataTable} />
				</tr>
			</thead>
			<tbody>
	          <Fragment>	
			  {items}
			  {(isArrayWithLength(props.showTotalRowColumns)) ? 
					<DataTableTotalRow 
						items={props.items} 
						columns={props.columns}
						customDisplayColumns={props.customDisplayColumns}
						showTotalRowColumns={props.showTotalRowColumns}
						actions={props.actions}
						multipleSelectActions={props.multipleSelectActions}
						locale={config.preferredLanguage}
						numberFormat={config.numberFormat}
						i18nPrefix={props.i18nPrefix}
					/> 
					: null
			  }
				{(props.customFooterRow) ? 
					props.customFooterRow
					: null
			  }
			</Fragment>	
	        </tbody>
	     </Table>
	);
}

export default DataTable;
