import React, { useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import QuestionAnswerOptionFormFields from "./QuestionAnswerOptionFormFields";

import Prompt from "../common/Prompt";
import DismissableFeedback from "../common/DismissableFeedback";

import { useConfig } from "../../services/use-config";
//import API from "../../services/backend-api";
import QuestionConfig from "../../config/QuestionConfig";
import { compareJSON } from "../../helpers/commons";
//import { AuthError, ValidationError } from "../../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveQuestionAnswerOptionValidationSchema = Yup.object().shape({
	...QuestionConfig.QuestionAnswerOptionFormValidationRules
});

const SaveQuestionAnswerOptionModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		 
		return () => { isMounted = false };
		  
	}, []);
		
	let initialValues = {}, readOnlyFields = {}, action, actionIcon, validationSchema;
	
	let defaultValues = saveQuestionAnswerOptionValidationSchema.default();
	
	validationSchema = saveQuestionAnswerOptionValidationSchema;
	
	//if props.item != null, then we have an update
	if (props.item) {		
		Object.keys(QuestionConfig.QuestionAnswerOptionFormValidationRules)
			.forEach((key) => {
				initialValues[key] = (props.item[key]) ? props.item[key] : defaultValues[key];
		});
		
		action = "update";
		actionIcon = faCheckCircle
	
	} else {	
		initialValues = defaultValues;
		action = "create";
		actionIcon = faCheckCircle;
	}
	
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-answer-option"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-answer-option">
  					<Trans i18nKey={"questions.save-answer-option-modal.title-"+action}>Save Question Alternative Answer</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Modal.Body className="mb-3">
				<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						actions.setSubmitting(false);
						props.onAnswerOptionSaved(action, values, (props.item) ? props.item.index : -1);     			
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-question-answer-option-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

								<QuestionAnswerOptionFormFields 
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={props.onError}
									onSuccess={props.onSuccess}
									onWarning={props.onWarning}
									onInfo={props.onInfo}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									action={action}
									readOnlyFields={readOnlyFields}
									i18nPrefix="questions.save-answer-option-modal."
									lineItems={props.lineItems}
									context={props.context}
								/>
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="questions.save-answer-option-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"questions.save-answer-option-modal.form.submit-"+action}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="questions.save-answer-option-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			</Modal.Body>
		</Modal>
	);
}

export default SaveQuestionAnswerOptionModal;
