import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faClose, faEdit, faCheck, faTimesCircle, faStopwatch} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import FormSelect from 'react-bootstrap/FormSelect';
//import Badge from 'react-bootstrap/Badge';

import InputMask from "react-input-mask";
import parse from 'html-react-parser';

import QuestionSourceInputSearch from "./QuestionSourceInputSearch";

import SaveQuestionAnswerOptionModal from "./SaveQuestionAnswerOptionModal";

import ConfirmationDialogModal from "../common/ConfirmationDialogModal";
import MultipleImageUploader from "../common/MultipleImageUploader";
import FormControlErrors from "../common/FormControlErrors";
import EditableText from "../common/EditableText";

import CustomLexicalEditor from "../lexical/CustomLexicalEditor";
import CustomDraftJSEditor from "../draft/CustomDraftJSEditor";

import QuestionConfig from "../../config/QuestionConfig";
import API from "../../services/backend-api";
import { useAuth } from "../../services/use-auth";
import { useConfig } from "../../services/use-config";
import BNCCModel from '../../config/BNCCModel';
import SearchSubjectTopicSkillModal from '../subject/SearchSubjectTopicSkillModal';
import { isArrayWithLength } from '../../helpers/commons';
import SaveQuestionResolutionStepModal from './SaveQuestionResolutionStepModal';

import '../../styles/Questions.css';
import FormControlHelper from '../common/FormControlHelper';

const GeneralSection = props => {

	//const [subjects, setSubjects] = useState([]);
	//const [subjectTopics, setSubjectTopics] = useState([]);
	//const [questionSources, setQuestionSources] = useState([]);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Subjects
		/*API.findSubjects({})
			.then(response => {
				if (isMounted) setSubjects(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Subjects: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		//Get Subject Topics
				if (props.values.subjectId) {
					getSubjectTopics(props.values.subjectId)
				}
			});*/
		
		 return () => { isMounted = false };
		  
	}, []);
	
        
    /*const getSubjectTopics = (subjectId) => {
    	API.findSubjectTopics(subjectId, {})
			.then(response => {
					setSubjectTopics(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Subject Topics: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }
	
	const handleSubjectChange = (e) => {
        
        props.setFieldValue("subjectId", e.target.value);
        
        if (!e.target.value) {
        	setSubjectTopics([]);
        	props.setFieldValue("topicId", "");
        	return;
        }
        
        //Get Subject Topics
        getSubjectTopics(e.target.value);
        
        //Reset topic
        props.setFieldValue("topicId", "");
    }*/
    
    const handleSourceChange = (source) => {
		
		if (!source) {
			props.setFieldValue("sourceId", "");
        	return;
        } 

		props.setFieldValue("sourceId", source.id);
    }
    
    const handleNewSource = (e) => {
		//TODO
    }
    
	return(
		<Fragment>
		<Row className="mb-3">
			<FormGroup as={Col} controlId="formGridField">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.field.label"}>Field</Trans> *</FormLabel>
				<FormSelect name="field" isInvalid={!(props.errors.field == null)} value={props.values.field} onChange={props.onChange} >
		    		<option  value="">{t(props.i18nPrefix+"form.field.options.blank")}</option>
		    		{  BNCCModel.KnowledgeFields.map(item =>
		    			<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"form.field.options."+item)}</option>
		    		)}
		    	</FormSelect>
				<FormControlErrors errors={props.errors.field} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridLevel">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.level.label"}>Level</Trans> *</FormLabel>
				<FormSelect name="level" isInvalid={!(props.errors.level == null)} value={props.values.level} onChange={props.onChange} >
		    		<option  value="">{t(props.i18nPrefix+"form.level.options.blank")}</option>
		    		{ QuestionConfig.Levels.map(item =>
		    			<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"form.level.options."+item)}</option>
		    		)}
		    	</FormSelect>
				<FormControlErrors errors={props.errors.level} />
			</FormGroup>

		</Row>

		<Row className="mb-3">
			
			<FormGroup as={Col} controlId="formGridSource">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sourceId.label"}>Source</Trans> *</FormLabel>
		    	<QuestionSourceInputSearch 
				    i18nPrefix={props.i18nPrefix+"form."}
				    onError={props.onError}
				    isInvalid={!(props.errors.sourceId == null)}
					onChange={handleSourceChange}
					sourceId={props.values.sourceId}
					//onNewSource={handleNewSource}
				 />
				<FormControlErrors block errors={props.errors.sourceId} />
			</FormGroup>
			
			<FormGroup as={Col} md={4} controlId="formGridYear">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.year.label"}>Year</Trans> *</FormLabel>
				<FormControl as={InputMask} mask='9999' type={'text'} name="year" isInvalid={!(props.errors.year == null)} value={props.values.year} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.year.placeholder")} />
				<FormControlErrors errors={props.errors.year} />
			</FormGroup>
		</Row>
		</Fragment>
	) 
}


const QuestionSection = props => {

	//const [editorState, setEditorState] = useState(EditorState.createEmpty());
	//const [searchSubjectTopicSkillModalShow, setSearchSubjectTopicSkillModalShow] = useState(false);
	
	const { t } = useTranslation();
	
	const location = useLocation();
	
	useEffect(() => {
		let isMounted = true; 
		
		/*let blocksFromHtml = htmlToDraft(props.values.questionStem);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		setEditorState(EditorState.createWithContent(contentState));*/
		
		return () => { isMounted = false };
		  
	}, []);
	
	const handleQuestionStemChange = (content) => {
        
        props.setFieldValue("questionStem", content);  
    }
    
    /*const removeSubjectTopicSkill = (item, index) => {
		
		let subjectTopicSkills = props.values.skills;
		
		//Remove Skill
		subjectTopicSkills.splice(index, 1);
		
		props.setFieldValue("skills", subjectTopicSkills);
		props.setFieldError("skills", null);
    }
    
    const handleSubjectTopicSkillSelected = (values) => {
	  
	  setSearchSubjectTopicSkillModalShow(false);
	  
	  let subjectTopicSkills = props.values.skills;
	  
	  //Add Skill
	  subjectTopicSkills.push(Object.assign({subject: values.subject.name, topic: values.topic.name}, values.skill));
	  	  
	  props.setFieldValue("skills", subjectTopicSkills);
	  props.setFieldError("skills", null);
	  
 	}*/
	
	return(
		<Fragment>
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridQuestionStem">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.questionStem.label"}>Question Stem</Trans>*</FormLabel>
					{/*<CustomDraftJSEditor
  						onChange={handleQuestionStemChange}
  						content={props.values.questionStem}
  						isInvalid={!(props.errors.questionStem == null)}
					/>
					<br/>*/}
					<CustomLexicalEditor
  						isInvalid={!(props.errors.questionStem == null)}
  						onChange={handleQuestionStemChange}
  						content={props.values.questionStem}
  						placeholder={t(props.i18nPrefix+"form.questionStem.placeholder")}
  						context={props.values}
					/>
					<FormControlErrors block errors={props.errors.questionStem} />
				</FormGroup>
			</Row>
			
			<Row className="mb-3">
				<FormGroup as={Col} md={4} controlId="formGridEstimatedTime">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.estimatedTime.label"}>Estimated Time</Trans>* <FormControlHelper text={props.i18nPrefix+"form.estimatedTime.helper"}/></FormLabel>
					<InputGroup>
						<FormControl type={'text'} name="estimatedTime" isInvalid={!(props.errors.estimatedTime == null)} value={props.values.estimatedTime} onChange={props.onChange} />
						<InputGroup.Text><Trans i18nKey={"questions.save.form.estimatedTime.append-text"} /></InputGroup.Text>
						<FormControlErrors errors={props.errors.estimatedTime} />
  					</InputGroup>
				</FormGroup>
				
			</Row>
			
			{/*
			{(searchSubjectTopicSkillModalShow) && <SearchSubjectTopicSkillModal
					show={searchSubjectTopicSkillModalShow}
	        		onHide={() => setSearchSubjectTopicSkillModalShow(false)}
					size="lg"
					onSelected={handleSubjectTopicSkillSelected}
		      	/>
			}
			<Row>
				<FormGroup as={Col} controlId="formGridSkills" className="mb-1">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.skills.label"}>Skills</Trans> *</FormLabel>
					<fieldset>
						<div className={(props.errors.skills == null) ? "border rounded p-2" : "border border-danger rounded p-2"}>
							<Row className="align-items-center">
							 	<Col>
        							{props.values.skills.map((skill, index) => (
									<Badge className="m-1" key={index} bg="light">
										<Button variant="link">
				      						 {skill.subject} &gt; {skill.topic} &gt; {skill.name}
				      					</Button>
										<Button variant="link" size="sm" style={{color: "red"}} onClick={() => { removeSubjectTopicSkill(skill, index);} }>
				      						<FontAwesomeIcon icon={faTimesCircle} />
				      					</Button>
									</Badge>
									))}
        						</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button variant="primary" size="sm" onClick={() => {setSearchSubjectTopicSkillModalShow(true);}}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.skills.add-button"}>Add Skill</Trans></Button>
							 	</Col>
							</Row>
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.skills} />
				</FormGroup>
		   </Row>*/}
		</Fragment>
	) 
}

const QuestionAnswerOptionList = props => {
	
	const { t } = useTranslation();
	
	const handleDoubleClick = (event, item, index) => {
		if (props.defaultAction && (event.detail === 2)) 
			props.defaultAction(item, index) 
    };
    
    const allowMultipleResponse= (props.questionType === "MULTIPLE_RESPONSE");
    
	return(
		<Fragment>
			{
			props.items.map((item, index) => {
				return (
					<Row key={index} className="question-answer-option mb-2">
						<Col md={"auto"}>
							<Button 
								//size="sm"
								variant={JSON.parse(item.isCorrectAnswer) ? "success": "secondary-outline border"} 
								className={(allowMultipleResponse ? "rounded": "rounded-circle") + " question-answer-option-selector-button"}
								onClick={() => { props.updateCorrectAnswer(item, index, allowMultipleResponse);}}
							>
									{parse("&#" +(65+index))}
							</Button>
						</Col>
						{(props.questionType === "TRUE_OR_FALSE") ?
							<Col md={"auto"} >{t(props.i18nPrefix+"form.answerOptions.options."+item.answer)}</Col>
						:
							<>
								<Col className="align-self-center question-answer-option-text" onClick={(e) => { handleDoubleClick(e, item, index);}}>{parse(item.answer)}</Col>
			   					<Col md={"auto"} className="align-self-center">
			   						<InputGroup size="sm">
          								<Button variant="warning" onClick={() => { props.updateAnswerOption(item, index);}}><FontAwesomeIcon icon={faEdit} /></Button>
			   							<Button variant="danger" onClick={() => { props.removeAnswerOption(item, index);}}><FontAwesomeIcon icon={faClose} /></Button>
			   						</InputGroup>
          	   					</Col>
          	   				</>
          	   			}
					</Row>
			)	
		  })}
		</Fragment>
	) 
	
}
	

const AnswerSection = props => {
	
	const [saveAnswerOptionModalShow, setSaveAnswerOptionModalShow] = useState(false);
	const [deleteAnswerOptionModalShow, setDeleteAnswerOptionModalShow] = useState(false);
	const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const newAnswerOption = () => {
	  setSelectedAnswerOption(null);
	  setSaveAnswerOptionModalShow(true);
    }

 	const updateAnswerOption = (item, index) => {
	  setSelectedAnswerOption(Object.assign({"index": index}, item));
	  setSaveAnswerOptionModalShow(true);
 	}
 	
 	const confirmAnswerOptionRemoval = (item, index) => {
	  setSelectedAnswerOption(Object.assign({"index": index}, item));
	  setDeleteAnswerOptionModalShow(true);
    }
    
    const deleteAnswerOption = (item) => {
		
		setDeleteAnswerOptionModalShow(false);
		
		//Shallow copy of Array, only to "dirty"" the form
		let answerOptionsCopy = [...props.values.answerOptions];
		
		answerOptionsCopy.splice(item.index, 1);
		
		props.setFieldValue("answerOptions", answerOptionsCopy);
		props.setFieldError("answerOptions", null);
		
  }
  
  const handleAnswerOptionSaved = (action, item, index) => {
	  setSaveAnswerOptionModalShow(false);
	  
	  //Shallow copy of Array, only to "dirty"" the form
	  let answerOptionsCopy = [...props.values.answerOptions];
	  
	  if (action === "create") {
		  answerOptionsCopy.push(item);
	  } else {  
		  answerOptionsCopy.splice(index, 1, item);
	  }
		  
	  props.setFieldValue("answerOptions", answerOptionsCopy);
	  props.setFieldError("answerOptions", null);
	  
  }
  
  const updateCorrectAnswer = (item, index, allowMultipleResponse) => {
	  
	  let answerOptions = props.values.answerOptions
	  			.map((answer, i) => {
					if (index === i) {
						return Object.assign(answer, {"isCorrectAnswer" : !JSON.parse(answer.isCorrectAnswer)}) 
					} else {
						return (!allowMultipleResponse) ? Object.assign(answer,{"isCorrectAnswer" : false}) : answer;
					}
				});
	  
	  props.setFieldValue("answerOptions", answerOptions);
	  props.setFieldError("answerOptions", null);
  }
  
  const handleQuestionTypeChange = (e) => {
        
        let previousQuestionType = props.values.type;
        
        props.setFieldValue("type", e.target.value);
        
        if (!e.target.value) {
        	//Reset Answers
        	props.setFieldValue("answerOptions", []);
        	props.setFieldValue("shortAnswer", "");
        	
        	props.setFieldError("answerOptions", null);
        	props.setFieldError("shortAnswer", null);
        	return;
        }
        
        switch(e.target.value) {
  			case "MULTIPLE_CHOICE":
    			if (previousQuestionType === "MULTIPLE_RESPONSE") {
					
					let correctAnswers = props.values.answerOptions.filter(item => (JSON.parse(item.isCorrectAnswer)));
					
					if (isArrayWithLength(correctAnswers) && correctAnswers.length > 1) {
						//if more than one correct answer, we shall reset them all to distract answers
						let answerOptions = props.values.answerOptions.map(item => Object.assign({},item, {isCorrectAnswer: false}));
						props.setFieldValue("answerOptions", answerOptions);
					}
					
				} else {
					props.setFieldValue("answerOptions", []);
				}

    			break;
    		case "MULTIPLE_RESPONSE":
    			if (previousQuestionType !== "MULTIPLE_CHOICE") {
					props.setFieldValue("answerOptions", []);
				}
    			break;
  			case "TRUE_OR_FALSE":
    			let answerOptions = [{answer: "TRUE", isCorrectAnswer: false}, {answer: "FALSE", isCorrectAnswer: false}];
				props.setFieldValue("answerOptions", answerOptions);
    			break;
  			case "SHORT_ANSWER":
  			default:
				props.setFieldValue("answerOptions", []);
			}
			
			
			props.setFieldValue("shortAnswer", "");
			
			props.setFieldError("answerOptions", null);
        	props.setFieldError("shortAnswer", null);

    }
  
  
	
	
	return(
		<Fragment>
			{(saveAnswerOptionModalShow) && <SaveQuestionAnswerOptionModal
				show={saveAnswerOptionModalShow}
	        	onHide={() => setSaveAnswerOptionModalShow(false)}
				size="xl"
				item={selectedAnswerOption}
				choices={props.values.choices}
				onAnswerOptionSaved={handleAnswerOptionSaved}
				context={props.values}
		      />
			}
			
			{(selectedAnswerOption) && <ConfirmationDialogModal
				item={selectedAnswerOption}
				show={deleteAnswerOptionModalShow}
        		onHide={() => setDeleteAnswerOptionModalShow(false)}
				size="lg"
				title={t("questions.delete-answer-option-confirmation-modal.title")}
				bodyText={t("questions.delete-answer-option-confirmation-modal.body", {item: selectedAnswerOption})}
				confirmText={t("questions.delete-answer-option-confirmation-modal.confirm")}
				cancelText={t("questions.delete-answer-option-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteAnswerOption}
			/>}
			
			<Row className="mb-3">
				<FormGroup as={Col} md={4} controlId="formGridType">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.type.label"}>Type</Trans> *</FormLabel>
					<FormSelect name="type" isInvalid={!(props.errors.type == null)} value={props.values.type} onChange={handleQuestionTypeChange} >
		    			<option  value="">{t(props.i18nPrefix+"form.type.options.blank")}</option>
		    			{ QuestionConfig.Types.map(item =>
		    				<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"form.type.options."+item)}</option>
		    			)}
		    		</FormSelect>
					<FormControlErrors errors={props.errors.type} />
				</FormGroup>
			</Row>
			
			{(props.values.type === "MULTIPLE_CHOICE" || props.values.type === "MULTIPLE_RESPONSE" || props.values.type === "TRUE_OR_FALSE") ?
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridAnswerOptions" className="mb-1">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.answerOptions.label"}>Answer Options</Trans> *</FormLabel>
					<fieldset>
						<div className={(props.errors.answerOptions == null) ? "border rounded p-3" : "border border-danger rounded p-3"}>
							<Row className="align-items-center">
							 	<Col>
        							<QuestionAnswerOptionList 
        								i18nPrefix={props.i18nPrefix}
        								questionType={props.values.type}
        								items={props.values.answerOptions} 
        								updateAnswerOption={updateAnswerOption}
        								updateCorrectAnswer={updateCorrectAnswer}
        								removeAnswerOption={confirmAnswerOptionRemoval}
        								defaultAction={updateAnswerOption}
        							/>
        						</Col>
							</Row>
							{(props.values.type !== "TRUE_OR_FALSE") ?
								<Row>
									<Col className="text-center">
										<Button variant="primary" size="sm" onClick={newAnswerOption}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.answerOptions.add-button"}>Add Answer Option</Trans></Button>
							 		</Col>
								</Row>
								: null
							}
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.answerOptions} />
				</FormGroup>
		   </Row>
		   :
		   (props.values.type === "SHORT_ANSWER") ?
		    <Row className="mb-3">
		    	<FormGroup as={Col} md={4} controlId="formGridShortAnswer">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.shortAnswer.label"}>Short Answer</Trans> *</FormLabel>
					<FormControl type={'text'} name="shortAnswer" isInvalid={!(props.errors.shortAnswer == null)} value={props.values.shortAnswer} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.shortAnswer.placeholder")}/>
					<FormControlErrors block={true} errors={props.errors.shortAnswer} />
				</FormGroup>
		   </Row>
		    
		    : null
		   }
		</Fragment>
	) 

}

const ResolutionStepList = props => {
	
	const { t } = useTranslation();
	
	const handleDoubleClick = (event, item, index) => {
		if (props.defaultAction) 
			props.defaultAction(item, index) 
    };
    
	return(
		<Fragment>
			{props.items.map((item, index, items) => {
				return (
					<Row key={index} className="mb-3">
						<Col md={"auto"} className="text-center font-monospace fw-bold text-primary">
							<EditableText 
								value={item.stepOrder}
								inputClassName="form-control"
								size={2}
								displayText={item.stepOrder+"/"+items.length}
								onChange={(value) => {props.onStepChange(Object.assign({},item, {stepOrder: value}), index)}}
							/>
						</Col>
						<Col md={"auto"}><div className="vr" style={{width: "5px", height: "100%"}}/></Col>
						<Col onClick={(e) => { handleDoubleClick(e, item, index);}}>
							<div>{parse(item.resolution)}</div>
							<small className="text-muted"><strong><Trans i18nKey={props.i18nPrefix+"form.resolutionSteps.skill"}>Skill</Trans>:</strong> {item.skill.subject} &gt; {item.skill.topic} &gt; {item.skill.name}</small>
						</Col>
						<Col md={"auto"} className="text-secondary text-center" 
							onDoubleClick={(e) => { props.defaultAction(item, index);}}>
							<div><FontAwesomeIcon size="xl" icon={faStopwatch} /></div>
							{item.estimatedTime}<Trans i18nKey={props.i18nPrefix+"form.estimatedTime.timeUnit"}>s</Trans></Col>
						<Col md={"auto"}>
							<InputGroup size="sm">
          						<Button variant="warning" onClick={() => { props.updateItem(item, index);}}><FontAwesomeIcon icon={faEdit} /></Button>
			   					<Button variant="danger" onClick={() => { props.removeItem(item, index);}}><FontAwesomeIcon icon={faClose} /></Button>
			   				</InputGroup>
			   			</Col>
					</Row>
				)	
		  })}
		</Fragment>
	) 
	
}

const ResolutionSection = props => {
	const [saveResolutionStepModalShow, setSaveQuestionResolutionStepModalShow] = useState(false);
	const [deleteResolutionStepModalShow, setDeleteResolutionStepModalShow] = useState(false);
	const [selectedResolutionStep, setSelectedResolutionStep] = useState(null);
	
	const { t } = useTranslation();
	
	const sortByStepOrder = (a,b) => {		
  		return parseInt(a.stepOrder)-parseInt(b.stepOrder);
 	}
	
	useEffect(() => {
		let isMounted = true; 
		
		//Sort Topic Skills
		props.setFieldValue("resolutionSteps", props.values.resolutionSteps.sort(sortByStepOrder));
		
		return () => { isMounted = false };
		  
	}, []);
	
	/*const handleResolutionChange = (content) => {
        props.setFieldValue("resolution", content);  
    }*/
    
    const handleResolutionStepSaved = (action, values, index) => {
	  
	  setSaveQuestionResolutionStepModalShow(false);
	  
	  //Shallow copy of Array, only to "dirty"" the form
	  let questionResolutionStepsCopy = [...props.values.resolutionSteps];
	  
	  if (action == "create") {
		questionResolutionStepsCopy.push(Object.assign({}, values, {"stepOrder" : (questionResolutionStepsCopy.length + 1)}));
	  } else {
		questionResolutionStepsCopy.splice(index, 1, values);
	  }
	  	  
	  props.setFieldValue("resolutionSteps", questionResolutionStepsCopy.sort(sortByStepOrder));
	  props.setFieldError("resolutionSteps", null);
	  
 	}
 	
 	const newResolutionStep = () => {
	  setSelectedResolutionStep(null);
	  setSaveQuestionResolutionStepModalShow(true);
    }
 	
 	const updateResolutionStep = (item, rowIndex) => {
	  setSelectedResolutionStep(Object.assign({"index": rowIndex}, item));
	  setSaveQuestionResolutionStepModalShow(true);
 	}
 	
 	const confirmResolutionStepRemoval = (item, rowIndex) => {
	  setSelectedResolutionStep(Object.assign({"index": rowIndex}, item));
	  setDeleteResolutionStepModalShow(true);
    }
    
    const deleteResolutionStep = (item) => {
		
		setDeleteResolutionStepModalShow(false);
		
		//Shallow copy of Array, only to "dirty"" the form
		let questionResolutionStepsCopy = [...props.values.resolutionSteps];
		
		//Remove Resolution Step
		questionResolutionStepsCopy.splice(item.index, 1);
		
		//Decrease stepOrder by one for all subsequent  Resolution Steps
		questionResolutionStepsCopy.forEach((step, index) => {
			if (index >= item.index)
				step.stepOrder = parseInt(step.stepOrder)-1;
		});
		
		props.setFieldValue("resolutionSteps", questionResolutionStepsCopy.sort(sortByStepOrder));
		props.setFieldError("resolutionSteps", null);
  }

	
	return(
		<Fragment>
			
			{(saveResolutionStepModalShow) && <SaveQuestionResolutionStepModal
					show={saveResolutionStepModalShow}
	        		onHide={() => setSaveQuestionResolutionStepModalShow(false)}
					size="xl"
					item={selectedResolutionStep}
					onItemSaved={handleResolutionStepSaved}
					context={props.values}
		      	/>
			}
			{(selectedResolutionStep) && <ConfirmationDialogModal
				item={selectedResolutionStep}
				show={deleteResolutionStepModalShow}
        		onHide={() => setDeleteResolutionStepModalShow(false)}
				size="lg"
				title={t("questions.delete-resolution-step-confirmation-modal.title")}
				bodyText={t("questions.delete-resolution-step-confirmation-modal.body", {item: selectedResolutionStep})}
				confirmText={t("questions.delete-resolution-step-confirmation-modal.confirm")}
				cancelText={t("questions.delete-resolution-step-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteResolutionStep}
			/>}
			
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridResolutionSteps" className="mb-1">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.resolutionSteps.label"}>Resolution Steps</Trans> *</FormLabel>
					<fieldset>
						<div className={(props.errors.resolutionSteps == null) ? "border rounded p-3" : "border border-danger rounded p-3"}>
							<Row className="mb-4">
							 	<Col>
							 	  <ResolutionStepList
							 	  		i18nPrefix={props.i18nPrefix}
							 	  		items={props.values.resolutionSteps}
							 	  		updateItem={updateResolutionStep}
        								removeItem={confirmResolutionStepRemoval}
        								onStepChange={(values, index) => { handleResolutionStepSaved("update", values, index);}}
        								defaultAction={updateResolutionStep}
							 	  />
        						</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button variant="primary" size="sm" onClick={newResolutionStep}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.resolutionSteps.add-button"}>Add Resolution Step</Trans></Button>
							 	</Col>
							</Row>
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.resolutionSteps} />
				</FormGroup>
		   </Row>
		</Fragment>
	) 
}

const ImagesSection = props => {

	//const { t } = useTranslation();
	
	const config = useConfig();
	
	const getQuestionImageUrl = (id, attachment) => {
        return API.getQuestionImageUrl(id, attachment);
    }
    
    const getQuestionPublicImageUrl = (id) => {
        return API.getQuestionPublicImageUrl(id);
    }
	
	return(
		<Fragment>
			<MultipleImageUploader 
				i18nPrefix={props.i18nPrefix+"form."}
				//maxImages={0}
				maxFileSize={config.uploadFileSizeLimit}
				images={props.values.images}
				setFieldError={props.setFieldError}
				validateField={props.validateField}
				isInvalid={!(props.errors.images == null)}
				onChange={(images) => { props.setFieldValue("images", images);}}
				getImageUrl={getQuestionImageUrl}
				getPublicImageUrl={getQuestionPublicImageUrl}
			/>
			{!(props.errors.images == null) 
					? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={props.errors.images} /></div>
				: null
			}	
		</Fragment>
	) 

}




const QuestionFormFields = { GeneralSection, QuestionSection, AnswerSection, ResolutionSection, ImagesSection };
		
export default QuestionFormFields;
