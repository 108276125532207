import React, { useState, useEffect, useRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faTimesCircle, faSearch} from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
//import FormSelect from 'react-bootstrap/FormSelect';
//import Badge from 'react-bootstrap/Badge';
//import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../common/FormControlErrors";

import QuestionSourceInputSearch from "./QuestionSourceInputSearch";

import SearchSubjectTopicSkillModal from "../subject/SearchSubjectTopicSkillModal";

import * as Yup from "yup";

import QuestionConfig from "../../config/QuestionConfig";

//import API from "../../services/backend-api";
import BNCCModel from '../../config/BNCCModel';
import { isArrayWithLength } from '../../helpers/commons';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../../styles/Typeahead.css";


const displayFiltersButton = {
    position: "absolute", 
	right: "0.75rem"
}

const skillToken = {
	backgroundColor: "#e7f4ff",
	color: "#007bff",
	cursor: "pointer"
};

const validationSchema = Yup.object().shape({
	...QuestionConfig.FilterFormValidationRules,
	...QuestionConfig.AdvancedFilterFormFields
});

const QuestionFilterFormFields = props => {
	
	const [displayMoreFilters, setDisplayMoreFilters] = useState(false);
	const fieldsTypeaheadRef = useRef(null);
	const levelsTypeaheadRef = useRef(null);
	const typesTypeaheadRef = useRef(null);
	
	const [searchSubjectTopicSkillModalShow, setSearchSubjectTopicSkillModalShow] = useState(false);
		
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		
		return () => { isMounted = false };
		  
	}, []);
	

	const toogleDisplayFilters = () => {
		
		let currentDisplayMode = displayMoreFilters;
		
		if (displayMoreFilters) {
			
			let defaultValues = validationSchema.default();
			
			Object.keys(QuestionConfig.AdvancedFilterFormFields)
			.forEach((key) => {
				props.setFieldValue(key, defaultValues[key]);
			});
			
			props.submitForm();
		}
		
		setDisplayMoreFilters(!currentDisplayMode);
		
    } 

	const handleSourcesChange = (sources) => {
		
		if (!sources) {
			props.setFieldValue("sources", "");
        	return;
        } 

		props.setFieldValue("sources", sources);
    }
    
    const buildEnumOptionItem = (field, value) => {
		return Object.assign({"key" : value, "displayText" : t(props.i18nPrefix+field+".options."+value)});
	}
	
	const handleSubjectTopicSkillSelected = (values) => {
	  
	  setSearchSubjectTopicSkillModalShow(false);
	  
	  let subjectTopicSkills = props.values.skills;
	  
	  //Add Skill
	  subjectTopicSkills.push(Object.assign({subject: values.subject.name, topic: values.topic.name}, values.skill));
	  	  
	  props.setFieldValue("skills", subjectTopicSkills);
	  props.setFieldError("skills", null);
	  
 	}
 	
 	const removeSubjectTopicSkill = (item, index) => {
		
		let subjectTopicSkills = props.values.skills;
		
		//Remove Skill
		subjectTopicSkills.splice(index, 1);
		
		props.setFieldValue("skills", subjectTopicSkills);
		props.setFieldError("skills", null);
  }


	return (
		<fieldset className="position-relative">
			<div className="position-absolute top-0 end-0">  					   
				<Button variant="link" onClick={toogleDisplayFilters} className="float-end"><FontAwesomeIcon icon={faFilter} /> {(displayMoreFilters)
					? <Trans i18nKey={props.i18nPrefix+"display-less-filters"}>Display - Filters</Trans>
					: <Trans i18nKey={props.i18nPrefix+"display-more-filters"}>Display + Filters</Trans>
					}	
				</Button>
			</div>
			<div className={"border rounded p-4"}>
				
				<FormGroup as={Row} controlId="formGridFields" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"fields.label"}>Fields</Trans>
    				</FormLabel>
					<Col sm="8">
						<Typeahead
      						multiple
      						id="fields"
							isInvalid={!(props.errors.fields == null)}
							labelKey={"displayText"}
      						onChange={(selected) => {
        						props.setFieldValue("fields", selected.map(item => item.key));
        						// Keep the menu open when making multiple selections.
        						fieldsTypeaheadRef.current.toggleMenu();
      						}}
      						options={BNCCModel.KnowledgeFields.map(item => buildEnumOptionItem("fields", item))}
      						placeholder={t(props.i18nPrefix+"fields.placeholder")}
							emptyLabel={t(props.i18nPrefix+"fields.empty-label")}
      						ref={fieldsTypeaheadRef}
      						selected={props.values.fields.map(item => buildEnumOptionItem("fields", item))}
    					/>
						<FormControlErrors block errors={props.errors.fields} />
					</Col>
					
				</FormGroup>
				
				{(searchSubjectTopicSkillModalShow) && <SearchSubjectTopicSkillModal
					show={searchSubjectTopicSkillModalShow}
	        		onHide={() => setSearchSubjectTopicSkillModalShow(false)}
					size="lg"
					onSelected={handleSubjectTopicSkillSelected}
		      	/>
				}
				
				{ displayMoreFilters &&
				 	<FormGroup as={Row} controlId="formGridSkills" className="mb-3">
						<FormLabel column sm="2">
							<Trans i18nKey={props.i18nPrefix+"skills.label"}>Skills</Trans>
						</FormLabel>
						<Col sm="8">
							<div className="form-control" style={{backgroundColor: "#ffffff"}} onClick={() => {setSearchSubjectTopicSkillModalShow(true);}}>
								{ !isArrayWithLength(props.values.skills) ?
									<span className="text-uppercase text-muted"><Trans i18nKey={props.i18nPrefix+"skills.placeholder"} /></span>
								 : 
									props.values.skills.map((skill, index) => (
									 <div key={index} className="pt-1 pb-1">
										<span className="ps-2 pe-2 pt-1 pb-1 rounded" style={skillToken} 
											onClick={(e) => { e.stopPropagation();}}>
				      						{skill.subject} &gt; {skill.topic} &gt; {skill.name}
				      						<span className="remove-skill-button ms-1" 
				      							onClick={() => { removeSubjectTopicSkill(skill, index);} }>&nbsp;&times;&nbsp;</span>
				      						{/*<FontAwesomeIcon className="text-danger ms-2" icon={faTimesCircle} 
				      							onClick={() => { removeSubjectTopicSkill(skill, index);} }/>*/}
				      					</span>
									</div>
								 ))}
							</div>
							<FormControlErrors block errors={props.errors.skills} />
						</Col>
					</FormGroup>
				}
				
				{ displayMoreFilters &&
				 	<FormGroup as={Row} controlId="formGridSources" className="mb-3">
						<FormLabel column sm="2">
							<Trans i18nKey={props.i18nPrefix+"sources.label"}>Source</Trans>
						</FormLabel>
						<Col sm="8">
							<QuestionSourceInputSearch 
								multiple={true}
				    			i18nPrefix={props.i18nPrefix}
				    			onError={props.onError}
				    			isInvalid={!(props.errors.sources == null)}
				    			onChange={handleSourcesChange}
								value={props.values.sources}
				    		/>
							<FormControlErrors block errors={props.errors.sources} />
						</Col>
					</FormGroup>
				}
			
						
				{ displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridLevels" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"levels.label"}>Level</Trans>
    				</FormLabel>
					<Col sm="8">
						<Typeahead
      						multiple
      						id="levels"
							isInvalid={!(props.errors.levels == null)}
							labelKey={"displayText"}
      						onChange={(selected) => {
        						props.setFieldValue("levels", selected.map(item => item.key));
        						// Keep the menu open when making multiple selections.
        						levelsTypeaheadRef.current.toggleMenu();
      						}}
      						options={QuestionConfig.Levels.map(item => buildEnumOptionItem("levels", item))}
      						placeholder={t(props.i18nPrefix+"levels.placeholder")}
							emptyLabel={t(props.i18nPrefix+"levels.empty-label")}
      						ref={levelsTypeaheadRef}
      						selected={props.values.levels.map(item => buildEnumOptionItem("levels", item))}
    					/>
						<FormControlErrors block errors={props.errors.levels} />
   					</Col>
				</FormGroup>
				}
				
				{ displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridTypes" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"types.label"}>Type</Trans>
    				</FormLabel>
					<Col sm="8">
						<Typeahead
      						multiple
      						id="types"
							isInvalid={!(props.errors.types == null)}
							labelKey={"displayText"}
      						onChange={(selected) => {
        						props.setFieldValue("types", selected.map(item => item.key));
        						// Keep the menu open when making multiple selections.
        						typesTypeaheadRef.current.toggleMenu();
      						}}
      						options={QuestionConfig.Types.map(item => buildEnumOptionItem("types", item))}
      						placeholder={t(props.i18nPrefix+"types.placeholder")}
							emptyLabel={t(props.i18nPrefix+"types.empty-label")}
      						ref={typesTypeaheadRef}
      						selected={props.values.types.map(item => buildEnumOptionItem("types", item))}
    					/>
						<FormControlErrors block errors={props.errors.types} />
   					</Col>
				</FormGroup>
				}
						
						
				<FormGroup as={Row} controlId="formGridFilter" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"filter.label"}>Question Stem</Trans>
    				</FormLabel>
					<Col sm="8">
						<FormControl type={'search'} name="filter" className="text-uppercase" isInvalid={!(props.errors.filter == null)} value={props.values.filter} onChange={props.onChange} placeholder={t(props.i18nPrefix+"filter.placeholder")} />
						<FormControlErrors errors={props.errors.filter} />
					</Col>
					<Col sm="2">
						<Button variant="success" type="submit" disabled={props.isSubmitting} onClick={() => {}}> 
							{props.isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</Col>
				</FormGroup>
				
			</div>
		</fieldset>
	)

}

export default QuestionFilterFormFields;
