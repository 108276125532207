import React, { useState } from 'react';
import { Trans} from 'react-i18next';
import log from 'loglevel';

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import ActivityDetailModal from "./ActivityDetailModal";

import DataTable from "../common/DataTable";

import API from "../../services/backend-api";
import { useAuth } from "../../services/use-auth";


const SubjectActivities = props => {
	const [isLoading, setIsLoading] = useState(false);
	const [activities, setActivities] = useState(null);
	const [activityDetailModalShow, setActivityDetailModalShow] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);
	
	const auth = useAuth();
		
	//Check if user is ROOT
	if (!auth.isRoot()) 
		return null;
	
	
  const detailActivity = (activity) => {
	  setSelectedActivity(activity);
	  setActivityDetailModalShow(true);
  }
  
  const handleActivityDetailModalHide = () => {
	  setSelectedActivity(null); 
	  setActivityDetailModalShow(false);
  }
  
  const mergeQueryParams = (currentValues, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentValues) {
		  queryParams.sortBy = currentValues.sortBy;
		  queryParams.order = currentValues.order;
		  queryParams.filter = currentValues.filter;
		  queryParams.filteredColumn = currentValues.filteredColumn;
		  
		  queryParams.subject = currentValues.subject;
		  
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
    
  const findActivities = (values) => {

	setIsLoading(true);

	API.findActivities(mergeQueryParams(activities, values))
		.then(response => {
			setActivities(response);
		}).catch(error => {			
			log.error("Error Loading Subject Activities List: ", error.message);
		 	props.onError(error);
		}).finally(() => {
		   setIsLoading(false);
		});
	
	};
	
	return (
			<Accordion defaultActiveKey="0">
  				<Card>
    				<Card.Header className="p-0 m-0">
						<Row className="p-0 m-0">
							<Col className="p-0 m-0">
								<div className="d-grid gap-2">
								<Button 
									variant="light" 
									size="lg"
									disabled={isLoading}
									onClick={() => findActivities({subject: props.subject, filteredColumn: "subjectId", filter: props.subjectId})} >
										{isLoading ? <Trans i18nKey="components.subject-activity.isLoading">Please wait...</Trans> : <span><Trans i18nKey="components.subject-activity.load-button">Load Log Activities</Trans></span>}
									</Button>
									</div>
							</Col>
						</Row>
    				</Card.Header>
						{(selectedActivity) && <ActivityDetailModal
							show={activityDetailModalShow}
							onHide={handleActivityDetailModalHide}
							size="lg"
							activity={selectedActivity}
							onError={props.onError}
						/>}
						{(activities) && (
			  			<Accordion.Collapse eventKey="0">
						<Card.Body>
							<Row>
        						<Col>
									<DataTable 
        								items={activities.list} 
        								i18nPrefix={"components.subject-activity.datatable."} 
        								columns={["eventTime", "user", "action"]} 
        								sortBy={activities.sortBy}
        								order={activities.order}
        								actions={[detailActivity]}	
        								defaultAction={detailActivity}
        								sortDataTable={findActivities}
        								sortableColumns={["eventTime"]}
        								customDisplayColumns={[["eventTime", "ZonedDateTime"]]}
										noItemsDisclaimer={true}
        							/>
        						</Col>
        	  				</Row>
						</Card.Body>
						</Accordion.Collapse>
		    			)}
    				
  				</Card>
			</Accordion>
	);
}

export default SubjectActivities;
