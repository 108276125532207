import { lightenDarkenColor } from "react-papaparse"

const GREY = "#CCC"
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)"
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919"
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
)
const GREY_DIM = "#686868"

const DropZone = {
	flex: 1,
  	display: "flex",
  	flexDirection: "column",
  	alignItems: "center",
  	padding: "10px",
  	borderWidth: "2px",
  	borderRadius: "2px",
	borderColor: '#98A641',
  	borderStyle: "dashed",
  	backgroundColor: "#fafafa",
  	color: "#bdbdbd",
  	outline: "none",
  	transition: "border .24s ease-in-out"
};

const DropZoneHover = {
    borderColor: '#98A641',
};

const DropFile = {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 100,
    width: "100%",
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center"
  }

const DropFileInfo = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10
  }

const FileSizeInfo = {
      color: '#fff',
      backgroundColor: '#000',
      borderRadius: 3,
      lineHeight: 1,
      marginBottom: '0.5em',
      padding: '0 0.4em',
};


const FileNameInfo = {
      color: '#fff',
      backgroundColor: '#000',
      borderRadius: 3,
      fontSize: "1.1em",
      lineHeight: 1,
      padding: '0 0.4em',
};


const RemoveButton = {
	height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23
};

const ProgressBar = {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 30,
    paddingRight: 30
};

const ProgressBarBackgroundColor = {
	backgroundColor: 'green'
};


const DropZoneStyles = { DropZone, DropZoneHover, DropFile, DropFileInfo, FileSizeInfo, FileNameInfo, RemoveButton, ProgressBar, ProgressBarBackgroundColor, 
	DEFAULT_REMOVE_HOVER_COLOR, REMOVE_HOVER_COLOR_LIGHT
};


export default DropZoneStyles;