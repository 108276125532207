import React,  { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle, faChevronRight, faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import SubjectFormFields from "./SubjectFormFields";

import Prompt from "../common/Prompt";
import Loader from "../common/Loader";

import SubjectActivities from "../activity/SubjectActivities";

import API from "../../services/backend-api";
import { useConfig } from "../../services/use-config";
import { useAuth } from "../../services/use-auth";
import SubjectConfig from "../../config/SubjectConfig";
import { ValidationError } from "../../helpers/custom-errors";
import { compareJSON } from "../../helpers/commons";
import CustomContainer from '../common/CustomContainer';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
});


const validationSchema = Yup.object().shape(SubjectConfig.FormValidationRules);


const SaveSubject = props => {

	const [subject, setSubject] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	
	let navigate = useNavigate();
	
	const auth = useAuth();
	
	const config = useConfig();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	
	useEffect(() => {
		let isMounted = true; 
			
		if (location.pathname === '/subjects/update' && location.state && location.state.item) {
			API.lookupSubject(location.state.item.id)
			.then(response => {
					setSubject(response);
			})
			.catch(error => { 
				log.error("Error Loading Subject: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) {
		 			setIsLoading(false);
		 		}
			});
		} else {			
			if (isMounted) {
	 			setIsLoading(false);
	 		}
		}
		 
		 return () => { isMounted = false };
		  
	}, []);
	
	
	if (location.pathname === '/subjects/update') {
		if (!location.state.item) {
			return (<Navigate  to="/subjects" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!subject) {
			return null;
		}
	} else {
		if (isLoading) {
			return <Loader />
		}
	}
	
	let initialValues = {}, action, actionIcon;
	
	let defaultValues = validationSchema.default();
	
	if (location.pathname === '/subjects/update') {
		Object.keys(SubjectConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (subject[key]) ? subject[key] : defaultValues[key];
			});
		action = "update";
		actionIcon = faCheckCircle;
	} else {
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle;
	}
	
	const saveSubject = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/subjects/update') {
				API.updateSubject(location.state.item.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createSubject(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});

	return (
		<CustomContainer>
			<Row>
		   		<Col>
		   			<h3>
		            	<Trans i18nKey={"subjects.save.header-"+action}>Save Subject</Trans> 
		            </h3>
		        </Col>
		    </Row>
		    <Row className="mb-3">
    			<Col>	     
					<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
    						
    						log.debug(compareJSON(initialValues, values), actions);
    										
    						saveSubject(values)
    							.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/subjects", {state: { success: t("subjects.save.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save Subject Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving Subject: ", error.message);
    								}
    								
    								context.onError(error);
    								
    							})

						}}
		      		>
		      		{({isSubmitting, dirty, errors, values, handleChange, setFieldValue, validateForm, setFieldError, validateField, touched}) => (	

		        		<Form className="form-subject" noValidate>
		        			<Prompt
		        				when={!!dirty && !isSubmitting}
		        				message={JSON.stringify({
		        					"title": t("form.unsaved-changes-modal.title"),
		        					"bodyText": t("form.unsaved-changes-modal.body"),
		        					"confirmText": t("form.unsaved-changes-modal.confirm"), 
		        					"cancelText": t("form.unsaved-changes-modal.cancel"),
		        					"size": "lg",
		        					"variant": "danger",		
		        					"when": compareJSON(initialValues, values)
		        					})
		        				}
		        			/>
		      		
							<SubjectFormFields 
								values={values} 
								errors={errors} 
								onChange={handleChange} 
								onError={context.onError}
								setFieldValue={setFieldValue}
								action={action}
								validateField={validateField}
								setFieldError={setFieldError}
								i18nPrefix="subjects.save."
							/>
						
					   		<Row className="mt-3 mb-3">    					   
    							<Col>  
    								<Button variant="success" type="submit" disabled={isSubmitting} > 
    									{isSubmitting ? <Trans i18nKey="subjects.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"subjects.save.form.submit-"+action}>Submit</Trans></span>} 
    								</Button>
    							
    								<Button as={Link} variant="secondary" className="float-end" to={"/subjects"}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="subjects.save.cancel">Cancel</Trans></Button>
    					  		</Col>
    			       		</Row>   
    			    </Form>	
	
		      		)}
			 </Formik>
		  </Col>
		</Row>
		 
		 {(location.pathname === '/subjects/update') && (
		 	<SubjectActivities 
		 		subject="SUBJECT" 
		 		subjectId={location.state.item.id} 
		 		onError={context.onError}
		 	/>
		 )}
		 </CustomContainer>
	);
}

export default SaveSubject;
