/* eslint-disable no-undef */
import { AuthError, ValidationError, HttpError } from "../helpers/custom-errors";
import { isArrayWithLength, isAsyncSupported } from "../helpers/commons";
import log from 'loglevel';


const __BACKEND_API_PREFIX = "/app";

function appendPrefix(path) {
	return __BACKEND_API_PREFIX + path;
}

function sendHttpRequest(method, url, async, body) {
  
  let prefixedUrl = appendPrefix(url);

  //First check if fetch/async/await is supported
  if (window.fetch && async && isAsyncSupported()) {
	
	let fetchParams = {};
	fetchParams.method = method;
	fetchParams.accept = "application/json";
	fetchParams.body = body;

	return fetch(prefixedUrl, fetchParams)
   		.then(checkStatus);
  } else {

	
  //If fetch/await is not supported, we use XMLHttpRequest instead
  return new Promise(function(resolve, reject) {
    var xhr;
    var checkStatusXHR = function() {
      let error = new HttpError("GENERIC", xhr.status, `HTTP Error ${xhr.responseText}`);

	  try {

			let data;

			//if NOT an attachment, interpret response as json
        	if (xhr.getResponseHeader('Content-Disposition') && xhr.getResponseHeader('Content-Disposition').indexOf('attachment') > -1) {
				data = new Blob([xhr.response]);
        	} else {
				data = JSON.parse(xhr.responseText);
			}
		
			if (xhr.status >= 200 && xhr.status < 299) {
				resolve(data);
			} else if (xhr.status >= 400 && xhr.status < 499) { //Http Client Error
				//Check if code attribute has been properly set
				if (data.code) {
					//Check if AuthError
					if (data.code.indexOf("auth-") !== -1) {
						error = new AuthError(data.code, xhr.status, data.message);
					} else { 
						error = new ValidationError(data.code, xhr.status, data.detail, data.message);
					}
				} 
	    	} 

		} catch(err) {
			//Do nothing here. This block exists only to intercept json parse exception
			//Proper error handler should happen in block bellow
			
			log.error("backend-api.checkStatusXHR: " + err);
			
    	}

		reject(error);
	};

    xhr = new XMLHttpRequest();
    if (async) {
      xhr.onreadystatechange = function() {
        if (xhr.readyState !== 4) {
          return;
        }
        checkStatusXHR();
      };
    }
    xhr.open(method, prefixedUrl, async);
    xhr.send(body);
    if (!async) {
      checkStatusXHR();
    }
  });
}
}

async function checkStatus(response) {
	
	//Initially, we set default HttpError with generic error message
	let error = new HttpError("GENERIC", response.status, `HTTP Error ${response.statusText}`);
	
	// Then we to process/parse response body in json format
	// If it fails, a standard Error/Exception will be thrown and handled in the catch statement
	try {
		
		let data;

		//if NOT an attachment, interpret response as json
		
        if (response.headers.get('Content-Disposition') && response.headers.get('Content-Disposition').indexOf('attachment') > -1) {
			data = await response.blob();
        } else {
			data = await response.json();
		}

		//const jsonData = await response.json();
		
		if (response.ok) {
			return data;
		} else if (response.status >= 400 && response.status < 499) { //Http Client Error
			//Check if code attribute has been properly set
			if (data.code) {
				//Check if AuthError
				if (data.code.indexOf("auth-") !== -1) {
					error = new AuthError(data.code, response.status, data.message);
				} else { 
					error = new ValidationError(data.code, response.status, data.detail, data.message);
				}
			} 
	    } 

	} catch (err) {
		//Do nothing here. This block exists only to intercept json parse exception
		//Proper error handler should happen in block bellow
		
		log.error("backend-api.checkStatus: " + err);
	}
	
	//Finally Error is throw to be handled in upper level
	throw error;
}


function objectToFormData(obj, blackList) {
	var fd = new FormData();
	var formKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			formKey = property;
			fd.append(formKey, obj[property]);
		}
	}

	return fd;    
};

function objectToQueryParameters(obj, blackList) {
	var queryString = "";
	var filterKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			filterKey = property;
			if (queryString) queryString = queryString.concat("&");
			else queryString = queryString.concat("?");
			queryString = queryString.concat(filterKey, '=', encodeURIComponent(obj[property]));
		}
	}

	return queryString;    
};

function getConfig() {
   
   return fetch(appendPrefix('/config'), {
	  accept: "application/json"
   })
   .then(checkStatus);

   //Force Syncronous Request
   //return sendHttpRequest('GET', '/config', false);
}

function getBuildInfo() {
   
   return fetch(appendPrefix('/build/info'), {
	  accept: "application/json"
   })
   .then(checkStatus);
}

function getEnvironment() {
   
   return fetch(appendPrefix('/environment'), {
	  accept: "application/json"
   })
   .then(checkStatus);
}


function getUserAuthState() {
	  
	return fetch(appendPrefix('/auth'), {
		accept: "application/json"
	})
	.then(checkStatus);
	
	//return sendHttpRequest('GET', '/auth', true);
}

function updateLanguage(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/lang'), {
		method: 'post',
		body: form
	})
	.then(checkStatus);
}

function loginUser(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/login'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function logoutUser(csrfToken) {

	var form = new FormData();
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/logout'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function changeUserPassword(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	return fetch(appendPrefix('/pwd/change'), {
		method: 'PATCH',
		body: form
	})
	.then(checkStatus)
}

function sendUserPasswordResetEmail(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/pwd/recover'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function confirmUserPasswordReset(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/pwd/reset'), {
		method: 'PATCH',
		body: form
	})
   .then(checkStatus);
}

function verifyUserPasswordResetToken(data, csrfToken) {
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/pwd/token'), {
		method: 'post',
		body: form
	})
   .then(checkStatus);
}

function findUsers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/users/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageUsers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/users')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupUser(id) {

	return fetch(appendPrefix('/users/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createUser(data, csrfToken) {
	
	var form = objectToFormData(data, ["roles", "permissions", "workgroups"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.roles)) {
		data.roles.forEach((item) => {
			form.append("roles[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.permissions)) {
		data.permissions.forEach((item) => {
			form.append("permissions[]", item);
		});
	}
	
	return fetch(appendPrefix('/users'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateUser(id, data, csrfToken) {
	
	var form = objectToFormData(data, ["roles", "permissions","workgroups"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.roles)) {
		data.roles.forEach((item) => {
			form.append("roles[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.permissions)) {
		data.permissions.forEach((item) => {
			form.append("permissions[]", item);
		});
	}
	
	return fetch(appendPrefix('/users/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateUserPassword(id, data, csrfToken) {
		
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/users/'+id+'/pwd'), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateSelfPassword(data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/users/self/pwd'), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function importUsers(data, csrfToken) {
	
	var path = '/users/import';
	
	var form = objectToFormData(data, ["data"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.data)) {
		data.data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function findActivities(filter) {

	var path = '/activities/all';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageActivities(filter) {

	var path = '/activities';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


function findQuestions(filter) {

	var query = objectToQueryParameters(filter, ["fields", "levels", "types", "sources", "skills"]);
	
	if (filter) {
		
		//Check if any fields
	 	if (isArrayWithLength(filter.fields)) {
			filter.fields.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('fields[]', '=', item);
			});
	 	}
	
		//Check if any levels
		if (isArrayWithLength(filter.levels)) {
			filter.levels.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('levels[]', '=', item);
			});
	 	}
	 	
	 	//Check if any types
		if (isArrayWithLength(filter.types)) {
			filter.types.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('types[]', '=', item);
			});
	 	}
	 	
	 	//Check if any sources
		if (isArrayWithLength(filter.sources)) {
			filter.sources.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('sources[]', '=', item.id);
			});
	 	}
	 	
	 	//Check if any skills
		if (isArrayWithLength(filter.skills)) {
			filter.skills.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('skills[]', '=', item.id);
			});
	 	}
	
	}
	
	return fetch(appendPrefix('/questions/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageQuestions(filter) {

	var query = objectToQueryParameters(filter, ["fields", "levels", "types", "sources", "skills"]);
	
	if (filter) {
		
		//Check if any fields
	 	if (isArrayWithLength(filter.fields)) {
			filter.fields.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('fields[]', '=', item);
			});
	 	}
	
		//Check if any levels
		if (isArrayWithLength(filter.levels)) {
			filter.levels.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('levels[]', '=', item);
			});
	 	}
	 	
	 	//Check if any types
		if (isArrayWithLength(filter.types)) {
			filter.types.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('types[]', '=', item);
			});
	 	}
	 	
	 	//Check if any sources
		if (isArrayWithLength(filter.sources)) {
			filter.sources.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('sources[]', '=', item.id);
			});
	 	}
	 	
	 	//Check if any skills
		if (isArrayWithLength(filter.skills)) {
			filter.skills.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('skills[]', '=', item.id);
			});
	 	}
	
	}
	
	return fetch(appendPrefix('/questions')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupQuestion(id) {

	return fetch(appendPrefix('/questions/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createQuestion(data, csrfToken) {
	
	var form = objectToFormData(data, ["answerOptions", "resolutionSteps", "images"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.answerOptions)) {
		data.answerOptions.forEach((item) => {
			form.append("answerOptions[]", JSON.stringify(item));
		});
	}
	
	/*if (data && isArrayWithLength(data.skills)) {
		data.skills.forEach((item) => {
			form.append("skills[]", JSON.stringify({id: item.id}));
		});
	}*/
	
	if (data && isArrayWithLength(data.resolutionSteps)) {
		data.resolutionSteps.forEach((item) => {
			
			item.skill = {id: item.skill.id};
			
			form.append("resolutionSteps[]", JSON.stringify(item));
		});
	}
	
	if (data && isArrayWithLength(data.images)) {
		data.images.forEach((item, index) => {
			if (item) {
				if (item.uploadedImage)
					form.append("uploadedImages[]", item.uploadedImage);
				else
					form.append("images[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix('/questions'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateQuestion(id, data, csrfToken) {
	
	var form = objectToFormData(data, ["answerOptions", "skills", "resolutionSteps", "images"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.answerOptions)) {
		data.answerOptions.forEach((item) => {
			form.append("answerOptions[]", JSON.stringify(item));
		});
	}
	
	if (data && isArrayWithLength(data.skills)) {
		data.skills.forEach((item) => {
			form.append("skills[]", JSON.stringify({id: item.id}));
		});
	}
	
	if (data && isArrayWithLength(data.resolutionSteps)) {
		data.resolutionSteps.forEach((item) => {
			item.skill = {id: item.skill.id};	
			form.append("resolutionSteps[]", JSON.stringify(item));
		});
	}
	
	if (data && isArrayWithLength(data.images)) {
		data.images.forEach((item, index) => {
			if (item) {
				if (item.uploadedImage)
					form.append("uploadedImages[]", item.uploadedImage);
				else
					form.append("images[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix('/questions/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function getQuestionImageUrl(id, attachment) {

	var path = '/questions/images/'+id;
	var query = (attachment) ? objectToQueryParameters({"a": true}) : "";
	
	return appendPrefix(path)+query;
}

function getQuestionPublicImageUrl(id, attachment) {

	var path = '/questions/images/public/'+id;
	var query = (attachment) ? objectToQueryParameters({"a": true}) : "";
	
	return appendPrefix(path)+query;
}



function deleteQuestionImage(id, csrfToken) {

	var path = '/collections/photos/'+id;

	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
	
}

function lookupQuestionSource(id) {

	return fetch(appendPrefix('/questions/sources/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findQuestionSources(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/questions/sources/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageQuestionSources(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/questions/sources')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


function findSubjects(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/subjects/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageSubjects(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/subjects')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupSubject(id) {

	return fetch(appendPrefix('/subjects/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createSubject(data, csrfToken) {
	
	var form = objectToFormData(data, ["topics"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.topics)) {
		data.topics.forEach((item) => {
			form.append("topics[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix('/questions'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateSubject(id, data, csrfToken) {
	
	var form = objectToFormData(data, ["topics"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.topics)) {
		data.topics.forEach((item) => {
			form.append("topics[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix('/subjects/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function findSubjectTopics(subjectId, filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/subjects/'+subjectId + '/topics/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findSubjectTopicSkills(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/subjects/topics/skills/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findBNCCSkills(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/bncc/skills/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageBNCCSkills(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/bncc/skills')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

const API = { getConfig, getBuildInfo, getEnvironment, updateLanguage, getUserAuthState, loginUser, logoutUser, changeUserPassword,
		sendUserPasswordResetEmail, confirmUserPasswordReset, verifyUserPasswordResetToken,
		pageUsers, findUsers, lookupUser, createUser, updateUser, updateUserPassword, updateSelfPassword, importUsers, 
		findActivities, pageActivities,
		pageQuestions, findQuestions, lookupQuestion, createQuestion, updateQuestion, getQuestionImageUrl, getQuestionPublicImageUrl, /*deleteQuestionImage,*/
		pageQuestionSources, findQuestionSources, lookupQuestionSource,
		pageSubjects, findSubjects, lookupSubject, createSubject, updateSubject, findSubjectTopics, findSubjectTopicSkills,
		findBNCCSkills, pageBNCCSkills
};

export default API;
