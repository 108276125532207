import React,  { Fragment, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormSelect from 'react-bootstrap/FormSelect';

//import { CSVReader } from 'react-papaparse';
import { useCSVReader, formatFileSize } from "react-papaparse"

import Loader from "../common/Loader";
import FormControlErrors from "../common/FormControlErrors";
import ImportValidationResults from "../common/ImportValidationResults";

//import ImportConfig from "../../config/ImportConfig";
import UserConfig from "../../config/UserConfig";
import UserPasswordConfig from "../../config/UserPasswordConfig";
import DropZoneStyles from "../../styles/DropZoneStyles";

import { isArrayWithLength } from "../../helpers/commons";

import * as Yup from "yup";

const usersValidationRules = Object.assign({}, 
		UserConfig.GeneralFormValidationRules, 
		UserConfig.AccessControlFormValidationRules,  
		UserPasswordConfig.FormValidationRules
);

const usersValidationSchema = Yup.object().shape(usersValidationRules);

const UsersImportFormFields = props => {

	const [delimiter, setDelimiter] = useState("");
	const [header, setHeader] = useState(true);
	const [columnMappingStrategy, setColumnMappingStrategy] = useState("position");
	const [fileLoaded, setFileLoaded] = useState(false);
	
	const [isLoading, setIsLoading] = useState(true);
	const [transformationErrors, setTransformationErrors] = useState([]);
	
	const { CSVReader } = useCSVReader();
	
	const [zoneHover, setZoneHover] = useState(false)
  	const [removeHoverColor, setRemoveHoverColor] = useState(
    	DropZoneStyles.DEFAULT_REMOVE_HOVER_COLOR
  	)
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 	
		
		setIsLoading(false);	
		 
		 return () => { isMounted = false };
		  
	}, []);
	
	if (isLoading) 
		return <Loader />		
  	
  	const handleOnUploadAccepted = (results) => {
    	log.info("Results: ", results);

		setZoneHover(false)
		
		//Set File Loaded
		setFileLoaded(true);

		//Clear Error/Success
		props.setFieldError("data", null);
    	props.onError(null);
    	props.onSuccess(null);
		
		if (isArrayWithLength(transformationErrors)) {
			props.setFieldError("data", transformationErrors[0]);
			setTransformationErrors([]);
			return;
		}
		
		//Remove unnecessary headers/columns here
		let parsedData = results.data.map((item) => {
			let d = item;
			
			Object.keys(d).forEach( k => {if (!UserConfig.ImportHeaders.includes(k)) delete d[k];} )
			
			return d;
		});
		
		let parsingErrors = [];
		
		results.errors.forEach((errors) => {
			errors.forEach(e => {
   				log.error("Error Parsing CSV File: ", e);
				 parsingErrors.push(t(props.i18nPrefix+"form.csvreader.validation.parse-failed", {error: e}));
				
   			});
		});
		
		props.setFieldValue("data", parsedData);
		
		if (isArrayWithLength(parsingErrors))
			props.setFieldError("data", parsingErrors[0]);
		else if (!isArrayWithLength(parsedData))
			props.setFieldError("data", t(props.i18nPrefix+"form.csvreader.validation.no-data"));
			
  	};

	/*const handleOnError = (err, file, inputElem, reason) => {
    	log.error("handleOnError", err);
		props.setFieldError("data", t("users.import.form.csvreader.validation.parse-failed", {error: err}));
  	};*/

	const handleOnRemoveFile = (data) => {
    	props.setFieldValue("data", []);

		props.setFieldError("data", null);
		
		setTransformationErrors([]);
		
		setFileLoaded(false);
		
		//Clear Error/Success
    	props.onError(null);
    	props.onSuccess(null);

  	};

	const handleTransformHeaderByPosition = (header, position) => {
		return (UserConfig.ImportHeaders.length > position) ? UserConfig.ImportHeaders[position] : header;
  	};


	const usersEnumsMap = new Map([
		["active", new Map(UserConfig.ActiveOptions.map((e) => [t("users.import.form.data.enums.active."+e), e]))],
		["forceChangePassword", new Map(UserPasswordConfig.ForceChangePasswordOptions.map((e) => [t("users.import.form.data.enums.forceChangePassword."+e), e]))],
	]);
	
	const getRoleByName = (name) => {		
		return UserConfig.Roles.find(r => r === name)
	};
	
	const getPermissionByValue = (value) => {		
		return UserConfig.Permissions.find(p => p === value)
	};
	
	
	let defaultUserValues = usersValidationSchema.default();
	
	const handleTransform = (value, key) => {
		
		let errors = transformationErrors; 
		
		let result;
		
		let trimmedValue = (value) ? value.trim() : value;
		
		switch (key) {
			case "active":
			case "forceChangePassword":
				if(!trimmedValue) {
					result = defaultUserValues[key];
				} else if (usersEnumsMap.get(key) && usersEnumsMap.get(key).get(trimmedValue) !== undefined) {
					result = usersEnumsMap.get(key).get(trimmedValue) 
    			} else {
					//errors.push(t("users.import.form.data.validation.enum-"+key, {item: trimmedValue}));
					result = trimmedValue;
				}
    			break;
    		case 'roles':
				let roles = defaultUserValues[key];
				
				if (trimmedValue) {
					trimmedValue.split(",")
						.forEach((e) => {
							
							let role = getRoleByName((e)? e.trim() : e);
							
							if (!role) {
								errors.push(t(props.i18nPrefix+"form.data.validation.role-not-found", {role: e}));
								roles.push(e);
							} else {
								roles.push(role);
							}
						})
				}
				result = roles;
    			break;
    		case 'permissions':
				let permissions = defaultUserValues[key];
				
				if (trimmedValue) {
					trimmedValue.split(",")
						.forEach((e) => {
							
							let permission = getPermissionByValue((e)? e.trim() : e);
							
							if (!permission) {
								errors.push(t(props.i18nPrefix+"form.data.validation.permission-not-found", {permission: e}));
								permissions.push(e);
							} else {
								permissions.push(permission);
							}
						})
				}
				result = permissions;
    			break;
  			default:
    			result = trimmedValue;
		}
		
		setTransformationErrors(errors);
		
		return result;
  	};
    
	return(
		<Fragment>
			<Row className="mb-3">			
				<FormGroup as={Col} md={6} controlId="formGridActions">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.action.label"}>Action</Trans> *</FormLabel>
					<FormSelect name="action" disabled={fileLoaded} isInvalid={!(props.errors.action == null)} value={props.values.action} onChange={props.onChange} >
		    			{ UserConfig.ImportActions.map(item =>
		    				<option key={item} value={item}>{t(props.i18nPrefix+"form.action.options."+item)}</option>
		    			)}
		    		</FormSelect>
					<FormControlErrors errors={props.errors.action} />
				</FormGroup>
			</Row>
					<Row className="mb-3">
						<FormGroup as={Col} md={5} controlId="formGridDelimiter">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.delimiter.label"}>Delimiter</Trans> </FormLabel>
							<FormSelect name="delimiter" disabled={fileLoaded}  value={delimiter} onChange={(e) => {setDelimiter(e.target.value)}} >
		    				{ UserConfig.ImportDelimiterOptions.map(item =>
		    					<option key={item.key} value={item.value}>{t(props.i18nPrefix+"form.delimiter.options."+item.key)}</option>
		    				)}
		    				</FormSelect>
						</FormGroup>
								
						<FormGroup as={Col} md={3} controlId="formGridHeader">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.header.label"}>Header</Trans> </FormLabel>
							<FormSelect name="header" disabled={fileLoaded} value={header} onChange={(e) => {setHeader(e.target.value)}} >
		    				{ UserConfig.ImportFirstRowHeaderOptions.map(item =>
		    					<option key={item.key} value={item.value}>{t(props.i18nPrefix+"form.header.options."+item.key)}</option>
		    				)}
		    				</FormSelect>
						</FormGroup>
								
						<FormGroup as={Col} md={4} controlId="formGridColumnMappingStrategy">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.columnMappingStrategy.label"}>Column Mapping Strategy</Trans> </FormLabel>
							<FormSelect name="columnMappingStrategy" disabled={fileLoaded} value={columnMappingStrategy} onChange={(e) => {setColumnMappingStrategy(e.target.value)}} >
		    				{ UserConfig.ImportColumnMappingStrategyOptions.map(item =>
		    					<option key={item.key} value={item.key}>{t(props.i18nPrefix+"form.columnMappingStrategy.options."+item.key)}</option>
		    				)}
		    				</FormSelect>
						</FormGroup>
	
					</Row>
							
					<Row className="mb-3">
						<Col>
							<CSVReader
          						onDragOver={event => {
        							event.preventDefault()
        							setZoneHover(true)
      							}}
      							onDragLeave={event => {
        							event.preventDefault()
        							setZoneHover(false)
      							}}
          						onUploadAccepted={handleOnUploadAccepted}
          						
          						config={{
									"delimiter" : delimiter, 
									"header" : JSON.parse(header),
									"transformHeader" : handleTransformHeaderByPosition,
									"transform" : handleTransform,
									"skipEmptyLines": true
								}}
          						
        					>
        					  {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove
      }) => (
        <>
          <div
            {...getRootProps()}
            style={Object.assign(
              {},
              DropZoneStyles.DropZone,
              zoneHover && DropZoneStyles.DropZoneHover, (!(props.errors.data == null) ? {borderColor: "red"}: {})
            )}
          >
            {acceptedFile ? (
              <>
                <div style={DropZoneStyles.DropFile}>
                  <div
                    {...getRemoveFileProps()}
                    style={DropZoneStyles.RemoveButton}
                    onMouseOver={event => {
                      event.preventDefault()
                      setRemoveHoverColor(DropZoneStyles.REMOVE_HOVER_COLOR_LIGHT)
                    }}
                    onMouseOut={event => {
                      event.preventDefault()
                      setRemoveHoverColor(DropZoneStyles.DEFAULT_REMOVE_HOVER_COLOR)
                    }}
                    onClick={event => {
                 		getRemoveFileProps().onClick(event);
                 		handleOnRemoveFile();
               }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                  <div style={DropZoneStyles.DropFileInfo}>
                    <span style={DropZoneStyles.FileSizeInfo}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={DropZoneStyles.FileNameInfo}>{acceptedFile.name}</span>
                  </div>
                  <div style={DropZoneStyles.ProgressBar}>
                    <ProgressBar style={DropZoneStyles.ProgressBarBackgroundColor} />
                  </div>
                  
                </div>
              </>
            ) : (
              <span><Trans i18nKey={props.i18nPrefix+"form.csvreader.placeholder"} /></span>
            )}
          </div>
        </>
      )}
       						</CSVReader>
						</Col>
					</Row>
					<Row><Col>
						<ImportValidationResults
							i18nPrefix={props.i18nPrefix}
							isSubmitting={props.isSubmitting} 
							errors={props.errors.data} 
							data={props.values.data}
							headers={UserConfig.ImportHeaders}
						/></Col>
					</Row>

		
		</Fragment>
		
	) 
}


export default UsersImportFormFields;
