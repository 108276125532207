import React, { useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';

import moment from "moment";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import FormControlErrors from "../common/FormControlErrors";

import UserInputSearch from '../user/UserInputSearch';

import { ValidationError } from "../../helpers/custom-errors";
import ActivityConfig from "../../config/ActivityConfig";


setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const validationSchema = Yup.object().shape(ActivityConfig.FilterFormValidationRules);

const ActivityFilterForm = props => {
	
	const [subjectActionOptions, setSubjectActionOptions] = useState([]);
		
	const { t } = useTranslation();
	
	const handleSubjectChange = (e, setFieldValueFunction) => {
        
        setFieldValueFunction("subject", e.target.value);
        
        if (!e.target.value) {
        	setSubjectActionOptions([]);
        	setFieldValueFunction("action", "");
        	return;
        }

		// Filter SubjectActions Options
		setSubjectActionOptions(ActivityConfig.SubjectActions[e.target.value]);
        
        //Reset SubjectActions Status on every change
        setFieldValueFunction("action", "");
         
    }
	
	let initialValues = {};
	
	let defaultValues = validationSchema.default();
	
	Object.keys(ActivityConfig.FilterFormValidationRules)
		.forEach((key) => {
				initialValues[key] = (props.parameters[key]) ? props.parameters[key] : defaultValues[key];
		});
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}     
			onSubmit={(values, actions) => {
				props.onSubmit(values)
			 	.catch(error => { 
				
					if (error instanceof ValidationError) {		
    									
    						log.info("Filtering Items Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Filtering Items: ", error.message);
    					}
    								
    					props.onError(error);
			 	})
			 	.finally(() => {
			 		actions.setSubmitting(false);
			 	});
			}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
	
			<Form className="form-filter-data" noValidate>
				<fieldset>
					<div className={"border rounded p-4"}>
						<FormGroup as={Row} controlId="formGridUser" className="mb-3">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"user.label"}>User</Trans>
    						</FormLabel>
						    <Col sm="8">
								<UserInputSearch 
				    				i18nPrefix={props.i18nPrefix}
				    				onError={props.onError}
				    				isInvalid={!(errors.userId == null)}
				    				//onChange={handleUserChange}
				    				onChange={(user) => { 
										if (!user) {
											setFieldValue("filter", "");
											return;
											//setUserName(user.displayName + " (" +user.fullName+")"); }
										}
										
										setFieldValue("filter", user.id); 
										//setUserName(user.displayName + " (" +user.fullName+")"); 
									} }
									userId={values.filter}
									filterActiveOnly={false}
				    			/>
   							</Col>
						</FormGroup>
						<FormGroup as={Row} controlId="formGridAction" className="mb-3">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"action.label"}>Subject/Action</Trans>
    						</FormLabel>
							<Col sm="4">
								<FormSelect name="subject" isInvalid={!(errors.subject == null)} value={values.subject} onChange={(e) => handleSubjectChange(e, setFieldValue)} >
		    						<option value="">{t(props.i18nPrefix+"subject.options.blank")}</option>
		    						{ ActivityConfig.Subjects.map(item =>
		    							<option key={item} className="text-uppercase" value={item}>{item}</option>
		    						)}
		    					</FormSelect>
								<FormControlErrors errors={errors.subject} />
							</Col>
							<Col sm="4">
								<FormSelect name="action" disabled={!(values.subject)} isInvalid={!(errors.action == null)} value={values.action} onChange={handleChange} >
		    						<option  value="">{t(props.i18nPrefix+"action.options.blank")}</option>
		    						{ subjectActionOptions.map(item =>
		    							<option key={item} className="text-uppercase" value={item}>{item}</option>
		    						)}
		    					</FormSelect>
								<FormControlErrors errors={errors.action} />
							</Col>	
						</FormGroup>
						
						<FormGroup as={Row} controlId="formGridDateRange" className="mb-1">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"dateRange.label"}>Date Range</Trans>
    						</FormLabel>
							<Col sm="4">
								<FormControl 
									name="start"
									type={"datetime-local"} 
									isInvalid={!(errors.start == null)} 
									value={(values.start || '').toString().substring(0, 16)}
									onChange={(ev) => { 
      									if (!ev.target['validity'].valid) return;
  										if (ev.target['value']) { 
  											setFieldValue("start", moment(ev.target['value']).format());
  										} else  { // reset if null, empty, etc
      										setFieldValue("start", "");
      									}
      								}}
								/>
								<FormControlErrors errors={errors.start} />
							</Col>
							<Col sm="4">
								<FormControl 
									name="end"
									type={"datetime-local"} 
									isInvalid={!(errors.end == null)} 
									value={(values.end || '').toString().substring(0, 16)}
									onChange={(ev) => { 
      									if (!ev.target['validity'].valid) return;
  										if (ev.target['value']) { 
  											setFieldValue("end", moment(ev.target['value']).format());
  										} else  { // reset if null, empty, etc
      										setFieldValue("end", "");
      									}
      								}}
								/>
								<FormControlErrors errors={errors.end} />
							</Col>
							
							<Col sm="2">
								<Button variant="success" type="submit" disabled={isSubmitting} onClick={() => {}}> 
									{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
								</Button>
							</Col>
						</FormGroup>
					</div>
				</fieldset>
			</Form>	
		)}
	</Formik>
	)

}

export default ActivityFilterForm;
