import {useEffect} from "react";
import {$getRoot, $createParagraphNode, $insertNodes} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$generateNodesFromDOM  } from '@lexical/html';
//import { JSDOM } from "jsdom";

const LoadInitialContent = ({initialContent}) => {
	const [editor] = useLexicalComposerContext();
  
	useEffect(() => {
    
		let isMounted = true; 
    
    	if (!initialContent) { return; }
    
    	editor.update(() => {
        		
      		const parser = new DOMParser();
      		const dom = parser.parseFromString(initialContent, "text/html");
      		const nodes = $generateNodesFromDOM(editor, dom);
      		
      		//Clear editor content before inserting nodes
      		$getRoot().clear();
			//$getRoot().select();
			
			$insertNodes(nodes);
			
    	});
    	
    	return () => { isMounted = false };
    	

	}, []);
  
  	return null;
};

export default LoadInitialContent;