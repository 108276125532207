import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from 'react-i18next';

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import '../../styles/FormControlHelper.css';

const FormControlHelper = props => {

	const { t } = useTranslation();
	
	if (!props.text) {
		return null
	}
	
	const renderTooltip = (text) => (
  		<Tooltip className="form-control-helper-tooltip" >
    		{t(text)}
  		</Tooltip>
	);
	
	return (
		<OverlayTrigger overlay={renderTooltip(props.text)}>
			<FontAwesomeIcon icon={faQuestionCircle} className="align-text-top" size="xs" title={t(props.text)}/>
		</OverlayTrigger>
	)
};

export default FormControlHelper;