import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
//import FormLabel from 'react-bootstrap/FormLabel';

import FormControlErrors from "../common/FormControlErrors";

import CustomDraftJSEditor from "../draft/CustomDraftJSEditor";
import CustomLexicalEditor from '../lexical/CustomLexicalEditor';

const QuestionAnswerOptionFormFields = props => {
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
			
		 return () => { isMounted = false };
		  
	}, []);
	

	const handleAnswerChange = (content) => {
        
        props.setFieldValue("answer", content);  
    }

   
	return(
		<Fragment>				
			<Row>
				<FormGroup as={Col} controlId="formGridAnswer">
				    {/*<FormLabel><Trans i18nKey={props.i18nPrefix+"form.answer.label"}>Answer</Trans></FormLabel>*/}
				    <CustomLexicalEditor
  						onChange={handleAnswerChange}
  						content={props.values.answer}
  						isInvalid={!(props.errors.answer == null)}
  						context={props.context}
					/>
				    <FormControlErrors block errors={props.errors.answer} />
				</FormGroup>
			</Row>
		</Fragment>
		
	) 
}


export default QuestionAnswerOptionFormFields;
