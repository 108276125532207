import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faClose, faEdit, faCheck} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import SaveSubjectTopicModal from "./SaveSubjectTopicModal";

import FormControlErrors from "../common/FormControlErrors";

import ConfirmationDialogModal from "../common/ConfirmationDialogModal";
import ShowDialogModal from '../common/ShowDialogModal';
import DataTable from '../common/DataTable';

import API from "../../services/backend-api";
import { useAuth } from "../../services/use-auth";
import { useConfig } from "../../services/use-config";

//import SubjectConfig from "../../config/SubjectConfig";
import BNCCModel from "../../config/BNCCModel";

import {isArrayWithLength, cloneObject } from "../../helpers/commons";

//import DataTable from '../components/DataTable';


const SubjectFormFields = props => {
	
	const [saveTopicModalShow, setSaveTopicModalShow] = useState(false);
	const [deleteTopicModalShow, setDeleteTopicModalShow] = useState(false);
	const [topicDeletionPendingModalShow, setTopicDeletionPendingModalShow] = useState(false);
	const [selectedTopic, setSelectedTopic] = useState(null);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const newSubjectTopic = () => {
	  setSelectedTopic(null);
	  setSaveTopicModalShow(true);
    }
    
    const updateSubjectTopic = (item, actionIndex, rowIndex) => {
	  setSelectedTopic(Object.assign({"index": rowIndex}, item));
	  setSaveTopicModalShow(true);
 	}
 	
    const validateSubjectTopicRemoval = (item, actionIndex, rowIndex) => {
	  
	  //First validate if topic has skills associated with any question
	  let query = {};
	  query.filteredColumn = "topicId";
	  query.filter = item.id;
	  API.pageQuestions(query)
		.then(response => {
			 if (response.totalCount == 0) {
			 	setSelectedTopic(Object.assign({"index": rowIndex}, item));
	 		 	setDeleteTopicModalShow(true);
	  		 } else {
				setTopicDeletionPendingModalShow(true);
			 }
		})
		.catch(error => { 
			log.error("Error Finding Questions: ", error.message);
		 	props.onError(error);
		})
	  
    }
    
    const deleteSubjectTopic = (item) => {
		
		setDeleteTopicModalShow(false);
		
		//Shallow copy of Array, only to "dirty"" the form
		let subjectTopicsCopy = [...props.values.topics];
		
		subjectTopicsCopy.splice(item.index, 1);
		
		props.setFieldValue("topics", subjectTopicsCopy);
		props.setFieldError("topics", null);

  }
  
  const handleSubjectTopicSaved = (action, item, index) => {
	  setSaveTopicModalShow(false);
	  
	  //Shallow copy of Array, only to "dirty"" the form
	  let subjectTopicsCopy = [...props.values.topics];
	    
	  if (action === "create") {
		  subjectTopicsCopy.push(item);
	  } else {
		 subjectTopicsCopy.splice(index, 1, item);
	  }
		  
	  props.setFieldValue("topics", subjectTopicsCopy);
	  props.setFieldError("topics", null);
	  
  }
  
  const handleDisplayBNCCSkills = (items) => {
	  return ( isArrayWithLength(items) ? items.map((item, index) => <Badge key={index} pill bg="info">{item}</Badge>) : "-")
  	}
    
	return(
		<Fragment>
		{(saveTopicModalShow) && <SaveSubjectTopicModal
				show={saveTopicModalShow}
	        	onHide={() => setSaveTopicModalShow(false)}
				size="xl"
				item={selectedTopic}
				onItemSaved={handleSubjectTopicSaved}
		      />
			}
			{(selectedTopic) && <ConfirmationDialogModal
				item={selectedTopic}
				show={deleteTopicModalShow}
        		onHide={() => setDeleteTopicModalShow(false)}
				size="lg"
				title={t("subjects.delete-topic-confirmation-modal.title")}
				bodyText={t("subjects.delete-topic-confirmation-modal.body", {item: selectedTopic})}
				confirmText={t("subjects.delete-topic-confirmation-modal.confirm")}
				cancelText={t("subjects.delete-topic-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteSubjectTopic}
			/>}
			{(topicDeletionPendingModalShow) && <ShowDialogModal
				show={topicDeletionPendingModalShow}
        		onHide={() => setTopicDeletionPendingModalShow(false)}
				size="lg"
				title={t("subjects.topic-deletion-pending-dialog-modal.title")}
				bodyText={t("subjects.topic-deletion-pending-dialog-modal.body")}
				cancelText={t("subjects.topic-deletion-pending-dialog-modal.cancel")}
				variant="warning"
			/>}
		
		<Row className="mb-3">
			<FormGroup as={Col} controlId="formGridName">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.name.label"}>Name</Trans> *</FormLabel>
				<FormControl name="name" isInvalid={!(props.errors.name == null)} value={props.values.name} onChange={props.onChange} />
				<FormControlErrors errors={props.errors.name} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridField">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.field.label"}>Field</Trans> *</FormLabel>
				<FormSelect name="field" isInvalid={!(props.errors.field == null)} value={props.values.field} onChange={props.onChange} >
		    		<option  value="">{t(props.i18nPrefix+"form.field.options.blank")}</option>
		    		{ BNCCModel.KnowledgeFields.map(item =>
		    			<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"form.field.options."+item)}</option>
		    		)}
		    	</FormSelect>
				<FormControlErrors errors={props.errors.field} />
			</FormGroup>
		</Row>
		
		 <Row>
			<FormGroup as={Col} controlId="formGridTopics" className="mb-1">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.topics.label"}>Topics</Trans></FormLabel>
					<fieldset>
						<div className={(props.errors.topics == null) ? "border rounded p-4" : "border border-danger rounded p-4"}>
							<Row className="align-items-center">
							 	<Col>
							 		<DataTable
        								items={props.values.topics} 
        								i18nPrefix={props.i18nPrefix+"form.topics.datatable."}
        								columns={["name", "skills", "bnccSkills"]} 
        								customDisplayColumns={[["skills", "Count"],["bnccSkills", handleDisplayBNCCSkills]]}
        								actions={[updateSubjectTopic, validateSubjectTopicRemoval]}
        								defaultAction={updateSubjectTopic}
        								//noItemsDisclaimer={true}
        							/>
        						</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button variant="primary" onClick={newSubjectTopic}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.topics.add-button"}>Add Topic</Trans></Button>
							 	</Col>
							</Row>
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={ !isArrayWithLength(props.errors.topics) ? props.errors.topics : props.i18nPrefix+"form.topics.validation.general"} />
				</FormGroup>
		   </Row>
		</Fragment>
	) 
}

		
export default SubjectFormFields;
