import * as Yup from "yup";
import ImportConfig from "../config/ImportConfig";
import BNCCModel from "../config/BNCCModel";
import { isArrayWithLength } from "../helpers/commons";

Yup.addMethod(Yup.array, "hasCorrectOrder", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasCorrectOrder", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		const uniques = new Set(value.map(item => parseInt(item["skillOrder"])));
		let ordering = [...uniques].sort((a,b) => a-b);
		
    	return (ordering.length === value.length && ordering.at(0) === 1 && ordering.at(-1) === ordering.length)
    			|| createError({ path, message: message });
    
    });
});

Yup.addMethod(Yup.array, "hasDistinctValues", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasDistinctValues", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		const uniques = new Set(value);   
    	return [...uniques].length === value.length
    			|| createError({ path, message: message }); 
    
    });
});


const TopicSkillFormValidationRules = {
	id: Yup.string()
		.default(() => ""),
	name: Yup.string()
		.max(255, 'subjects.save-topic-skill-modal.form.name.validation.maximumlength')
		.required()
		.default(() => ""),
	skillOrder: Yup.number()
		.required()
		.default(() => 0),
   	active: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => true),		
}

const MultipleTopicSkillsFormValidationRules = {
	skills: Yup.array().of(Yup.object().shape(TopicSkillFormValidationRules))
   		.default(() => [])
}

const TopicFormValidationRules = {
	id: Yup.string()
		.default(() => ""),
	name: Yup.string()
		.max(255, 'subjects.save-topic-modal.form.name.validation.maximumlength')
		.required()
		.default(() => ""),
	bnccSkills:Yup.array()
		.hasDistinctValues({ message: 'subjects.save-topic-modal.form.skills.validation.distinct-values'})
   		.default(() => []),
   	skills:Yup.array()
   		.hasCorrectOrder({ message: 'subjects.save-topic-modal.form.skills.validation.correct-order'})
   		.default(() => []),
   	active: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => true),			
}

const FormValidationRules = {
	name: Yup.string()
		.max(255, 'subjects.save.form.name.validation.maximumlength')
		.required()
		.default(() => ""),
   	field: Yup.string()
		.oneOf([""].concat(BNCCModel.KnowledgeFields), 'form.validation.string.enum')
		.required()
   		.default("MATHEMATICS"),
   	topics: Yup.array().of(Yup.object().shape(TopicFormValidationRules))
		.default(() => []),
	active: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => true),
}

const ExportHeaders =[
	"id", "name", "field", "active", "topics", "whenModified", "whenCreated"
]

const ImportHeaders =[
	"id", "name", "field", "active", "topics"
]

const ImportActions = ImportConfig.Actions;
const ImportDelimiterOptions = ImportConfig.DelimiterOptions;
const ImportFirstRowHeaderOptions = ImportConfig.FirstRowHeaderOptions;
const ImportColumnMappingStrategyOptions = ImportConfig.ColumnMappingStrategyOptions;

const ImportFormValidationRules = {
	   action: Yup.string()
		.oneOf([""].concat(ImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(FormValidationRules))
		.max(255, 'subjects.import.form.data.validation.maximum-size' )
		.default(() => []),
}

const FilterFormValidationRules = {
	filter: Yup.string()
		.test(
        	'empty-or-3-characters-check',
        	'subjects.list.form.filter.validation.minimumlength',
        	filter => !filter || filter.length >= 3,
    	)
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "name"),
	field: Yup.string()
   		.default(() => ""),
	
}

const AdvancedFilterFormFields = {
	
}

const QuestionConfig = { FormValidationRules, TopicFormValidationRules, TopicSkillFormValidationRules, MultipleTopicSkillsFormValidationRules,
		ImportHeaders, ExportHeaders, ImportFormValidationRules,
		ImportActions, ImportDelimiterOptions, ImportFirstRowHeaderOptions, ImportColumnMappingStrategyOptions,
		FilterFormValidationRules, AdvancedFilterFormFields
		
};

export default QuestionConfig;