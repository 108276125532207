import React, { Fragment, useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faUpload } from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

//import API from "../../services/backend-api";
import { useAuth } from "../../services/use-auth";
import { isArrayWithLength } from '../../helpers/commons';


const thumb = {
	position: "relative",
	display: 'inline-flex',
	//borderRadius: 2,
	//border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 160,
	height: 160,
	padding: 4,
	boxSizing: 'border-box'
};

const thumbInner = {
	//display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};


const img = {
	display: 'block',
	width: '100%',//width: 'auto',
	height: '100%',
	cursor: "pointer",
	objectFit: "fill"
};

const disabledImg = {
	display: 'block',
	width: '100%',//width: 'auto',
	height: '100%',
	cursor: "not-allowed",
	objectFit: "fill",
	opacity: 0.2
};

const disabledImgIcon = {
	position: "absolute",
	right: 10,
	bottom: 10,
	opacity: 0.5,
	border: 0,
	borderRadius: ".325em",
};

const MultipleImageList = props => {

	const [selectedIndex, setSelectedIndex] = useState(-1);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		return () => { isMounted = false };
		  
	}, []);
	
	if (!isArrayWithLength(props.items)) {
		if (props.noItemsDisclaimer)
			return <Trans i18nKey={props.i18nPrefix+"no-items-disclaimer"} />
		else
			return null
	}
		
    return (
        <Container>
	    	<Row className="multiple-image-list mb-3">
	    		<Col>
	    		{ props.items.map((item, index) => (
					<div key={index} style={thumb} className={(selectedIndex===index) ? "border-primary img-thumbnail" : "img-thumbnail"}>
				      	<div style={thumbInner} className="w-100">
				      		{ (item.disabled) ?
				      			<>
				      				<Image src={item.preview} style={disabledImg} />
									<span style={disabledImgIcon}>
				      					<FontAwesomeIcon size="xl" icon={faBan} />
				      				</span>
				      			</>
				      			:
				      			<Image src={item.preview} style={img} onClick={() => { setSelectedIndex(index); props.onChange(item);} } />
				        	}
				        </div>
				    </div>
				    
	    		))}
	    		</Col>
	    	</Row>
        </Container>
    )
}

export default MultipleImageList;