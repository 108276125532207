import React,  { useState, useEffect, Fragment } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation, Trans } from 'react-i18next';

import { jsonToCSV } from "react-papaparse";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faFileDownload} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner';

import SubjectFilterForm from "./SubjectFilterForm";

import Loader from "../common/Loader";
import PagedList from "../common/PagedList";
import CustomContainer from  "../common/CustomContainer";

import SubjectConfig from "../../config/SubjectConfig";
import API from "../../services/backend-api";

import { isArrayWithLength } from "../../helpers/commons";

import '../../styles/Subjects.css';
import BNCCModel from '../../config/BNCCModel';


const thumbTitleInactive = {
    /*overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",*/
	textDecoration: "line-through"
}

const dataTableActionModals = [];


const SubjectThumbnail = props => {
	
	const { t } = useTranslation();
	
	if (!props.subject)
		return null;
		
	
	return (
		<Card className="subject thumbnail">
  			<Card.Body onClick={() => props.onClick(props.subject)}>
    			<Card.Title className="thumb-title" style={(JSON.parse(props.subject.active)) ? {} : thumbTitleInactive }>
					{props.subject.name}
				</Card.Title>
    			<Card.Subtitle className="thumb-subtitle mb-2 text-danger">{t("subjects.list.cards.subtitle", {count: Number(props.subject.totalTopics)})}</Card.Subtitle>
    			<Card.Text className="thumb-text mb-1 text-muted">{t("subjects.list.cards.text", {count: Number(props.subject.totalSkills)})}</Card.Text>
  			</Card.Body>
		</Card>
	)
}

const Subjects = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isExporting, setIsExporting] = useState(false);
	const [subjects, setSubjects] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	
	const { t } = useTranslation();
	
	let navigate = useNavigate();
	
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		//Initializing Action Modals Show Status
		resetModalsVisibility();
		
		findSubjects({})
			.catch(error => { 
				log.error("Error Loading Initial Subjects List: ", error.message);
				context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
	  setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }
   
   const handleSuccess = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }
   
  const updateSubject = (subject) => {
	  navigate("/subjects/update", { state: { item: subject} });
  }
  
  const addSubject = () => {
	  navigate("/subjects/create");
  }
    
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const renderTooltip = (text) => (
  		<Tooltip className="form-control-helper-tooltip" >
    		{text}
  		</Tooltip>
	);
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const refreshPage = () => {
		  
	  //List Subjects with no new values
	  findSubjects({})
		.catch(error => { 
	 		log.error("Error Refreshing Subjects List: ", error.message);
	 		context.onError(error);
		})	
  }  
  
  const exportSubjects = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(subjects, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findSubjects(exportFilters).then(response => {

			let columns = SubjectConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.subject = r.subject.shortcode;
				row.topic = r.topic.name;
				row.source = r.source.name;
				row.choices = JSON.stringify(r.choices);
				row.images = JSON.stringify(r.images);
				
				
				row.active = t("subjects.list.export.enums.active."+row.active);
				//row.type = t("subjects-transactions.list.export.enums.type."+row.type);
				
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("subjects.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});

  
  const findSubjects = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
		context.onError(null);
		
		API.findSubjects(mergeQueryParams(subjects, values)).then(response => {
			setSubjects(response);
			resolve(response);
		}).catch(error => { 	
			reject(error);
		});
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!subjects) 
		return null;
		
	return (
		<CustomContainer >
		    <Row className="mb-3">
        		<Col sm={7} xl={8}>
        			<h3>
        				{t("subjects.list.header", {count: subjects.list.length})}
        				{(subjects.totalCount > 0) && (
		            		<OverlayTrigger overlay={renderTooltip(t("subjects.list.export-button"))} placement="right" rootClose={true}>	
		            			<Button variant="link" onClick={exportSubjects} disabled={isExporting} > 
		            				{ isExporting 
										? <Spinner animation="border" size="sm" /> 
										: <FontAwesomeIcon icon={faFileDownload} size="lg" className="align-bottom" />
									}
								</Button>
		        			</OverlayTrigger>
		        		)}
		        		
        			</h3>
        		</Col>
				<Col sm={5} xl={4}>
					<div className="d-flex justify-content-end">
						<Button variant="outline-success"  onClick={addSubject}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={"subjects.list.add-button"} /></Button>	
					</div>
				</Col>
        	</Row>
		    <Accordion defaultActiveKey="0">
			{ BNCCModel.KnowledgeFields.map((field, index) => {
				
				let fieldSubjects = subjects.list.filter((subject) => subject.field === field);
										
				return (
					<Accordion.Item key={field} eventKey={index+""}>
        				<Accordion.Header><h5><Trans i18nKey={"subjects.list.cards.enums.field."+field}>{field}</Trans> ({fieldSubjects.length})</h5></Accordion.Header>
       					<Accordion.Body>
         					<Row>
								{  !(isArrayWithLength(fieldSubjects)) ?
										<Col><span className="font-italic"><Trans i18nKey="subjects.list.cards.no-items"></Trans></span></Col>
								   :
									<Fragment>
										{fieldSubjects.map((subject, index) => 
											<Col key={index} md={4} className="mb-3">
												<SubjectThumbnail 
													subject={subject} 
													onClick={updateSubject}/>
												</Col>
										)}
									</Fragment>
								}

        					</Row>
        				 </Accordion.Body>
      				</Accordion.Item>
	      		)
    		})}
			</Accordion>
		</CustomContainer>
		
	);
}

export default Subjects;
