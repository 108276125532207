import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import * as Yup from "yup";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';

import FormControlErrors from "../common/FormControlErrors";

import API from "../../services/backend-api";
import { isArrayWithLength } from '../../helpers/commons';

const SearchSubjectTopicSkillFormFields = props => {
	const [subjects, setSubjects] = useState([]);
	const [subjectTopics, setSubjectTopics] = useState([]);
	const [subjectTopicSkills, setSubjectTopicSkills] = useState([]);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		API.findSubjects({}).then(response => {
			if (isMounted) setSubjects(response.list);
		}).catch(error => {			
			log.error("Error Loading Subjects: ", error.message);
		 	props.onError(error);
		});

		return () => { isMounted = false };
		  
	}, []);
	
	const getSubjectTopics = (subject) => {
    	API.findSubjectTopics(subject.id, {})
			.then(response => {
					setSubjectTopics(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Subject Topics: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }
	
	const handleSubjectChange = (e) => {
        
        let selectedSubject = subjects.find(item => (item.id == e.target.value)); 
        
        if (!selectedSubject) {
			props.setFieldValue("subject", null);
        	setSubjectTopics([]);
        	props.setFieldValue("topic", null);
        	setSubjectTopicSkills([]);
        	props.setFieldValue("skill", null);
        	return;
        }
        
        props.setFieldValue("subject", selectedSubject);
        
        //Get Subject Topics
        getSubjectTopics(selectedSubject);
        
        //Reset topic
        props.setFieldValue("topic", null);
    }
    
    const handleSubjectTopicChange = (e) => {
        
        let selectedTopic = subjectTopics.find(item => (item.id == e.target.value)); 
        
        if (!selectedTopic) {
			props.setFieldValue("topic", null);
        	setSubjectTopicSkills([]);
        	props.setFieldValue("skill", null);
        	return;
        }
        
        props.setFieldValue("topic", selectedTopic);
        
        //Set Subject Topic Skills
        setSubjectTopicSkills(selectedTopic.skills);
        
        //Reset Skill
        props.setFieldValue("skill", null);
    }
    
    const handleSubjectTopicSkillChange = (e) => {
        
        let selectedSkill = subjectTopicSkills.find(item => (item.id == e.target.value)); 
        
        if (!selectedSkill) {
        	props.setFieldValue("skill", null);
        	return;
        }
        
        props.setFieldValue("skill", selectedSkill);
        
    }
	
	/*const handleNewTopicSkillsChange = (text, lineItems) => {
        props.setFieldValue("skills", lineItems.map(item => 
        	Object.assign({}, validationSchema.default(), {"name": item }))
        );
    }*/
    
    const sortBySkillOrder = (a,b) => {		
  		return parseInt(a.skillOrder)-parseInt(b.skillOrder);
 	}
	
	return(
		<Fragment>	

			<FormGroup as={Row} controlId="formGridSubject" className="mb-3">
				<FormLabel column sm="2">
      				<Trans i18nKey={props.i18nPrefix+"subject.label"}>Subject</Trans>
    			</FormLabel>
				<Col>
					<FormSelect name="subject" className="text-uppercase" isInvalid={!(props.errors.subject == null)} value={(props.values.subject) ? props.values.subject.id : ""} onChange={handleSubjectChange} >
		    			<option value="">{t(props.i18nPrefix+"subject.blank-option")}</option>
		    			{ subjects.filter(item => (item.totalTopics)).map(item =>
		    				<option key={item.id} value={item.id}>{item.name}</option>
		    			)}
		    		</FormSelect>
					<FormControlErrors errors={props.errors.subject} />
				</Col>
			</FormGroup>
			
			<FormGroup as={Row} controlId="formGridSubject" className="mb-3">
				<FormLabel column sm="2">
      				<Trans i18nKey={props.i18nPrefix+"topic.label"}>Topic</Trans>
    			</FormLabel>
				<Col>
					<FormSelect name="topic" className="text-uppercase" disabled={!(props.values.subject)} isInvalid={!(props.errors.topic == null)} value={(props.values.topic) ? props.values.topic.id : ""} onChange={handleSubjectTopicChange} >
		    			<option  value="">{t(props.i18nPrefix+"topic.blank-option")}</option>
		    			{ subjectTopics.filter(item => (isArrayWithLength(item.skills))).map(item =>
		    				<option key={item.id} value={item.id}>{item.name} ({item.skills.length})</option>
		    			)}
		    		</FormSelect>
					<FormControlErrors errors={props.errors.topic} />
				</Col>	
			</FormGroup>
			
			<FormGroup as={Row} controlId="formGridSubjectTopicSkill" className="mb-3">
				<FormLabel column sm="2">
      				<Trans i18nKey={props.i18nPrefix+"skill.label"}>Skill</Trans>
    			</FormLabel>
				<Col>
					<FormSelect name="topic" disabled={!(props.values.topic)} isInvalid={!(props.errors.skill == null)} value={(props.values.skill) ? props.values.skill.id : ""} onChange={handleSubjectTopicSkillChange} >
		    			<option  value="">{t(props.i18nPrefix+"skill.blank-option")}</option>
		    			{ subjectTopicSkills.sort(sortBySkillOrder).map(item =>
		    				<option key={item.id} value={item.id}>{item.skillOrder}-{item.name} </option>
		    			)}
		    		</FormSelect>
					<FormControlErrors errors={props.errors.skill} />
				</Col>	
			</FormGroup>
			

		</Fragment>
		
	) 
}


export default SearchSubjectTopicSkillFormFields;
