import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import log from 'loglevel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';
import ReCAPTCHA from "react-google-recaptcha";

import { getHomeBrowserRoute } from "../helpers/commons";
import { PrivateRoutesConfig } from '../config/PrivateRoutesConfig';

import FormControlErrors from "../components/common/FormControlErrors";
import DismissableFeedback from "../components/common/DismissableFeedback";
import Loader from "../components/common/Loader";

import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";
import { ValidationError } from "../helpers/custom-errors";

import logo from '../images/logo-bg-light.png';

import { isArrayWithLength, isAllowedRoute } from "../helpers/commons";

import '../styles/LoginPage.css';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   },
	   string: {
	     email: 'form.validation.string.email',
	   },
	 });

const validationSchema = Yup.object().shape({
	   email: Yup.string()
	     .email()
	     .required(),
	   password: Yup.string()
	     .required(),
	   gRecaptchaResponse: Yup.string()
	     .required("login.form.gRecaptchaResponse.validation.required")
	     //.nullable()
	     
	 });

const LoginPage = props => {
	
  const [_error, _setError] = useState(null);
  const [_success, _setSuccess] = useState(null);
  
  //const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  
  const location = useLocation();
  
  const recaptchaRef = React.createRef();
  
  const { t } = useTranslation();
	  
  let navigate = useNavigate();
  
  const config = useConfig();
  
  const auth = useAuth();
  
  useEffect(() => {
	let isMounted = true; 
  	
  	if (auth.globalSuccess) {
  		if (isMounted) _setSuccess(auth.globalSuccess)
  	}
  	
  	if (config.globalError || auth.globalError) {
  		if (isMounted) {
  			if (config.globalError)
  				_setError(config.globalError.message)
  			else
  				_setError(auth.globalError.message)
  		}
  	}
  	
  	return () => { isMounted = false }; 
  	
  }, [config, auth]);
  
  const showLanguageOptions = () => {
	if (!isArrayWithLength(config.availableLanguages) || config.availableLanguages.length === 1) 
			return null;
	
	return (
		<div>
             {config.availableLanguages.map((lang) => (
                   (lang !== config.preferredLanguage) 
                   		&& <Button key={lang} variant="link" onClick={() => config.handleLanguageChange(lang, changeLanguageResponse)} ><FontAwesomeIcon icon={faGlobe} /> <Trans i18nKey={"app.language."+lang}>{lang}</Trans></Button>
              ))} 	
        </div>
	)
  };
  
  const changeLanguageResponse = (type, feedback ) => {
		if (type === "success")
			_setSuccess(feedback);
		else
			_setError(feedback);
  };
  
  if (!config.initialized || !auth.initialized) 
  	return <Loader />
	  
	  return (
          <Container className="text-center pt-2 pb-2">
          	<DismissableFeedback feedback={_error} type="danger" onClose={() => _setError(null)}/>
			<DismissableFeedback feedback={_success} type="success" onClose={() => _setSuccess(null)} />
          	<Formik
          		initialValues={{ email: '', password: '' }}
          		validationSchema={validationSchema}
          		validateOnChange={false}
          		validateOnBlur={false}     
          		onSubmit={(values, actions) => {
          			//Clear Error
          			_setError(null);
          			_setSuccess(null);
          			auth.loginUser({values: values})
          				.then((response) => {
							if (response.status === 'CHANGE_PASSWORD') {
								navigate("/change-password");
							} else if (response.status === 'COMPLETED') {
								if (location.state && location.state.from) {
									let routeFound = PrivateRoutesConfig.find(route => route.path === location.state.from.pathname);
									if (routeFound && isAllowedRoute(routeFound, response.userData))
										navigate(location.state.from);
									else
										navigate(getHomeBrowserRoute(PrivateRoutesConfig, response.userData));	
								} else {
							  		navigate(getHomeBrowserRoute(PrivateRoutesConfig, response.userData));
							  	}
							} else {
								actions.setSubmitting(false);
							}
          				})
          				.catch(error => {
          					
          					actions.setSubmitting(false);
          					
          					//Reset Recaptcha to avoid validating same captcha twice
          					recaptchaRef.current.reset();
          					actions.setFieldValue("gRecaptchaResponse", "");
          					
          					if (error instanceof ValidationError) {		
          						
          						log.info("User Login Attempt Failed: ", error.message);
          						
          						if (error.code.indexOf("csrf-") !== -1) {
          							_setError(t('error.api.csrf'));
          						} else {
          							_setError(error.message);
          						}
          						
          						if (error.detail) {            						
              						for (let key of Object.keys(error.detail)) {
              							let errorMsgs = error.detail[key];
              							errorMsgs.forEach((message) => {
              								actions.setFieldError(key, message);
              							});
              						}
              					}
              					
          						
          					} else {
          						
          						log.error("User Login Error: ", error.message);
          						_setError(t('error.api.general'));
          					}

          				})
          				
          		}}
          	>
          		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
          			<Form className="form-signin" noValidate>
          				<Image className="mb-3" width="200" src={logo} alt={t('app.logo')}/>
                  		<h1 className="h3 mb-3 fw-normal"><Trans i18nKey="login.form.header">Please sign in</Trans></h1>
                  		
                  		<FormGroup controlId="formEmail">
          	                <FormLabel className="sr-only"><Trans i18nKey="login.form.email.label">Email address</Trans></FormLabel>
          	                <FormControl type={'email'} name="email" isInvalid={!(errors.email == null)} value={values.email} onChange={handleChange} placeholder={t('login.form.email.placeholder')} />
          	                <FormControlErrors errors={errors.email} />
          	            </FormGroup>
                  		
      	                <FormGroup controlId="formPassword" className="mb-3" >
      	                	<FormLabel className="sr-only"><Trans i18nKey="login.form.password.label">Password</Trans></FormLabel>
      	                	<FormControl type={'password'} name="password" isInvalid={!(errors.password == null)} value={values.password} onChange={handleChange} placeholder={t('login.form.password.placeholder')} autoComplete="current-password"/>
      	                	<FormControlErrors errors={errors.password} />
      	                </FormGroup>
						
						{(config.recaptchaSiteKey) ?
						<FormGroup controlId="formRecaptcha" className="mb-3" >
								<ReCAPTCHA ref={recaptchaRef} className={!(errors.gRecaptchaResponse == null) ? "google-recaptcha is-invalid" : "google-recaptcha"} sitekey={config.recaptchaSiteKey} onChange={(value) => {setFieldValue("gRecaptchaResponse", value)}} /> 
								{!(errors.gRecaptchaResponse == null) 
          							? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={errors.gRecaptchaResponse} /></div>
									: null
								}
						</FormGroup>
						: null
						}
                  		
                  		<div className="d-grid gap-2">
                  			<Button variant="success" type="submit" size="lg" disabled={isSubmitting || !config.recaptchaSiteKey}> 
                  		    	{ isSubmitting ? <Trans i18nKey="login.form.submitting">Please wait...</Trans> : <Trans i18nKey="login.form.submit">Submit</Trans>} 
              				</Button>
              			</div>
              			
              			<Button as={Link} variant="link" to="/forgot-password"><Trans i18nKey="login.forgot-password">Forgot Password</Trans></Button>
              			
              			{showLanguageOptions()}
              			
              		</Form>		
          		 )}
   		     </Formik>  
          </Container>
    );
}

export default LoginPage;
