import React, {useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
//import FormSelect from 'react-bootstrap/FormSelect';
//import FormCheck from 'react-bootstrap/FormCheck';
import InputGroup from 'react-bootstrap/InputGroup';

import Switch from "../../common/Switch";
import FormControlHelper from '../../common/FormControlHelper';
import DismissableFeedback from "../../common/DismissableFeedback";
import FormControlErrors from "../../common/FormControlErrors";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import SelectQuestionImageModal from '../../question/SelectQuestionImageModal';
import { isArrayWithLength } from '../../../helpers/commons';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

//const InlineImagePositions = ["LEFT", "RIGHT", "FULLWIDTH"]

const validationSchema = Yup.object().shape({
	imageURL: Yup.string()
		.required()
   		.default(""),
   	altText: Yup.string()
   		.default(""),
   	inline: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => false),
    /*position: Yup.string()
		.oneOf([""].concat(InlineImagePositions), 'form.validation.string.enum')
		.required()
   		.default(""),*/
});

const InsertImageModal = props => {
	const [_error, _setError] = useState(null);
	const [showSelectImageModal, setShowSelectImageModal] = useState(false);
	
	const { t } = useTranslation();
	
	let initialValues = validationSchema.default()

	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-insert-image-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-insert-image-modal">
  					{(props.title)
  						? props.title
  						: <Trans i18nKey="components.insert-image-modal.title">Insert Image Title</Trans>
  					}
  				</Modal.Title>
  			</Modal.Header>
  			
  			<Formik
					initialValues={initialValues}
		    		validationSchema={validationSchema}
		    		validateOnChange={false}
		    		validateOnBlur={false}
		    		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						actions.setSubmitting(false);
						
						props.onConfirm({src: values.imageURL, altText: values.altText /*, position: position*/ }, values.inline) 
					}}
		    	>
		   		{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
				
				<Form className="form-insert-equation-modal" noValidate>	
				
  				<Modal.Body className="pt-0 pb-0">
  				 <Container className="p-2">
  				 	
  				 	<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />
  				 	
  				 	{showSelectImageModal && (
						<SelectQuestionImageModal
							size="lg"
							show={showSelectImageModal} 
							onHide={() => {setShowSelectImageModal(false);}}
							onSelected={(image) => {
								setFieldValue("imageURL", image.preview);
								setShowSelectImageModal(false);
								
							}}
							items={isArrayWithLength(props.images) ? props.images : []}
						/>
		  			)}
							
  					<Row className="mb-3">
						<FormGroup as={Col} controlId="formGridImageURL">
							<FormLabel><Trans i18nKey={"components.insert-image-modal.form.imageURL.label"}>Image URL</Trans> * <FormControlHelper text={"components.insert-image-modal.form.imageURL.helper"}/></FormLabel>
							<InputGroup>
								<FormControl type={"text"} name="imageURL" isInvalid={!(errors.imageURL == null)}  value={values.imageURL} onChange={handleChange} />
								<Button variant="outline-secondary" onClick={() => {setShowSelectImageModal(true);}}><Trans i18nKey={"components.insert-image-modal.form.imageURL.select-button"}>Select</Trans></Button>
							</InputGroup>
							<FormControlErrors block errors={errors.imageURL} />
						</FormGroup>
					</Row>

  				 	
					<Row className="mb-3">
						<FormGroup as={Col} controlId="formGridAltText">
							<FormLabel><Trans i18nKey={"components.insert-image-modal.form.altText.label"}>Alt Text</Trans>  <FormControlHelper text={"components.insert-image-modal.form.altText.helper"}/></FormLabel>
							<FormControl type={"text"} name="altText" isInvalid={!(errors.altText == null)} value={values.altText} onChange={handleChange} />
							<FormControlErrors errors={errors.altText} />
						</FormGroup>
					</Row>
					
					{/*<Row className="mb-3">
						<FormGroup as={Col} md={"auto"} controlId="formGridInline">
							<FormLabel><Trans i18nKey={"components.insert-image-modal.form.inline.label"}>Inline</Trans></FormLabel>
							<Switch 
	    						name="inline"
	    						value={values.inline}
	    						checked={values.inline}
	    						onChange={handleChange}
	    					/>
						</FormGroup>
						<FormGroup as={Col} controlId="formGridPosition">
							<FormLabel><Trans i18nKey={"components.insert-image-modal.position.label"}>Position</Trans></FormLabel>
							<FormSelect position="position" value={values.position} onChange={handleChange}>
								{  InlineImagePositions.map(item =>
		    						<option key={item} className="text-uppercase" value={item}><Trans i18nKey={"components.insert-image-modal.position.options."+item} /></option>
		    					)}
							</FormSelect>
						</FormGroup>
					</Row>*/}
  					
					</Container>
		  		</Modal.Body>
          		<Modal.Footer>			      		
          			<Button variant="success" type="submit"> 
          				{(props.confirmText)
  							? props.confirmText
  							: <Trans i18nKey="components.insert-image-modal.confirm">OK</Trans>
  						}
          			</Button>
          			<Button variant="secondary" onClick={props.onHide}>
          				{(props.cancelText)
  							? props.cancelText
  							: <Trans i18nKey="components.insert-image-modal.cancel">Cancel</Trans>
  						}
					</Button>	
          		</Modal.Footer>
          	</Form>	
			)}
		</Formik>
		</Modal>
	);
}

export default InsertImageModal;
