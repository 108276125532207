import * as Yup from "yup";

const ForceChangePasswordOptions = [
	true,
	false
]

const PasswordChecklistRules = {
	minLength: Yup.string().min(8, 'form.validation.string.password.minimumlength'),
	maxLength: Yup.string().max(20, 'form.validation.string.password.maximumlength'),
	specialChar: Yup.string().matches('^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{1,}$', 'form.validation.string.password.specialchar'),
	digit: Yup.string().matches('^(?=.*[0-9]).{1,}$', 'form.validation.string.password.digit'),
	uppercase: Yup.string().matches('^(?=.*[A-Z]).{1,}$', 'form.validation.string.password.uppercase'),
	lowercase: Yup.string().matches('^(?=.*[a-z]).{1,}$', 'form.validation.string.password.lowercase'),
	space: Yup.string().matches('^(?=\\S+$).{1,}$', 'form.validation.string.password.space')
}

const FormValidationRules = {
		   newPassword: Yup.string()
		   	 .required()
		   	 //This regex is a combination of password checklist rules
		   	 .matches('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=\\S+$)[a-zA-Z0-9!@#$%^&*]{8,20}$', 'form.validation.string.password.requirements')
		     .default(""),
		   confirmedNewPassword: Yup.string()
		     .required()
		     .oneOf([Yup.ref('newPassword')], 'form.validation.string.password.mismatch')
		     .default(""),
		   forceChangePassword: Yup.boolean()
		   		.typeError('form.validation.boolean')
		    	.default(() => true),
}

const UserPasswordConfig = { ForceChangePasswordOptions, FormValidationRules, PasswordChecklistRules };

export default UserPasswordConfig;