import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import ConfirmationDialogModal from "../common/ConfirmationDialogModal";
import DataTable from '../common/DataTable';
import FormControlErrors from "../common/FormControlErrors";
import FormControlHelper from "../common/FormControlHelper";
import EditableList from "../common/EditableList";

import API from "../../services/backend-api";
//import { useConfig } from "../../services/use-config";
import { useAuth } from "../../services/use-auth";

import { isArrayWithLength} from "../../helpers/commons";

//import SubjectConfig from "../../config/SubjectConfig";

import { AuthError, ValidationError } from "../../helpers/custom-errors";

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../styles/Typeahead.css";
import SaveSubjectTopicSkillModal from './SaveSubjectTopicSkillModal';
import ShowDialogModal from '../common/ShowDialogModal';


const SubjectTopicFormFields = props => {

	const [isSearchingBNCCSkill, setIsSearchingBNCCSkill] = useState(false);
	const [bnccOptions, setBNCCOptions] = useState([]);
	const [pagedBNCCSkills, setPagedBNCCSkills] = useState(null);
	const typeaheadRef = useRef(null);
	const pageSize = (props.pageSize) ? props.pageSize : 30;
	
	const [saveTopicSkillModalShow, setSaveTopicSkillModalShow] = useState(false);
	const [deleteTopicSkillModalShow, setDeleteTopicSkillModalShow] = useState(false);
	const [topicSkillDeletionPendingModalShow, setTopicSkillDeletionPendingModalShow] = useState(false);
	const [selectedTopicSkill, setSelectedTopicSkill] = useState(null);
	
	const { t } = useTranslation();
	//const config = useConfig();
	const auth = useAuth();
 	
 	const sortBySkillOrder = (a,b) => {		
  		return parseInt(a.skillOrder)-parseInt(b.skillOrder);
 	}
	
	useEffect(() => {
		let isMounted = true; 
		
		//Sort Topic Skills
		props.setFieldValue("skills", props.values.skills.sort(sortBySkillOrder));
			
		return () => { isMounted = false };
		  
	}, []);
	
	const handleNameChange = (e) => {
        
        props.setFieldValue("name", (e.target.value) ? e.target.value.toUpperCase() : "" );
        
    }
    
	  const handleBNCCSkillSearch = (query) => {
	    
	    pageBNCCSkills({filter: query, pageSize: pageSize})
	      .then(({ totalCount, list }) => {
				
			  const options = Array(totalCount).fill().map((e,index)=> {
			  		if (list[index]) {
						return Object.assign({}, list[index]);
	    	  		}
			  		else
			   			return ({shortcode: t(props.i18nPrefix+"form.bnccSkills.loading")}) 
			  }); 
			   	 
	    	  setBNCCOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find BNCC Skills Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find BNCC Skills Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 })
	  }
	  
	  const handleBNCCSkillPaginate = (e, number) => {
	
		const pageIndex = Math.ceil(number/pageSize) - 1;
	    
		pageBNCCSkills({filter: pagedBNCCSkills.filter, pageSize: pageSize, pageIndex: pageIndex})
	      .then(({ list }) => {
		
			  let updatedOptions = bnccOptions;
			  
			  list.forEach((item, index) => {
			  
			  	updatedOptions[pageSize*pageIndex + index] = Object.assign({}, item);
		
	    	  })
		 	 
	    	  setBNCCOptions(updatedOptions);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Paginate BNCC Skills Attempt Failed: ", error.message);
          						
				 props.onError(error.message);		
          						
          	 } else {
          		log.error("Paginate BNCC Skills Error: ", error.message);
          		props.onError(t('error.api.general'));
          	 }
		 })
		 
	}
	
	
	const pageBNCCSkills = (values) => {
		
		setIsSearchingBNCCSkill(true);
		
		return new Promise((resolve, reject) => {
			API.pageBNCCSkills(values)
			.then(response => {
				setPagedBNCCSkills(response);
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 	setIsSearchingBNCCSkill(false);
		 	});
		});
		
	}
	
	const renderMenuItemChildren = (option, properties) => { 
	
		return (
			<Fragment>
          		<Highlighter search={properties.text}>
            		{option.shortcode}
         		</Highlighter>,
          		{(option.description) ?
          			<div>
            			<small>
              				{option.description}
            			</small>
          			</div>
          			: null
          		}
        	</Fragment>
    	)
    }
    
    const createBNCCSkillOption = (item) => {
        
        return Object.assign({}, {"shortcode": item})
    }
    
    
    const newTopicSkill = () => {
	  setSelectedTopicSkill(null);
	  setSaveTopicSkillModalShow(true);
    }
    
    const updateTopicSkill = (item, actionIndex, rowIndex) => {
	  setSelectedTopicSkill(Object.assign({"index": rowIndex}, item));
	  setSaveTopicSkillModalShow(true);
 	}
 	
 	const validateTopicSkillRemoval = (item, actionIndex, rowIndex) => {
	  
	  //First validate if skill is already associated with any question
	  let query = {};
	  query.skills = [{ id: item.id }];
	  API.pageQuestions(query)
		.then(response => {
			
			 if (response.totalCount == 0) {
			 	setSelectedTopicSkill(Object.assign({"index": rowIndex}, item));
	  		 	setDeleteTopicSkillModalShow(true);
	  		 } else {
				setTopicSkillDeletionPendingModalShow(true);
			 }
		})
		.catch(error => { 
			log.error("Error Finding Questions: ", error.message);
		 	props.onError(error);
		})
	  
    }
    
    const deleteTopicSkill = (item) => {
		
		setDeleteTopicSkillModalShow(false);
		
		//Shallow copy of Array, only to "dirty"" the form
		let topicSkillsCopy = [...props.values.skills];
		
		//Remove Skill
		topicSkillsCopy.splice(item.index, 1);
		
		//Decrease skillOrder by one for all subsequent skills
		topicSkillsCopy.forEach((skill, index) => {
			if (index >= item.index)
				skill.skillOrder = parseInt(skill.skillOrder)-1;
		});
		
		props.setFieldValue("skills", topicSkillsCopy.sort(sortBySkillOrder));
		props.setFieldError("skills", null);
  }
  
  
  const moveTopicSkillUp = (item, actionIndex, rowIndex) => {
	
	//Shallow copy of Array, only to "dirty"" the form
	let topicSkillsCopy = [...props.values.skills];
	
	if (rowIndex > 0) {
		
		let currentSkillOrder = item.skillOrder;
		let predecessorSkillOrder = topicSkillsCopy[rowIndex-1].skillOrder;
		
		//Switch Skill Orders
		topicSkillsCopy[rowIndex].skillOrder = predecessorSkillOrder;
		topicSkillsCopy[rowIndex-1].skillOrder = currentSkillOrder;
		
		props.setFieldValue("skills", topicSkillsCopy.sort(sortBySkillOrder));
		props.setFieldError("skills", null);

	}
  }
  
  const moveTopicSkillDown = (item, actionIndex, rowIndex) => {
	
	//Shallow copy of Array, only to "dirty"" the form
	let topicSkillsCopy = [...props.values.skills];
	
	if (rowIndex < topicSkillsCopy.length-1) {
		
		let currentSkillOrder = item.skillOrder;
		let subsequentSkillOrder = topicSkillsCopy[rowIndex+1].skillOrder;
		
		//Switch Skill Orders
		topicSkillsCopy[rowIndex].skillOrder = subsequentSkillOrder;
		topicSkillsCopy[rowIndex+1].skillOrder = currentSkillOrder;
		
		props.setFieldValue("skills", topicSkillsCopy.sort(sortBySkillOrder));
		props.setFieldError("skills", null);

	}

  }
  
  const handleTopicSkillSaved = (action, values, index) => {
	  
	  setSaveTopicSkillModalShow(false);
	  
	  //Shallow copy of Array, only to "dirty"" the form
	  let topicSkillsCopy = [...props.values.skills];
	  
	  if (action == "create") {
		//We can be create multiple skills at once
		
		let currentSkillsLength = topicSkillsCopy.length;
		
		values.skills.forEach((newItem, index) => topicSkillsCopy.push(Object.assign({}, newItem, {"skillOrder" : (currentSkillsLength + index + 1)})));
		
	  } else {
		// But, we can only update one skill per time
		topicSkillsCopy.splice(index, 1, values);
	  }
	  	  
	  props.setFieldValue("skills", topicSkillsCopy.sort(sortBySkillOrder));
	  props.setFieldError("skills", null);
	  
 	}
 	
 	const displayInactive = (item) => { 
		return (item.active) ? "" : "inactive-row"	
  	}

    // Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;
	
	
    
	return(
		<Fragment>	

			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridName">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.name.label"}>Name</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="name" isInvalid={!(props.errors.name == null)} value={props.values.name} onChange={handleNameChange} placeholder={t(props.i18nPrefix+"form.name.placeholder")} />
				    <FormControlErrors errors={props.errors.name} />
				</FormGroup>
			</Row>
			
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridBNCCSkills">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.bnccSkills.label"}>BNCCS kills</Trans> <FormControlHelper text={props.i18nPrefix+"form.bnccSkills.helper"}/></FormLabel>
					<AsyncTypeahead
      					multiple
      					id="bnccSkills"
      					name="bnccSkills"
      					filterBy={filterBy}
						isInvalid={!(props.errors.bnccSkills == null)}
						isLoading={isSearchingBNCCSkill}
						paginate={true}
						minLength={3}
						maxResults={pageSize}
						labelKey="shortcode"
						onPaginate={handleBNCCSkillPaginate}
						onSearch={handleBNCCSkillSearch}
      					onChange={(selected) => {
        					props.setFieldValue("bnccSkills", selected.map(item => item.shortcode));
        					// Keep the menu open when making multiple selections.
        					typeaheadRef.current.toggleMenu();
      					}}
      					options={bnccOptions}
						placeholder={t(props.i18nPrefix+"form.bnccSkills.placeholder")}
						emptyLabel={t(props.i18nPrefix+"form.bnccSkills.no-matches-found")}
						searchText={t(props.i18nPrefix+'form.bnccSkills.searching')}
						promptText={t(props.i18nPrefix+'form.bnccSkills.prompt')}
						paginationText={t(props.i18nPrefix+'form.bnccSkills.paginate')}
      					ref={typeaheadRef}
						renderMenuItemChildren={renderMenuItemChildren}
						selected={props.values.bnccSkills.map(item => createBNCCSkillOption(item))}

    			/>
				<FormControlErrors block={true} errors={props.errors.bnccSkills} />
			</FormGroup>	
			</Row>
			
			{(saveTopicSkillModalShow) && <SaveSubjectTopicSkillModal
				show={saveTopicSkillModalShow}
	        	onHide={() => setSaveTopicSkillModalShow(false)}
				size="lg"
				item={selectedTopicSkill}
				onItemSaved={handleTopicSkillSaved}
		      />
			}
			{(selectedTopicSkill) && <ConfirmationDialogModal
				item={selectedTopicSkill}
				show={deleteTopicSkillModalShow}
        		onHide={() => setDeleteTopicSkillModalShow(false)}
				size="lg"
				title={t("subjects.delete-topic-skill-confirmation-modal.title")}
				bodyText={t("subjects.delete-topic-skill-confirmation-modal.body", {item: selectedTopicSkill})}
				confirmText={t("subjects.delete-topic-skill-confirmation-modal.confirm")}
				cancelText={t("subjects.delete-topic-skill-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteTopicSkill}
			/>}
			
			{(topicSkillDeletionPendingModalShow) && <ShowDialogModal
				show={topicSkillDeletionPendingModalShow}
        		onHide={() => setTopicSkillDeletionPendingModalShow(false)}
				size="lg"
				title={t("subjects.topic-skill-deletion-pending-dialog-modal.title")}
				bodyText={t("subjects.topic-skill-deletion-pending-dialog-modal.body")}
				cancelText={t("subjects.topic-skill-deletion-pending-dialog-modal.cancel")}
				variant="warning"
			/>}
			
			<Row>
			<FormGroup as={Col} controlId="formGridSkills" className="mb-1">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.skills.label"}>Skills</Trans></FormLabel>
					<fieldset>
						<div className={(props.errors.skills == null) ? "border rounded p-4" : "border border-danger rounded p-4"}>
							<Row className="align-items-center">
							 	<Col>
        							<DataTable
        								items={props.values.skills} 
        								i18nPrefix={props.i18nPrefix+"form.skills.datatable."}
        								columns={["skillOrder","name"]} 
        								customDisplayColumns={[]}
        								actions={[updateTopicSkill, validateTopicSkillRemoval, moveTopicSkillUp, moveTopicSkillDown]}
        								defaultAction={updateTopicSkill}
        								customRowStyle={displayInactive}	
        								//noItemsDisclaimer={true}
        							/>
        						</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button variant="primary" onClick={newTopicSkill}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.skills.add-button"}>Add Skill</Trans></Button>
							 	</Col>
							</Row>
							
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.skills} />
				</FormGroup>
		   </Row>

		</Fragment>
		
	) 
}


export default SubjectTopicFormFields;
