import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Loader from "../components/common/Loader";
import FormControlErrors from "../components/common/FormControlErrors";
import DismissableFeedback from "../components/common/DismissableFeedback";
import UserPasswordChecklist from "../components/user/UserPasswordChecklist";

import { useAuth } from "../services/use-auth";
import { ValidationError } from "../helpers/custom-errors";

import UserPasswordConfig from "../config/UserPasswordConfig";

import '../styles/ResetPasswordPage.css';

import logo from '../images/logo-bg-light.png';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(UserPasswordConfig.FormValidationRules);

const ResetPasswordSuccessPage = props => {

const { t } = useTranslation();

 return (
          <Container fluid className="text-center pt-4 pb-4">
          		<DismissableFeedback feedback={props.success} type="success" onClose={() => props.onSuccess(null)} />	
            	<Row>	
            		<Col md={12} className="mb-3"><Image width="100" src={logo} alt={t('app.logo')}/></Col>
            		<Col md={12} className="mb-3"><h1 className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></h1></Col>
                    <Col md={12} className="mb-3"><h1 className="h3 fw-normal"><Trans i18nKey="reset-password.success.header">Your password was reset successfully!</Trans></h1></Col>
					<Col md={12} className="mb-3"><Trans i18nKey="reset-password.success.details">Instructions</Trans></Col>
              	    <Col md={12}><Button as={Link} variant="link" to="/login"><Trans i18nKey="reset-password.back-to-login">Back to Login Page</Trans></Button></Col>
              	</Row>		
          </Container>
    );

}

const ResetPasswordPage = props => {
	
  const [_error, _setError] = useState(null);
  const [_success, _setSuccess] = useState(null);
  const [_isValidatingToken, _setIsValidatingToken] = useState(true);
  
  const { t } = useTranslation();  
  
  const auth = useAuth();
  
  let { token } = useParams();
  let navigate = useNavigate();
  
  const onSuccess = (success) => {
	_setSuccess(success);
  };
  
  useEffect(() => {
	  let isMounted = true;
	  
	  auth.verifyUserPasswordResetToken({ values: { token: token } })
		.then((response) => {
			//Do nothing at this point
			if (isMounted) _setIsValidatingToken(false);
		})
		.catch(error => {		
			if (error instanceof ValidationError) {		
				log.info("User Password Reset Token is Invalid/Expired: ", token);
				
				navigate("/forgot-password");
			} else {
				
				log.error("Verify User Password Reset Error: ", error.message);
          						
          		if (isMounted) _setError(t('error.api.general'));
          		if (isMounted) _setIsValidatingToken(false);
			}
		})
         
        return () => { isMounted = false };
         
  }, []);
	  
	  if (_isValidatingToken)
	  	return <Loader />
	  	
	  if (_success) {
  		return <ResetPasswordSuccessPage success={_success} onSuccess={onSuccess} />
  	  }
 
	  return (
          <Container className="text-center pt-2 pb-2">
          	<DismissableFeedback feedback={_error} type="danger" onClose={() => _setError(null)} />
          	<Formik
          		initialValues={{ newPassword: '', confirmedNewPassword: '' }}
          		validationSchema={validationSchema}
          		validateOnChange={false}
          		validateOnBlur={false}     
          		onSubmit={(values, actions) => {
          			//Clear Error
          			_setError(null);
          			_setSuccess(null);
          			auth.confirmUserPasswordReset({ values: values })
          				.then((response) => {
							_setSuccess(response.success);
          				})
          				.catch(error => {
          					
          					if (error instanceof ValidationError) {		
          						
          						log.info("Reset User Password Attempt Failed: ", error.message);
          						
          						_setError(error.message);
          						
          						if (error.detail) {            						
              						for (let key of Object.keys(error.detail)) {
              							let errorMsgs = error.detail[key];
              							errorMsgs.forEach((message) => {
              								actions.setFieldError(key, message);
              							});
              						}
              					}
          						
          					} else {
          						
          						log.error("Reset User Password Error: ", error.message);
          						
          						_setError(t('error.api.general'));
          					}
          					
          				})
          				.finally(() => {
          					actions.setSubmitting(false);
          				});
          		}}
          	>
          		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
          			<Form className="form-reset-password" noValidate>
          				
          			<Image className="mb-3" width="150" src={logo} alt={t('app.logo')}/>
          			<h1 className="h3 mb-3 fw-normal"><Trans i18nKey="reset-password.form.header">Welcome</Trans></h1>
                  	
                  	<Row>
                  	
                  	<Col md={{offset: 4, span: 4}}>	
                  		<Row className="mb-3">
                  			<FormGroup as={Col} controlId="formNewPassword">
          	               		<FormLabel className="sr-only"><Trans i18nKey="reset-password.form.new-password.label">Email address</Trans></FormLabel>
          	                	<FormControl type={'password'} name="newPassword" isInvalid={!(errors.newPassword == null)} value={values.newPassword} onChange={handleChange} placeholder={t('reset-password.form.new-password.placeholder')} />
          	                	<FormControlErrors errors={errors.newPassword} />
          	            	</FormGroup>
                  		</Row>
                  		
                  		<Row className="mb-3">
      	                	<FormGroup as={Col} controlId="formConfirmedNewPassword">
      	                		<FormLabel className="sr-only"><Trans i18nKey="reset-password.form.confirmed-new-password.label">Password</Trans></FormLabel>
      	                		<FormControl type={'password'} name="confirmedNewPassword" isInvalid={!(errors.confirmedNewPassword == null)} value={values.confirmedNewPassword} onChange={handleChange} placeholder={t('reset-password.form.confirmed-new-password.placeholder')} />
								<FormControlErrors errors={errors.confirmedNewPassword} />
      	                	</FormGroup>
						</Row>
	
						<div className="d-grid gap-2">
                  			<Button className="mb-3" variant="success" type="submit" size="lg" block disabled={isSubmitting} onClick={() => {setFieldValue("token", token);}}> 
                  		    	{isSubmitting ? <Trans i18nKey="reset-password.form.submitting">Please wait...</Trans> : <Trans i18nKey="reset-password.form.submit">Submit</Trans>} 
              				</Button>
              			</div>
              			
              		</Col>
              			
              		<Col md="auto">
						<UserPasswordChecklist className="d-none d-lg-block" value={values.newPassword} valueAgain={values.confirmedNewPassword}/>
              		</Col>
              			
              		</Row>
              			
              		</Form>		
          		 )}
   		     </Formik>  
          </Container>
    );
}

export default ResetPasswordPage;
