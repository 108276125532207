import React, {useState, useEffect, Fragment} from "react";
import log from 'loglevel';

import {$getRoot, $getSelection, ParagraphNode, TextNode} from 'lexical';
import {$generateHtmlFromNodes, $generateNodesFromDOM  } from '@lexical/html';

import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';

import { HeadingNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";

import LoadInitialContent from "./LoadInitialContent";
import Theme from "./Theme";

import ToolbarPlugin from "./plugin/ToolbarPlugin";
import EquationsPlugin from './plugin/EquationsPlugin';
import ImagesPlugin from './plugin/ImagesPlugin';
import InlineImagePlugin from './plugin/InlineImagePlugin';
import DraggableBlockPlugin from './plugin/DraggableBlockPlugin';

import { EquationNode } from "./node/EquationNode";
import { ImageNode} from "./node/ImageNode";
import { InlineImageNode } from "./node/InlineImageNode";
import { ExtendedTextNode } from "./node/ExtendedTextNode";
import { CustomParagraphNode } from "./node/CustomParagraphNode";

import {useSharedHistoryContext} from './context/SharedHistoryContext';

import { CAN_USE_DOM } from "../../helpers/commons"

import './CustomLexicalEditor.css';

const CustomLexicalEditor = props => {
   const {historyState} = useSharedHistoryContext();
   const [floatingAnchorElem, setFloatingAnchorElem] = useState(null)
   const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false)
	
	const onError = (error) => {
  		log.error(error);
	}
	
	const onRef = _floatingAnchorElem => {
    	if (_floatingAnchorElem !== null) {
      		setFloatingAnchorElem(_floatingAnchorElem)
    	}
  	}

  	useEffect(() => {
    	const updateViewPortWidth = () => {
      		const isNextSmallWidthViewport =
        		CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches

      		if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        		setIsSmallWidthViewport(isNextSmallWidthViewport)
      		}
    	}
    	updateViewPortWidth()
    	window.addEventListener("resize", updateViewPortWidth)

    	return () => {
      		window.removeEventListener("resize", updateViewPortWidth)
    	}
  	}, [isSmallWidthViewport]) 

	const handleEditorStateChange = (editorState, editor) => {
        
    	log.debug('editorState', JSON.stringify(editorState));
        
        editor.update(() => {
    		let contentHtml = $generateHtmlFromNodes(editor);
    		log.debug(contentHtml);
    		props.onChange(contentHtml)
  		});
        
    }

	const initialConfig = {
    	namespace: 'SimEduca',
    	theme: Theme,
   	    onError,
   	    nodes: [
			HeadingNode,
    		ListNode,
    		ListItemNode,
    		EquationNode,
    		ImageNode,
    		InlineImageNode,
    		ExtendedTextNode, {
      			replace: TextNode,
      			with: node => new ExtendedTextNode(node.__text),
      			withKlass: ExtendedTextNode
    		},
			/*CustomParagraphNode, {
				replace: ParagraphNode,
				with: (node) => {
			  		return new CustomParagraphNode();
				}
		  	}*/
	],
    };
	
	

	return (
		<LexicalComposer initialConfig={initialConfig}>
      		<LoadInitialContent initialContent={props.content} />
      		<ToolbarPlugin context={props.context}/>
      		<RichTextPlugin
            	contentEditable={
              		<div className="lexical-editor border rounded p-2" ref={onRef}>
              			<ContentEditable
                			className="editable-content"
                			aria-placeholder={props.placeholder}
              			/>
              		</div>
       
            	}
            	ErrorBoundary={LexicalErrorBoundary}
          	/>
          	<HistoryPlugin externalHistoryState={historyState}/>
          	<ListPlugin />
          	<EquationsPlugin />
          	<ImagesPlugin />
          	<InlineImagePlugin />
          	{floatingAnchorElem && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
              </>
            )}
          	<OnChangePlugin onChange={handleEditorStateChange} ignoreSelectionChange/>
     	</LexicalComposer>);
	};


export default CustomLexicalEditor;