import React from 'react';
import { Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ShowDialogModal = props => {
	
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-show-dialog"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-show-dialog">
  					{(props.title)
  						? props.title
  						: <Trans i18nKey="modal.show-dialog.title">Show Dialog Title</Trans>
  					}
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="pt-0 pb-0">
  				<Container className="p-2">
  					<Row className="align-items-center">
  					<Col md={1} sm={2} xs={2} className="text-center p-1">
  						<h1 className={"text-"+props.variant}><FontAwesomeIcon icon={faExclamationCircle} /></h1>
					</Col>
		  			<Col className="ms-3 text-justified align-middle">{props.bodyText}</Col>
				</Row>
				</Container>
		  	</Modal.Body>
          	<Modal.Footer>			      		
          		<Button variant="secondary" onClick={props.onHide}>
          			{(props.closeText)
  						? props.closeText
  						: <Trans i18nKey="modal.show-dialog.close">Close</Trans>
  					}
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

export default ShowDialogModal;
