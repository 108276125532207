import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faChevronDown, faChevronUp, faClone, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormGroup from 'react-bootstrap/FormGroup';
import FormCheck from 'react-bootstrap/FormCheck';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from "react-bootstrap/AccordionContext";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../common/DismissableFeedback";
import Loader from "../common/Loader";

import { isArrayWithLength } from "../../helpers/commons";

import API from "../../services/backend-api";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });
	 
const GeneralDataFieldsValidationRules = {
	field: Yup.bool().default(true),
	level: Yup.bool().default(true),
	sourceId:  Yup.bool().default(true),
	year:  Yup.bool().default(true),
}

const QuestionDataFieldsValidationRules = {
	questionStem: Yup.bool().default(true),
	estimatedTime: Yup.bool().default(true)
}

const AnswerFormValidationRules = {
	type: Yup.bool().default(true),
	answer: Yup.bool().default(true),
}

const ResolutionFormValidationRules = {
	resolutionSteps: Yup.bool().default(true),
}

const ImagesFormValidationRules = {
	images: Yup.bool().default(true),
}

const accordionCardConfig = [
	{
		key: "generalData",
		value: true, 
		data: GeneralDataFieldsValidationRules
	},
	{
		key: "questionData",
		value: true, 
		data: QuestionDataFieldsValidationRules
	},
	{
		key: "answerData",
		value: true, 
		data: AnswerFormValidationRules
	},
	{
		key: "resolutionData",
		value: true, 
		data: ResolutionFormValidationRules
	},
	{
		key: "imagesData",
		value: true, 
		data: ImagesFormValidationRules
	}
]

const validationSchema = Yup.object().shape(
	Object.assign({}, ...accordionCardConfig.map((card) => card.data))
);


const ContextAwareToggle = ({ children, eventKey, callback }) => {
  
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button variant="link" onClick={decoratedOnClick}  className="float-end">
     	 <FontAwesomeIcon size="lg" icon={isCurrentEventKey ? faChevronUp : faChevronDown} />
    </Button>
  );
}

const CloneQuestionModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [originalQuestion, setOriginalQuestion] = useState(null);
	
	let navigate = useNavigate();

	useEffect(() => {
		let isMounted = true; 
		
		if (props.item) {
			API.lookupQuestion(props.item.id)
			.then(response => {
					setOriginalQuestion(response);
			})
			.catch(error => { 
				log.error("Error Loading Original Question: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) {
		 			setIsLoading(false);
		 		}
			});
		} 
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if (!props.item) {
		return (<Navigate  to="/questions" />)
	} else if (!isLoading && !originalQuestion) {
		return null;
	}
	
	
	let initialValues = Object.assign(
		...accordionCardConfig.map((card) => { return { [card.key]: card.value } })
		, validationSchema.default()
	);

	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-clone-question-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-clone-question-modal">
  					<Trans i18nKey="questions.clone-question-modal.title">Clone Question Data</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={true}
		      		validateOnBlur={true}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
		      			
		      			let clonedQuestion = {};
		      			
		      			accordionCardConfig.forEach((card) => {
		      				if (values[card.key] === true) {
		      					Object.keys(card.data)
									.forEach((key) => {
										if (values[key] === true) {
											switch (key) {
												case "answer":
													clonedQuestion["shortAnswer"] = originalQuestion["shortAnswer"];
													if (isArrayWithLength(originalQuestion["answerOptions"])) {
														clonedQuestion["answerOptions"] = originalQuestion["answerOptions"]
															.map(item => {
																let clonedItem = Object.assign({"clonedObjectId": item.id }, item);
																delete clonedItem.id;
																return clonedItem
															})
													}
												case "resolutionSteps":
												case "images":
													if (isArrayWithLength(originalQuestion[key])) {
														clonedQuestion[key] = originalQuestion[key]
															.map(item => {
																let clonedItem = Object.assign({"clonedObjectId": item.id }, item);
																delete clonedItem.id;
																return clonedItem
															})
													}
													break;
												default:
													clonedQuestion[key] = originalQuestion[key];
											}
										}
									});
		      				}
		      			
		      			});
		      					      			
		      			navigate("/questions/clone", { state: { item: clonedQuestion}});
					
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		
		      		<Form className="form-clone-question-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  								<Row className="mb-2 pl-3 pr-3 align-items-center">
  									<Col md={1} sm={2} xs={2} className="text-center p-1">
  										<h1 className={"text-primary"}><FontAwesomeIcon icon={faExclamationTriangle} /></h1>
									</Col>
		  							<Col className="ml-3 text-justified align-middle">
										<Trans i18nKey="questions.clone-question-modal.body">Clone Question Body</Trans>
									</Col>
								</Row>

								<Row className="ps-3 pe-3">
									<Col>
										<Accordion defaultActiveKey="0">
										{ accordionCardConfig.map((card, index) => (
											
											<Card key={card.key}>
    											<Card.Header className="pt-1 pb-1">
      												<Row>
      												<FormGroup as={Col} className="mb-0 my-auto" controlId={"formGrid-"+card.key}>		
															<FormCheck type={"checkbox"} >
        														<FormCheck.Input 
        															type={"checkbox"}
        															name={card.key}
        															value={values[card.key]}
        															checked={(values[card.key])}
	     															onChange={handleChange} 
	     														/>
        														<FormCheck.Label>
        															<Trans i18nKey={"questions.clone-question-modal.form."+card.key+".label"}>{card.key}</Trans>
																</FormCheck.Label>
      														</FormCheck>
	     											</FormGroup>
													<Col>
														<ContextAwareToggle eventKey={index.toString()} />
													</Col>
													</Row>
    											</Card.Header>
    											<Accordion.Collapse eventKey={index.toString()}>
      												<Card.Body className="pt-2 pb-2">
      													<Row>
      													{ Object.keys(card.data).map((field, index) => (
															<Fragment key={index}>
																<Col md={1} />
																<FormGroup as={Col} md={5} className="mb-1" controlId={"formGrid-"+field}>		
																		<FormCheck type={"checkbox"} >
        																<FormCheck.Input 
        																	type={"checkbox"}
        																	name={field}
        																	value={values[field]}
        																	checked={values[field]}
	     																	onChange={handleChange} 
	     																	disabled={!(values[card.key])}
	     																/>
        																<FormCheck.Label>
        																	<Trans i18nKey={"questions.save.form."+field+".label"}>{field}</Trans>
																		</FormCheck.Label>
      																</FormCheck>
	     														</FormGroup>
															</Fragment>
														))}
														</Row>
      												</Card.Body>
    											</Accordion.Collapse>
  											</Card>
											
											 ))}
										</Accordion>
									</Col>
								</Row>

							
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="questions.clone-question-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faClone} /> <Trans i18nKey="questions.clone-question-modal.form.submit">Clone</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="questions.clone-question-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			)}
		</Modal>
	);
}

export default CloneQuestionModal;
