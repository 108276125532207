import React,  { useState, useEffect } from 'react';
import { Trans} from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//import Card from 'react-bootstrap/Card';

import Toast from 'react-bootstrap/Toast';

import * as Yup from "yup";

import UserPasswordConfig from "../../config/UserPasswordConfig";

//import '../styles/UserPasswordChecklist.css';


const validationSchema = Yup.object().shape(UserPasswordConfig.PasswordChecklistRules);

const UserPasswordChecklist = props => {

	const [checkListPassed, setCheckListPassed] = useState({});
	
	useEffect(() => {
		let isMounted = true; 
		
		var passwordObject = {};
		Object.keys(UserPasswordConfig.PasswordChecklistRules).forEach((key) => passwordObject[key] = props.value);
	
		validationSchema.validate(passwordObject, {abortEarly: false})
		.then(function (value) {
  				var passed = {};
  				Object.keys(UserPasswordConfig.PasswordChecklistRules).forEach((key) => passed[key] =  true);
  				if (isMounted) setCheckListPassed(passed) 
  							
			})
		.catch(function (err) {
  				
  				//console.log( err.inner);
  				
  				let failed = {};
  				err.inner.forEach((error) => {
  					failed[error.path] = true;
  				});
  				
  				var passed = {};
  				Object.keys(UserPasswordConfig.PasswordChecklistRules).forEach((key) => passed[key] = !failed[key]);
  				
  				if (isMounted) setCheckListPassed(passed) 
		});
		
		
		 return () => { isMounted = false };
		  
	}, [props.value]);
	
	
	if (!props.value || !props.value.length > 0) {
		return null
	}
	
	var checklistRules = Object.keys(UserPasswordConfig.PasswordChecklistRules).map((key, index) => 
		<Row key={index}>
			<Col md={1}><FontAwesomeIcon icon={(checkListPassed[key]) ? faCheck : faTimes } color={(checkListPassed[key]) ? "green" : "red" }/></Col>
			<Col><Trans parent="small" className={(checkListPassed[key]) ? "align-text-top" : "align-text-top text-muted" } i18nKey={'components.user-password-checklist.requirements.' + key} /></Col>
		</Row>
	)
	
	/*return (
		<Card>
  			<Card.Body>
    			<Card.Title><Trans i18nKey='components.user-password-checklist.title'>Password must have</Trans></Card.Title>
    			<Card.Text>
      				{checklistRules}
    			</Card.Text>
  			</Card.Body>
		</Card>
	)*/
	
	
	return (
		  <Toast className={props.className}>
    		<Toast.Header closeButton={false}>
      			<strong className="me-auto">
      				<Trans i18nKey='components.user-password-checklist.title'>Password must have</Trans>
      			</strong>
    		</Toast.Header>
    		<Toast.Body>{checklistRules}</Toast.Body>
  		</Toast>
    )
	
};

export default UserPasswordChecklist;