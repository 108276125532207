import { faMask, faFingerprint, faUserCog, faInfoCircle, faFileImport, faQuestion, faQuestionCircle, faBook} from '@fortawesome/free-solid-svg-icons'
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { Roles } from '../config/Roles'
import { Permissions } from '../config/Permissions'

export const PrivateNavbarOptionsConfig = [
	{
		path: '/support',
		title: 'header.navigation-titles.support',
		icon: faMask,
		roles: [
			Roles.ROOT
		],
		children: [
			{
				path: '/users',
				title: 'header.navigation-titles.users',
				icon: faUserCog,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/users/import',
				title: 'header.navigation-titles.users-import',
				icon: faFileImport,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/activities',
				title: 'header.navigation-titles.activities',
				icon: faFingerprint,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/about',
				title: 'header.navigation-titles.about',
				icon: faInfoCircle,
				roles: [
					Roles.ROOT
				]
			}
		]
	},
	{
		path: '/admin',
		title: 'header.navigation-titles.admin',
		icon: faCogs,
		roles: [
			Roles.ROOT, Roles.ADMIN
		],
		children: [
			{
				path: '/subjects',
				title: 'header.navigation-titles.subjects',
				icon: faBook,
				roles: [
					Roles.ROOT, Roles.ADMIN
				]
			},
			{
				path: '/questions',
				title: 'header.navigation-titles.questions',
				icon: faQuestionCircle,
				roles: [
					Roles.ROOT, Roles.ADMIN
				]
			},
			
		]
	}
]