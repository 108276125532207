import * as Yup from "yup";
import ImportConfig from "../config/ImportConfig";
import UserPasswordConfig from "../config/UserPasswordConfig"; 

const Roles = [
	"admin",
	"tutor",
	"student"
]

const Permissions = [
	"user.create",
	"user.export",
	"user.import"
]

const ActiveOptions = [
	true,
	false
]

const GeneralFormValidationRules = {
  	firstName: Yup.string()
   		.max(100, 'users.save.form.firstName.validation.maximumlength')
   		.required()
   		.default(""),
   	lastName: Yup.string()
   		.max(100, 'users.save.form.lastName.validation.maximumlength')
   		.required()
   		.default(""),
   	displayName: Yup.string()
   		.max(100, 'users.save.form.displayName.validation.maximumlength')
   		.required()
   		.default(""),
   	email: Yup.string()
   		.email()
   		.required()
   		.default(""),
   	mobilePhone: Yup.string()
   		.matches("^\\+[1-9]\\d{1,14}$", 'users.save.form.mobilePhone.validation.format')
   		.required()
   		.default(""),
    active: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => true),
}

const AccessControlFormValidationRules = {
	roles: Yup.array()
		.min(1, 'users.save.form.roles.validation.required')
		.default(() => []),
	permissions: Yup.array()
		.default(() => [])
}

const ExportHeaders =[
	"id", "firstName", "lastName", "displayName", "email", "mobilePhone", "active", "roles", "permissions", "lastLogin", "whenModified", "whenCreated"
]

const ImportHeaders =[
	"id", "firstName", "lastName", "displayName", "email", "mobilePhone", "active", "roles", "permissions", "newPassword", "confirmedNewPassword", "forceChangePassword"
]

const ImportActions = ImportConfig.Actions;
const ImportDelimiterOptions = ImportConfig.DelimiterOptions;
const ImportFirstRowHeaderOptions = ImportConfig.FirstRowHeaderOptions;
const ImportColumnMappingStrategyOptions = ImportConfig.ColumnMappingStrategyOptions;

const ImportFormValidationRules = {
	   action: Yup.string()
		.oneOf([""].concat(ImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(Object.assign({}, GeneralFormValidationRules, AccessControlFormValidationRules,  UserPasswordConfig.FormValidationRules)))
		.max(255, 'users.import.form.data.validation.maximum-size' )
		.default(() => []),
}

const UserConfig = { Roles, Permissions, ActiveOptions, GeneralFormValidationRules, AccessControlFormValidationRules,
				 	ImportHeaders, ExportHeaders, ImportFormValidationRules,
					ImportActions, ImportDelimiterOptions, ImportFirstRowHeaderOptions, ImportColumnMappingStrategyOptions 
				 };

export default UserConfig;