import React, { useEffect }  from "react";
import { useBlocker } from 'react-router-dom'
import { createRoot } from 'react-dom/client';

import log from 'loglevel';

import ConfirmationDialogModal from "./ConfirmationDialogModal";


const Prompt = props => {
	
	const block = props.when;
	const blocker = useBlocker(block);
	
	const textObj = JSON.parse(props.message);
    
    useEffect(() => {
    	// message in latest Chrome will be ignored anyway - it uses its own
    	if (props.when) window.onbeforeunload = () => textObj.bodyText

    	return () => {
      		window.onbeforeunload = null
    	}
  	}, [props.when])
  
    useEffect(() => {
    	
    	if (blocker.state === "blocked") {
      
      		const container = document.createElement("div")
      		container.setAttribute("id", "prompt-dialog-container")
      		container.setAttribute("aria-hidden", "true")
      		document.body.appendChild(container)
      		
      		const root = createRoot(container);

      		const closePrompt = state => {
        		if (container) {
          			root.unmount()
        		}
        		if (!state) {
          			document.body.removeChild(container)
          			blocker.reset()
        		} else {
          			blocker.proceed()
        		}
      		}

     		//const textObj = JSON.parse(props.message);
   	
   	 		if (textObj.when) log.info("User Confirmation triggered when: ", textObj.when);
      
      		 
			root.render(<ConfirmationDialogModal
					show={true}
    				onHide={() => closePrompt(false)}
					onConfirmation={() => closePrompt(true)}
					size={textObj.size}
					title={textObj.title}
					bodyText={textObj.bodyText}
					confirmText={textObj.confirmText}
					cancelText={textObj.cancelText}
					variant={textObj.variant}
				/>);

      		/*ReactDOM.render(
        		<ConfirmationDialogModal
					show={true}
    				onHide={() => closePrompt(false)}
					onConfirmation={() => closePrompt(true)}
					size={textObj.size}
					title={textObj.title}
					bodyText={textObj.bodyText}
					confirmText={textObj.confirmText}
					cancelText={textObj.cancelText}
					variant={textObj.variant}
				/> ,
        		container
      		)*/
    	}
  	}, [blocker])

	return (
		 <div key={block}/>
	);
};


export default Prompt;