
const KnowledgeFields = [
	"MATHEMATICS",
	"NATURAL_SCIENCES",
	"HUMANITIES",
	"LANGUAGES_AND_CODES",
]

const EducationalStages = [
	"INFANTIL",
	"FUNDAMENTAL",
	"ENSINO_MEDIO"
]

const Grades = [
	"EI01",
	"EI02",
	"EI03",
	"EF01",
	"EF02",
	"EF03",
	"EF04",
	"EF05",
	"EF06",
	"EF07",
	"EF08",
	"EF09",
	"EM01",
	"EM02",
	"EM03"
]


const BNCCModel = { KnowledgeFields, EducationalStages, Grades		
};

export default BNCCModel;